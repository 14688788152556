import React from 'react';
import { newsPr_content } from '../../../../common/constants'

import UpdateCard from './UpdateCard';



const Update = () => {

    return (
        <div style={{ width: '100%' }}>
            {newsPr_content.UPDATE &&
                newsPr_content.UPDATE.map((item, index) => {
                    return <UpdateCard key={index} target={item} />
                })
            }
        </div>
    );
};

export default Update;
import React, { useEffect } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'966px',
        margin: 'auto',
        backgroundColor: theme.palette.background.sub.subBackground,
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '966px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    card:{
        width:'373.19px'
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={150}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'150px'}} />
                <Typography variant='h1'>The Why</Typography>
                <div style={{ height: '80px' }} />
                <div style={{display:'flex', justifyContent:'space-between', minWidth:'1180px'}}>
                    <div className={classes.card}>
                        <div style={{height:'120px'}}>
                            <img src={'/images/profile/how/ic_how_chap1_1_'+layout.mode+'.png'} alt='WHY 1' style={{width:'72.58px',height:'80px'}} />
                        </div>
                        {layout.language === 'kor'?
                        <React.Fragment>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_'+ layout.mode +'.png'} alt='chapter li' style={{width:'6px',height:'6px', marginRight:'10px'}}/>가짜 뉴스와 저급한 컨텐츠</Typography>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />비효율적이고 불공평한 보상의 분배</Typography>
                            <div style={{ height: '44px' }} />
                            <Typography variant='body1'>
                                디지털 경제의 대다수 미디어 비즈니스 모델은 클릭 수와 좋아요 수 등 수치를 기준으로 생산자에게 보상을 분배합니다.<br />
                                이렇게 미디어 생태계는 점점 기계처럼 움직이고 있습니다. 그리고 숨은 의도(hidden agenda)를 가진 가짜 뉴스를 만들었습니다.<br />
                                언론이 전달하는 정확한 정보’에 의지해 생각하고 판단하고 행동할 수 있는 힘을 얻던 사람들은 점점 정확한 정보’를 찾기 어려워졌습니다.
                            </Typography>
                        </React.Fragment>
                        : layout.language === 'eng'? 
                        <React.Fragment>
                            <div style={{height: '84px'}}>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_'+ layout.mode +'.png'} alt='chapter li' style={{width:'6px',height:'6px', marginRight:'10px'}}/>Fake news and low quality contents</Typography>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />Inefficient and unfair distribution of rewards</Typography>
                            </div>
                            <div style={{ height: '44px' }} />
                            <Typography variant='body1'>
                                Most media business models based on digital economy distribute rewards to content producers based on quantitative counts such as 'clicks' and 'likes'.<br />
                                The media ecosystem now is operating like a cold machine. This operating system gave birth to fake news and deep fake videos with hidden agendas.<br />
                                'Accurate information" from media press gave people to think and act independently. However, finding "accurate information" is becoming more difficult. 
                            </Typography>
                            </React.Fragment>
                        :
                        <React.Fragment>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_'+ layout.mode +'.png'} alt='chapter li' style={{width:'6px',height:'6px', marginRight:'10px'}}/>虚假新闻与低俗内容</Typography>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />低效率、不公平的报酬分配</Typography>
                            <div style={{ height: '44px' }} />
                            <Typography variant='body1'>
                                数字经济环境中的大多数媒体商务模型通过点击量、点赞数等数值，向撰稿人提供报酬。<br/>
                                这样的媒体生态运行方式逐渐机械化。与此同时，还会制造出带有隐藏意图（hidden agenda）的虚假新闻。<br/>
                                以“媒体传递的准确信息”进行思考与判断，并把这些作为行动源动力的人们开始逐渐很难找到“准确的信息”。
                            </Typography>
                        </React.Fragment>
                        }

                    </div>
                    <div className={classes.card}>
                        <div style={{ height: '120px' }}>
                            <img src={'/images/profile/how/ic_how_chap1_2_' + layout.mode + '.png'} alt='WHY 2' style={{ width: '101.73px', height: '71.79px' }} />
                        </div>
                        {layout.language === 'kor'? 
                            <React.Fragment>
                                <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />소통이 단절된 미디어</Typography>
                                <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />셀 수 없는 대중의 분열과 높아지는 개인의 장벽</Typography>
                                <div style={{ height: '44px' }} />
                                <Typography variant='body1'>
                                    미디어가 제공하는 연결 구조는 그 자체로 사람들의 정보 관계망이자 컨텐츠가 이동하는 연결 통로입니다.<br />
                                    하지만 현재 언론 미디어는 소통하지도 대중의 피드백을 듣지 않는 일방 적인 구조 입니다.<br />
                                    대중의 극단적 양극화와 자신과 같은 의견을 가진 집단에서만 소통하는 극도의 양극화(extreme echo chamber)현상을 초래한 원인이기도 합니다.
                                </Typography>
                            </React.Fragment>
                        : layout.language === 'eng'?
                            <React.Fragment>
                                <div style={{height: '84px'}}>
                                <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />Disconnected media</Typography>
                                <Typography variant='body2' style={{ display: 'flex', alignItems: 'baseline' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />Countless public fragmentations and rising individual barriers.</Typography>
                                </div>
                                <div style={{ height: '44px' }} />
                                <Typography variant='body1'>
                                    The connection structure that the media provides is the information network and the path that the contents move.<br />
                                    However, existing media presses are one-way where they don't communicate and receive feedback from the audiences.<br />
                                    This resulted in extreme polarization of the public and a closed community with people that have same opinions (Extreme Echo Chamber).
                                </Typography>
                            </React.Fragment>
                        : 
                            <React.Fragment>
                                <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />失去沟通的媒体</Typography>
                                <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />数不尽的公众分歧与越垒越高的个人屏障</Typography>
                                <div style={{ height: '44px' }} />
                                <Typography variant='body1'>
                                    媒体提供的连接结构，其本身就是人们的信息关系网络与内容移动的链接通道。<br/>
                                    但是当下的新闻媒体，却是一种不仅不交流，还不听公众反馈的单向型结构。<br/>
                                    这也是导致公众的两极化分化加剧，只与自己意见相同的集团沟通的两极化（extreme echo chamber）现象严重的原因。
                                </Typography>
                            </React.Fragment>
                    } 

                    </div>
                    <div className={classes.card}>
                        <div style={{ height: '120px' }}>
                            <img src={'/images/profile/how/ic_how_chap1_3_' + layout.mode + '.png'} alt='WHY 3' style={{ width: '89.55px', height: '79.04px' }} />
                        </div>
                        {layout.language === 'kor'? 
                        <React.Fragment>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_'+ layout.mode +'.png'} alt='chapter li' style={{width:'6px',height:'6px', marginRight:'10px'}}/>기술 기업의 컨텐츠 유통 독점</Typography>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />신뢰를 잃은 뉴스 미디어</Typography>
                            <div style={{ height: '44px' }} />
                            <Typography variant='body1'>
                                전 세계적으로 콘텐츠의 유통은 소수의 대형 언론 유통 기업이 독점하고 있습니다.<br/>
                                유통 채널이 없는 언론은 저널리즘 및 미디어의 가치와 역할을 잃어버립니다.<br />
                                신뢰를 잃은 콘텐츠를 접하며 사람들은 어디까지 믿어야 하는지 생각하는지 고민합니다. 대중이 귀를 닫은 언론은 사회의 근간을 흔드는 위험 요소입니다. 
                            </Typography>
                        </React.Fragment>
                        : layout.language === 'eng'?
                        <React.Fragment>
                            <div style={{height: '84px'}}>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'baseline' }}><img src={'/images/common/ic_chap_circle2_'+ layout.mode +'.png'} alt='chapter li' style={{width:'6px',height:'6px', marginRight:'10px'}}/>Contents distribution monopoly of technological enterprises </Typography>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />Newsmedia that has lost its credibility</Typography>
                            </div>
                            <div style={{ height: '44px' }} />
                            <Typography variant='body1'>
                                The distribution of content worldwide is monopolized by a handful of major media distribution companies.<br/>
                                Media without a distribution channel loses the value and roles of journalism and media.<br/>
                                People concern about how much they can trust when they view contents that have lost their faith. <br/>
                                Media without public trust is a risk factor on shaking the foundation of the society.
                            </Typography>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_'+ layout.mode +'.png'} alt='chapter li' style={{width:'6px',height:'6px', marginRight:'10px'}}/>科技企业的内容分发垄断</Typography>
                            <Typography variant='body2' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle2_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '10px' }} />失去信任的新闻媒体</Typography>
                            <div style={{ height: '44px' }} />
                            <Typography variant='body1'>
                                全世界范围内，内容分发已经被少数的大型新闻分发企业垄断。<br/>
                                失去分发渠道的媒体，面临失去新闻与媒体价值与职责的境地。<br/>
                                看到失去信任的内容，人们不禁开始怀疑其可信度到底有几分。<br/>
                                捂住公众耳朵的新闻媒体，是动摇社会基石的危险因素。
                            </Typography>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React from 'react';
import { Fade, makeStyles, Typography } from '@material-ui/core';
import { useCountUp } from 'react-countup';

const useStyles = makeStyles(theme => ({
    color:{
        color:theme.palette.text.secondary
    }
}));

const Counter = ({ countNum }) => {

    const classes = useStyles();

    const { countUp } = useCountUp({
        duration: 3,
        separator: ",",
        start: 1,
        end: countNum,
    });

    return (
        <React.Fragment>
            <Fade in={true}>
                <Typography variant='subtitle1' className={classes.color}>{countUp}&nbsp;&nbsp;+</Typography>
            </Fade>
        </React.Fragment>
    );
};

export default Counter;
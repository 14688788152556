import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { IconButton, Grid, Hidden, Dialog } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { LARGE, SMALL } from '../../common/constants';


const AlphaPopNew = ({layout}) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        handleOpen();
    }, []);

    const handleOpen = () => {
        const loc = localStorage.getItem("TODAY_POP_DIS");
        const now = new Date();
        if (loc && loc === (now.getMonth() + 1 + '' + now.getDate())){
            setOpen(false);
        }else{ 
            setOpen(true);
        }
    };

    const todayNot = () => {
        const now = new Date()
        localStorage.setItem("TODAY_POP_DIS", now.getMonth()+1+''+now.getDate());
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
             <React.Fragment>
                {layout.sizeMode === LARGE ?
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    style={{ position: 'fixed', top: '20%', left: 'calc(100% - 830px)', background: 'rgba(0,0,0,0)', width: '800px', height: '600px' }}
                        hideBackdrop={true}
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                        disableScrollLock={true}
                    >
                        <div style={{position:'relative'}}>
                            <img src={`/images/common/pop_alpha_new.png`} style={{ width: '800px', height:'600px' }} alt='ROZEUS ALPHA START' />
                            <div style={{ position: 'absolute', top: 20, right: 20, width: '30px', height: '30px', cursor: 'pointer' }} onClick={handleClose}/>
                            <div style={{ position: 'absolute', top: 367, left: 37, width: '267px', height: '40px', cursor: 'pointer' }} onClick={()=>window.open('https://www.rozeus.com')} />
                            <div style={{ position: 'absolute', top: 490, left: 30, width: '171px', height: '60px', cursor: 'pointer' }} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bflysoft.rozeus')} />
                            <div style={{ position: 'absolute', top: 490, left: 212, width: '171px', height: '60px', cursor: 'pointer' }} onClick={() => window.open('https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887')} />
                        </div>
                    </Modal>
                :
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        fullScreen={true}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ width: '100%' }}
                    >
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <img src={`/images/common/pop_alpha_new_m.png`} style={{ width: '100%' }} alt='ROZEUS ALPHA START' />
                            <div style={{ position: 'absolute', bottom:0, left: 0, width: '100%', height: '46px', cursor: 'pointer' }} onClick={handleClose} />
                            <div style={{ position: 'absolute', top: '47%', left: 27, width: '50%', height: '4%', cursor: 'pointer' }} onClick={() => window.open('https://www.rozeus.com')} />
                            <div style={{ position: 'absolute', top: '57.5%', left: 25, width: '36%', height: '6%', cursor: 'pointer' }} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bflysoft.rozeus')} />
                            <div style={{ position: 'absolute', top: '65%', left: 25, width: '36%', height: '6%', cursor: 'pointer' }} onClick={() => window.open('https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887')} />
                        </div>
                    </Dialog>
                }
            </React.Fragment>
    )
}

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(AlphaPopNew));
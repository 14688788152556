import React from 'react';

import { Hidden, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    button:{
        color: theme.palette.button2.text,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background: theme.palette.button2.normal,
        borderRadius:'6px',
        cursor:'pointer',
        transition: "all 0.3s ease-in",
        MozTransition: "all 0.3s ease-in",
        WebkitTransition: "all 0.3s ease-in",
        msTransition: "all 0.3s ease-in",
        OTransition: "all 0.3s ease-in",
        "&:hover": {
            borderColor: theme.palette.button2.hover,
            background: theme.palette.button2.hover,
        },
        "&:active": {
            borderColor: theme.palette.button2.active,
            background: theme.palette.button2.active,
        },
    }
}));

const CustomButton = ({ layout, text, size, img, onclick }) => {

    const classes = useStyles();
    return (
        <div
            className={classes.button} 
            style={{width:size.width, height:size.height}}
            onClick={onclick}
        >
            <Typography variant='body2' style={{ color: 'inherit' }}>{text}</Typography>
            {img &&
                <React.Fragment>
                    <Hidden xsDown>
                        <div style={{ width: '12px' }} />
                    </Hidden>
                    <Hidden smUp>
                        <div style={{ width: '8px' }} />
                    </Hidden>
                    <div>
                        <img src={img.src} alt='button icon' style={{width:img.width}} />
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(CustomButton));
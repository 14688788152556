import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
    body: {
        position: 'relative',
        marginBottom: '15px',
    },
    bgColorDiv: {
        position: 'absolute',
        top: 15,
        left:'20%',
        width: '60%',
        height:'100%',
        backgroundColor: theme.palette.background.sub.subBackground,
        zIndex: -1,
    },
    card: {
        width: '100%',
        backgroundColor: theme.palette.background.sub.chapCard,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        padding:'40px 20px'
    },
    divider:{
        backgroundColor:theme.palette.menuUnderLine
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div className={classes.bgColorDiv} />
                <div>
                    <Typography variant='h1' style={{ textAlign: 'center' }}>The How</Typography>
                    <div style={{height:'100px'}}/>
                    <div
                        data-aos='fade-up'
                        data-aos-duration={aos_option.duration}
                        data-aos-offset={aos_option.offset}
                    >
                        <div style={{display:'flex'}}>
                            <Typography variant='subtitle1'>01</Typography>
                            <div style={{width:'12px', minWidth:'12px'}}/>
                            {layout.language === 'kor'? 
                            <div >
                                <Typography variant='subtitle2' style={{fontSize:'16px'}}>스스로 정화하는 신뢰기반<br/> 미디어 생태계</Typography>
                                <div style={{ height: '12px' }} />
                                <Typography variant='body1'>
                                    대중의 집단지성의 미디어 활용능력(Media literacy)을 이용한 신뢰 기반의 콘텐츠 생태계<br/>
                                    독립적이고 자체 개발된 메인넷을 기반으로 스스로 정화하며 선순환하는 콘텐츠 생태계
                                </Typography>
                            </div>
                            :layout.language === 'eng'?
                            <div >
                                <Typography variant='subtitle2' style={{fontSize:'16px'}}>A Trust-based media ecosystem that self cleanses.</Typography>
                                <div style={{ height: '12px' }} />
                                <Typography variant='body1'>
                                    A Trust-based content ecosystem that utilizes media literacy of the public's collective intelligence.<br/>
                                    Self-purifying virtuous cycle media ecosystem based on independent and self-developed main net.
                                </Typography>
                            </div> 
                            :
                            <div >
                                <Typography variant='subtitle2' style={{fontSize:'16px'}}>自主净化的基于信任的媒体生态体系</Typography>
                                <div style={{ height: '12px' }} />
                                <Typography variant='body1'>
                                    通过公众的集体智慧
                                </Typography>
                            </div>
                            }

                        </div>
                        <div style={{ height: '50px' }} />
                        <div style={{ display: 'flex' }}>
                            <Typography variant='subtitle1'>02</Typography>
                            <div style={{width:'12px', minWidth:'12px'}}/>
                            {layout.language === 'kor'? 
                            <div >
                                <Typography variant='subtitle2' style={{ fontSize: '16px' }}>경쟁이 아닌 상생을 위한<br />미디어 지원 플랫폼</Typography>
                                <div style={{ height: '12px' }} />
                                <Typography variant='body1'>
                                    기존 미디어 산업의 모든 구성원(기존미디어 등)이 블록체인을 기반으로 하는 독립적인 댑 인프라로의 이전을 무상으로 지원하는 플랫폼<br/>
                                    미디어 산업의 본질적 가치 유지를 위한 활동(정확한 정보의 생산과 건강한 콘텐츠 소비/생산 문화의 구축 등)중심의 투명한 수익 구조를 가진 플랫폼
                                </Typography>
                            </div>
                            :layout.language === 'eng'?
                            <div >
                                <Typography variant='subtitle2' style={{ fontSize: '16px' }}>Media supporting platform<br /> for co-prosperity, not competition.</Typography>
                                <div style={{ height: '12px' }} />
                                <Typography variant='body1'>
                                    A platform that enables all members of the existing media industry (such as existing media) to move free of charge to an independent, blockchain-based dApp infrastructure. <br/>
                                    A platform with a transparent revenue structure centered on activities to maintain the intrinsic value of the media industry (production of accurate information and the establishment of a healthy content consumption/production culture, etc.
                                </Typography>
                            </div>
                            :
                            <div >
                                <Typography variant='subtitle2' style={{ fontSize: '16px' }}>不为竞争、<br/> 只为共生的媒体支持平台</Typography>
                                <div style={{ height: '12px' }} />
                                <Typography variant='body1'>
                                    免费支持传统媒体产业的所有组成者（传统媒体等）通过基于区块链的独立dApp基础，进行数字化的平台。<br/>
                                    拥有以保持媒体行业本质价值的活动（构建制作准确的信息与制作/消费健康内容文化等）为中心的透明收益分配结构的平台。
                                </Typography>
                            </div>

                            }

                        </div>
                        <div style={{ height: '50px' }} />
                        <div style={{ display: 'flex' }}>
                            <Typography variant='subtitle1'>03</Typography>
                            <div style={{width:'12px', minWidth:'12px'}}/>
                            {layout.language === 'kor'? 
                                <div >
                                    <Typography variant='subtitle2' style={{ fontSize: '16px' }}>공평하고 투명한 수익<br/>능동적 소비 행동 UX</Typography>
                                    <div style={{ height: '12px' }} />
                                    {/**
                                    <Typography variant='body1'>
                                        생태계에 기여한 모든 참여자들에게 공평하고 공정한 수익 분배의 실현<br/>
                                        블록체인에 익숙하지 않은 대중도 “블록체인과 암호화폐”의 생태계로 쉽게 접근할 수 있는 진입로의 역할 그리고 능동적 콘텐츠 소비를 위해 최적화된 UX(User Experience)의 제공
                                    </Typography>
                                     */}
                                    <Typography variant='body1'>
                                        블록체인 기반의 미디어 산업에 최적화된 블록체인 플랫폼<br />
                                        공평한 환경과 투명한 보상시스템으로 미디어 생산자들이 독립적이고 공평하게 활동할 수 있는 환경제공
                                    </Typography>
                                </div>
                            :layout.language === 'eng'?
                                <div >
                                    <Typography variant='subtitle2' style={{ fontSize: '16px' }}>Fair and transparent revenue <br/>for active consumption behavior UX</Typography>
                                    <div style={{ height: '12px' }} />
                                    {/**
                                    <Typography variant='body1'>
                                        Fulfill impartial and fair distribution of revenue to all participants that contributed to the ecosystem. <br/>
                                        An easy-entry pathway for the public that is not familiar with the ecosystem of "blockchain cryptocurrency." Provide optimized UX (User Experience) for active content consumption.
                                    </Typography>
                                     */}
                                    <Typography variant='body1'>
                                        Optimized blockchain platform for blockchain based media industry.<br />
                                        Provide a fair environment where media producers can act independently and impartially through a transparent compensation system.
                                    </Typography>
                                 </div>
                            :
                                <div >
                                    <Typography variant='subtitle2' style={{ fontSize: '16px'}}>公平、透明且能够进行 <br/> 自主创作的UX</Typography>
                                    <div style={{ height: '12px' }} />
                                    {/**
                                    <Typography variant='body1'>
                                        为生态系统做出贡献的所有参与者，提供公平公正的收入分配 <br/> 
                                        即便是不熟悉区块链的公众，也能轻松接触到“区块链与加密货币”的生态圈，并提供可以主动进行内容消费的最佳UX（User Experience）
                                    </Typography>
                                     */}
                                    <Typography variant='body1'>
                                        基于区块链技术的媒体行业优化区块链平台。<br/>
                                        采用公正透明的回报模式，为媒体人构建一个可以独立、公平报道的环境。
                                    </Typography>
                                 </div>
                            }

                        </div>
                    </div>
                    <div style={{ height: '120px' }} />
                    <div className={classes.card}>
                        <div style={{width:'100%'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <img src={'/images/profile/how/ic_how_chap3_problem_' + layout.mode + '.png'} alt='Problems' style={{ width: '20px', height: '18px' }} />
                                <div style={{ width: '9.5px' }} />
                                {layout.language === 'cn'? 
                                <Typography variant='body2' >问题</Typography>
                                :
                                <Typography variant='body2'>Problems</Typography>
                                 }
                                

                            </div>
                            <div style={{ height: '16px' }} />
                            {layout.language === 'kor'? 
                                <React.Fragment>
                                <Divider className={classes.divider} />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>소수 유통사의 유통 독점</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>사용자의 수동적 소비에 의존</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>잃어버린 신뢰와 가짜뉴스</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>컨텐츠 질의 저하</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>뉴스컨텐츠 소비 후 사용자의 부정적 감정 경험</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>비 효율적 UX</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>불공평한 보상 분배</Typography>
                                </React.Fragment>
                            :layout.language === 'eng'?
                                <React.Fragment>
                                <Divider className={classes.divider} />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>Distribution monopoly by a handful of media distributors</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>High dependence on passive consumption of users.</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>Lost of trust and fake news</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>Degrading content quality</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>Users' experience of negative reactions from news contents consumption</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>Inefficient UX</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>Unfair distribution of rewards </Typography>
                                </React.Fragment>
                            
                            :
                                <React.Fragment>
                                <Divider className={classes.divider} />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>少数流通公司垄断流通</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>依赖用户选择消费</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>失去信任与虚假新闻</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>内容品质低下</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>阅读新闻内容时，用户体验糟糕</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>无效率的UX</Typography>
                                <Divider />
                                <Typography variant='h3' style={{ margin: '9px 0px' }}>不公正的收益分配</Typography>
                                </React.Fragment>
                            }

                        </div>
                        <div style={{ height: '70px' }} />
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={'/images/profile/how/ic_how_chap3_solutions_' + layout.mode + '.png'} alt='Solutions' style={{ width: '18px', height: '18px' }} />
                                <div style={{ width: '9.5px' }} />
                                {layout.language === 'cn'? 
                                <Typography variant='body2' >解决方案</Typography>
                                :
                                <Typography variant='body2'>Solutions</Typography>
                                }
                               
                            </div>
                            <div style={{ height: '16px' }} />
                            <Divider className={classes.divider} />
                            {layout.language === 'eng'? 
                            <React.Fragment>
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Infrastructure that ensures the independent operation of media editorial rights</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Induce an active (mission) contents culture</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Trustworthy contents based on collective intelligence</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Develop a system that directly connects quality and rewards and internalizes trustful contents value through fact-chain.</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Funding crowd citizen journalism through communication and suggestions by both producers (journalists) and consumers.</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Curation for personalized contents</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Disclosure of transparent value flow and fair distribution of rewards for a real-time check.</Typography>
                            </React.Fragment>
                            :layout.language === 'kor'? 
                            <React.Fragment>
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>미디어 편집권의 독립적 운영을 보장하는 인프라</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>능동적(미션) 컨텐츠 문화 유도</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>집단 지성 기반 신뢰 컨텐츠</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>퀄리티와 보상을 직결시키는 시스템을 구축하고 팩트체인을 통해 신뢰 컨텐츠 가치를 내재화</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>생산자(기자)와 소비자가 함께 취재 주제를 제안하고 소통해서 크라우드 시민 저널리즘을 펀딩</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>개인별 맞춤 컨텐츠 큐레이션</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>실시간으로 확인 할 수 있도록 투명한 가치 흐름의 공개와 공평한 보상 분배</Typography>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>确保媒体编辑权独立运作的基础设施</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>引导主动（作业）型内容消费文化</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>基于集体智慧的值得信赖的内容</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>建立质量与补偿直接关联的系统，并通过事实链确保可信内容的价值</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>生产者（记者）与消费者共同提出并交流采访主题，并支持市民众筹新闻</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>个性化定制内容推荐</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>公开可以进行实时确认透明价值连和公平的收益分配</Typography>
                            </React.Fragment>
                            }

                        </div>
                        <div style={{ height: '70px' }} />
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={'/images/profile/how/ic_how_chap3_star_' + layout.mode + '.png'} alt='Features' style={{ width: '19px', height: '18.5px' }} />
                                <div style={{ width: '9.5px' }} />
                                {layout.language === 'cn'? 
                                <Typography variant='body2'>核心</Typography>
                                :
                                <Typography variant='body2'>Key Features</Typography>
                                }

                            </div>
                            <div style={{ height: '16px' }} />
                            <Divider className={classes.divider} />
                            {layout.language === 'kor'? 
                            <React.Fragment>
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>ROZEUS dApp</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>합리적인 보상 엔진(시스템)</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>팩트체킹(신뢰도 표준화) 엔진</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>자가 정화 엔진 (수질관리 프로토콜)</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>미디어 지원 엔진</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>AI 기반 맞춤 탐색 엔진</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>ROZEUS 블록체인</Typography>
                            </React.Fragment>
                            :layout.language === 'eng'? 
                            <React.Fragment>
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>ROZEUS dApp</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Reasonable reward engine (system)</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Fact-checking (credibility standardization) engine</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Self-purifying engine (Self-purification protocol)</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>Media support engine</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>AI based curation engine</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>ROZEUS Blockchain</Typography>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>ROZEUS dApp</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>合理的收益分配引擎（系统）</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>事实核查（标准化信任度）引擎</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>自主净化引擎（</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>媒体支持引擎</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>基于AI的个性化定制搜索引擎</Typography>
                            <Divider />
                            <Typography variant='h3' style={{ margin: '9px 0px' }}>ROZEUS区块链</Typography>
                            </React.Fragment>
                            }
                        </div>
                    </div>
                    <div style={{ height: '30px' }} />
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React, { useEffect } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    bgColorDiv: {
        position: 'absolute',
        width: '100%',
        height: '1210px',
        backgroundColor: theme.palette.background.sub.subBackground,
        zIndex:-2
    },
    bgImgDiv:{
        position:'absolute',
        top:'297px',
        right: 'calc(763px + 174px)',
        width:'482px',
        height: '730px',
        backgroundSize:'482px 730px',
        backgroundRepeat:'no-repeat',
        zIndex: -1
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    chapter1Card : {
        width: '33.3%', 
        height: '500px',
        minHeight: '500px',
        backgroundColor: theme.palette.background.sub.chapCard,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div className={classes.bodyWrap}>
            <div className={classes.bgColorDiv} />
            <div className={classes.body}>
                <div className={classes.bgImgDiv} style={{ backgroundImage: 'url(/images/profile/rozeus/back_rozeus_chap1_' + layout.mode + '.png)' }} />
                <div style={{height:'150px'}} />
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <div 
                        data-aos='fade-up'
                        data-aos-duration={aos_option.duration}
                        data-aos-offset={aos_option.offset}
                        >
                        {layout.language === 'kor'? 
                            <React.Fragment>
                            <Typography variant='h1'>로제우스 소개</Typography>
                            <div style={{ height: '30px' }} />
                            {/**
                            <Typography variant='body1'>로제우스는 모든 사람들이 미디어를 통해 어울리고 작용하는 데이터 기반 플랫폼과 디지털 화폐인 로즈(ROZ)가</Typography>
                            <Typography variant='body1'>순환하는 바탕이 되는 모든 기반 기술과 시스템입니다.</Typography>
                            <Typography variant='body1'>현재 미디어 산업의 문제를 해결하기 위한 솔루션이자 미디어 특화 글로벌 오픈</Typography>
                            <Typography variant='body1'>플랫폼 입니다.</Typography>
                             */}
                            <Typography variant='body1'>로제우스는 모든 사람들이 미디어를 통해 어울리고 작용하는 데이터 기반 플랫폼이며,</Typography>
                            <Typography variant='body1'>현재 미디어 산업의 문제를 해결하기 위한 솔루션이자 미디어 특화 글로벌 오픈 플랫폼 입니다.</Typography>
                            <div style={{ height: '81px' }} />
                            <Typography variant='h1'>Strong Points</Typography>
                            <div style={{ height: '30px' }} />
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />집단지성의 미디어 활용능력과 콘텐츠 신뢰 측정 인덱스를 사용합니다.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />콘텐츠에 신뢰도를 수치화 합니다.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />개인의 플랫폼 기여도를 보상합니다.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />가치 공유를 통해 공평하고 투명한 생태계를 운영합니다.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />가치 공유 현황을 위한 별도의 서비스를 제공합니다.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />사용자의 소비 형태에 최적화된 UX를 제공합니다.</Typography>
                            <Typography variant='body1' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '14px' }} />전문적인 큐레이션과 맞춤형 큐레이션등을 연구합니다.</Typography>
                            </React.Fragment>
                        : layout.language === 'eng'? 
                            <React.Fragment>
                            <Typography variant='h1'>About Rozeus</Typography>
                            <div style={{ height: '30px' }} />
                            {/**
                            <Typography variant='body1'>Rozeus is technology-based system for a data-based platform where everyone </Typography>
                            <Typography variant='body1'>can work together through the media, based on the circulation of our digital currency, ROZ.</Typography>
                            <Typography variant='body1'>We provide the solution as a specialist in media global open platform </Typography>
                            <Typography variant='body1'>for existing media issues in the industry. </Typography>
                             */}
                            <Typography variant='body1'>Rozeus is technology-based system for a data-based platform where everyone can work together</Typography>
                            <Typography variant='body1'> through the media. We provide the solution as a specialist in media global open platform for existing</Typography>
                            <Typography variant='body1'> media issues in the industry. </Typography>
                            <div style={{ height: '81px' }} />
                            <Typography variant='h1'>Strong Points</Typography>
                            <div style={{ height: '30px' }} />
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />Apply collective intelligence's media literacy and content credibility measurement index.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />Measure the credibility of news contents.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />Reward individual's contributions to the platform.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />Operate a fair and transparent ecosystem through sharing values.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />Provide separate services for the status of sharing values.</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />Provide optimized UX according to users' consumption patterns.</Typography>
                            <Typography variant='body1' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '14px' }} />Research for professional and customizing curation.</Typography>
                            </React.Fragment>
                        :
                            <React.Fragment>
                            <Typography variant='h1'>关于Rozeus</Typography>
                            <div style={{ height: '30px' }} />
                            {/**
                            <Typography variant='body1'>ROZEUS是让所有人通过媒体进行沟通与交流的基于数据的平台与通过数字货币</Typography>
                            <Typography variant='body1'>ROZ进行循环的所有技术基础与系统。</Typography>
                            <Typography variant='body1'>为解决媒体行业现存的问题，提供解决方案与专业国际化媒体开放平台。</Typography>
                             */}
                            <Typography variant='body1'>Rozeus是一个让每一位用户都能通过媒体进行交流与互动的基于数据的平台，</Typography>
                            <Typography variant='body1'>旨在解决媒体行业现有问题，也是一个专业的全球媒体开放平台。</Typography>
                            <div style={{ height: '81px' }} />
                            <Typography variant='h1'>Strong Points</Typography>
                            <div style={{ height: '30px' }} />
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />使用集体智慧的媒体使用能力与内容可信度测评指标。</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />将内容的可信度数值化。</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />为个人对平台的贡献程度提供报酬。</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />通过共享价值，运行公平而透明的生态系统。</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />为价值共享现状，提供特别服务。</Typography>
                            <Typography variant='body1' style={{display:'flex',alignItems:'center'}}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight:'14px' }} />提供最适合用户使用习惯的UX。</Typography>
                            <Typography variant='body1' style={{ display: 'flex', alignItems: 'center' }}><img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '6px', height: '6px', marginRight: '14px' }} />研究专业的新闻呈现模式与个性化定制内容等。</Typography>
                            </React.Fragment>

                        }
                    </div>
                </div>
                <div style={{height:'189px'}} />
                <div 
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                    style={{display:'flex', justifyContent:'center',width:'100%',maxWidth:'1240px',minWidth:'920px'}}>
                    <div className={classes.chapter1Card}>
                        <div style={{ height: '276px' }}>
                        <img src={'/images/profile/rozeus/card_rozeus_chap1_1_'+layout.mode+'.png'} alt="신뢰할 수 있는 콘텐츠" style={{width:'151.93px',height:'123.64px',marginTop:'82px', marginBottom:'70.36px'}}/>
                        </div>
                        {layout.language === 'kor'? 
                        <React.Fragment>
                            <Typography variant='body2'>신뢰할 수 있는 콘텐츠</Typography>
                            <div style={{ height: '12px' }}/>
                            <Typography variant='h3' style={{ margin:'0px 40px', width:'calc(100% - 80px)' }}>내가 읽고 있는 콘텐츠를 의심하기 시작한 때가 언제인가요? 효율적인 시스템으로 가짜 뉴스 검증 체계를 구현합니다. 검증된 사실의 위키를 만듭니다. 믿을 수 있는 콘텐츠가 의미있는 콘텐츠입니다. 의심하는 시간을 아낄 수 있습니다.</Typography>

                        </React.Fragment>
                        : layout.language === 'eng'? 
                        <React.Fragment>
                            <Typography variant='body2'>Reliable Contents</Typography>
                            <div style={{ height: '12px' }}/>
                            <Typography variant='h3' style={{ margin:'0px 40px', width:'calc(100% - 80px)' }}>When was it that you started doubting the contents that you are reading? 
                                                                                                              We provide an efficient fake news verification system. 
                                                                                                              Verified information makes a wiki of information. 
                                                                                                              Reliable contents have true values. 
                                                                                                              You can save the time spent doubting the contents.</Typography>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Typography variant='body2' style={{fontWeight:900}}>值得信任的内容</Typography>
                            <div style={{ height: '12px' }}/>
                            <Typography variant='h3' style={{ margin:'0px 40px', width:'calc(100% - 80px)' }}>什么时候开始怀疑自己看到的内容有问题呢？
                                                                                                              通过高效的系统，构建虚假新闻验证体系。
                                                                                                              制作事实验证维基。值得信任的内容才是有价值的内容。
                                                                                                              为用户节省疑惑时间。</Typography>

                        </React.Fragment>
                        } 

                    </div>
                    <div style={{ width: '24px' }}></div>
                    <div className={classes.chapter1Card}>
                        <div style={{ height:'276px'}}>
                        <img src={'/images/profile/rozeus/card_rozeus_chap1_2_' + layout.mode + '.png'} alt="모두에게 공평한 투명한 보상" style={{ width: '144.4px', height: '119.24px', marginTop: '76px', marginBottom: '62.76px' }} />
                        </div>
                        {layout.language === 'kor'? 
                            <React.Fragment>
                                <Typography variant='body2'>모두에게 공평한 투명한 보상</Typography>
                                <div style={{ height: '12px' }}/>
                                <Typography variant='h3' style={{ margin: '0px 40px', width: 'calc(100% - 80px)' }}>도움이 되는 글과 댓글을 작성하면 보상을 받습니다. 모든 종류의 긍적적인 활동은 영향력에 비례하는 보상을 받습니다. 전체 보상은 모두에게 자동 공개되며 투명하게 분배 됩니다. 미디어의 파워를 독자와 작가에게 돌려줍니다.</Typography>

                            </React.Fragment>
                        : layout.language === 'eng'?
                            <React.Fragment>
                                <Typography variant='body2' style={{paddingTop:'7px'}}>Fair and Transparent Rewards for All</Typography>
                                <div style={{ height: '12px' }}/>
                                <Typography variant='h3' style={{ margin: '0px 40px',paddingTop:'1px', width: 'calc(100% - 80px)' }}>Helpful contents and comments are rewarded. 
                                                                                                                                     All positive activities will be rewarded according to the impacts of the influences. 
                                                                                                                                     All rewards will be visible to everyone and distributed transparently. 
                                                                                                                                     The media power goes back to the readers and writers.</Typography>

                            </React.Fragment> 
                        :
                            <React.Fragment>
                                <Typography variant='body2' style={{fontWeight:900}}>为所有人提供公平而透明的回报</Typography>
                                <div style={{ height: '12px' }}/>
                                <Typography variant='h3' style={{ margin: '0px 40px', width: 'calc(100% - 80px)' }}>撰写有益文章与留言将得到奖励。<br/>
                                                                                                                    所有积极有益的活动，都将以影响力为基准得到奖励。<br/>
                                                                                                                    所有奖励将自动向所有人公开，并进行透明分配。<br/>
                                                                                                                    将媒体的力量交给读者与撰稿人。</Typography>

                            </React.Fragment> 
                        }
                    </div>
                    <div style={{ width: '24px' }}></div>
                    <div className={classes.chapter1Card}>
                        <div style={{ height: '276px' }}>
                        <img src={'/images/profile/rozeus/card_rozeus_chap1_3_' + layout.mode + '.png'} alt="편리한 개인별 시스템" style={{ width: '109.67px', height: '150px', marginTop: '71px', marginBottom: '55px' }}/>
                        </div>
                        {layout.language === 'kor'? 
                            <React.Fragment>
                                <Typography variant='body2'>편리한 개인별 시스템</Typography>
                                <div style={{height:'12px'}}/>
                                <Typography variant='h3' style={{ margin: '0px 40px', width: 'calc(100% - 80px)' }}>사용자 데이터가 쌓일 수록 개인을 위한 시스템이 점점 똑똑해집니다. 작가 등 생산자의 콘텐츠는 사용자 데이터를 기반으로 공평하게 노출됩니다.</Typography>

                            </React.Fragment>  
                        : layout.language === 'eng'?
                            <React.Fragment>
                                <Typography variant='body2'>Convenient Personalized System</Typography>
                                <div style={{height:'12px'}}/>
                                <Typography variant='h3' style={{ margin: '0px 40px', width: 'calc(100% - 80px)' }}>As users' data gathers, the system becomes more intelligent. <br/> 
                                                                                                                    Writers and their contents are exposed fairly based on the users' data.</Typography>

                            </React.Fragment>
                        :
                            <React.Fragment>
                                <Typography variant='body2' style={{fontWeight:900}}>便捷的个性化系统</Typography>
                                <div style={{height:'12px'}}/>
                                <Typography variant='h3' style={{ margin: '0px 40px', width: 'calc(100% - 80px)' }}>随着累计用户数据的增加，个性化定制系统将变得更加智能。撰稿人等内容制作人将以用户数据为基础得到公平的流量。</Typography>

                            </React.Fragment>
                        }  

                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
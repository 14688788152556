import React, { useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import SubMenuList from './SubMenuList';
import { inject, observer } from 'mobx-react';


const useStyles = makeStyles(theme => ({
    menu: {
        display: 'flex',
        alignItems: 'center',
        height: '60px',
        cursor: 'pointer',
        margin: '0px 4px',
    },
    menuHover: {
        display: 'flex',
        alignItems: 'center',
        height: '58px',
        marginTop: '2px',
        margin: '0px 4px',
        cursor: 'pointer',
        borderBottom: '2px solid',
        borderColor: theme.palette.menuUnderLine
    },
    menuActive:{
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 200,
        fontSize: '16px',
        lineHeight: '30px',
        color:theme.palette.text.highlight
    }

}));

const CustomMenuItem = ({ name, margin, layout, menu}) => {

    const classes = useStyles();
    const [over, setOver] = useState(false);

    const handleMouseOver = () => {
        setOver(true);
    }

    const handleMouseOut = () => {
        setOver(false)
    }
    
    const handleClick = (event) => {
        menu.select(name, event.currentTarget);
    }

    return (
        <React.Fragment>
            <div className={over || (menu.target === name) ? classes.menuHover : classes.menu} onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div style={{ margin: '0px 4px', display:'flex', alignItems:'center' }}>
                    <Typography variant='h5' style={{ fontFamily: 's-core_dream4_regular', height:'58px', display:'flex', alignItems:'center', marginTop:'2px'}} className={menu.target === name ? classes.menuActive : ''}>{name}</Typography>
                    {name === 'SERVICE' && <img src={'/images/common/footer/ic_upArrow_' + layout.mode + '.png'} alt='Up Arrow' style={{ marginLeft: '7.5px', width: '9px', height: '9px' }} />}
                </div>
            </div>
            {menu.subMenuObj &&
                <SubMenuList name={name} margin={margin} />
            }
        </React.Fragment>
    );
}

export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu
}))(observer(CustomMenuItem));

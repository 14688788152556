import React, { useCallback, useEffect, useState } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Counter from '../../home/Counter';

import { throttle } from 'lodash';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    bgColorDiv: {
        position: 'absolute',
        width: '100%',
        height: '1227px',
        backgroundColor: theme.palette.background.sub.subBackground,
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '1227px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();
    const [flag1, setFlag1] = useState(true);
    const [flag2, setFlag2] = useState(true);
    const [flag3, setFlag3] = useState(true);
    
    
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const listener = useCallback(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section && section.getBoundingClientRect().height;

        if ((height + scrollTop) > (sectionHeight + 820) && flag1) {
            setFlag1(false)
        }
        if ((height + scrollTop) > (sectionHeight + 820 + 160) && flag2) {
            setFlag2(false)
        }
        if ((height + scrollTop) > (sectionHeight + 820 + 320) && flag3) {
            setFlag3(false)
        }
    }, [flag1, flag2, flag3]);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener, true);
        return () => {
            window.removeEventListener("scroll", throttleListener, true);
        };
    }, [listener]);

    useEffect(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section && section.getBoundingClientRect().height;
        if ((height + scrollTop) > (sectionHeight + 265 + 120) && flag1) {
            setFlag1(false)
        }
        if ((height + scrollTop) > (sectionHeight + 265 + 120 + 160) && flag2) {
            setFlag2(false)
        }
        if ((height + scrollTop) > (sectionHeight + 265 + 120 + 320) && flag3) {
            setFlag3(false)
        }
    }, [flag1, flag2, flag3]);


    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={150}
            className={classes.bodyWrap}>
            <div className={classes.bgColorDiv} />
            <div className={classes.body}>
                <div style={{height:'200px'}} />
                {layout.language === 'kor'? 
                    <React.Fragment>
                        <Typography variant='h1'>2,300개 이상의 온라인 뉴스, 신문, 방송 등의 채널과 함께</Typography>
                        <Typography variant='h1'>280억 건 이상의 미디어 빅데이터를 쌓고 있습니다.</Typography>
                    </React.Fragment>
                : layout.language === 'eng'? 
                    <React.Fragment>
                        <Typography variant='h1'>We gather more than 28 billion media big data</Typography>
                        <Typography variant='h1'>together with more than 2,300 online news, newspaper,</Typography>
                        <Typography variant='h1'>and broadcasts channels.</Typography>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Typography variant='h1'>超过2,300个网络新闻、报纸、</Typography>
                        <Typography variant='h1'>电视等平台共同积累的超280亿条媒体大数据。</Typography>
                    </React.Fragment>
                }   
                <div style={{height:'30px'}} />
                {layout.language === 'kor'? 
                    <React.Fragment>
                        <Typography variant='body1'>다양한 유형의 데이터에 대응할 수 있는 최적화된 수집기반으로 방대한 미디어 데이터를 실시간 수집 및 가공합니다.</Typography>
                        <Typography variant='body1'>비정형 상태의 자연어 데이터를 처리하여 분석에 최적화된 형태로 정규화하는 자연어 처리(NLP) 엔진으로</Typography>
                        <Typography variant='body1'>딥러닝을 위한 핵심 전처리 요소기술을 개발합니다.</Typography>
                        <Typography variant='body1'>대용량의 정형, 비정형 데이터를 분산 저장하고, 빠르게 색인할 수 있는 빅데이터 플랫폼을 운영합니다.</Typography>
                    </React.Fragment>
                : layout.language === 'eng'? 
                    <React.Fragment>
                        <Typography variant='body1'>Through optimized collecting ability that can respond to different types of data, we collect and process vast media data in real-time.</Typography>
                        <Typography variant='body1'> We develop core preprocessing element technology for deep learning with Natural Language Processing (NLP) engine </Typography>
                        <Typography variant='body1'>to process unstructured natural language data and normalize it to an optimized form for analysis. </Typography>
                        <Typography variant='body1'>Operate big data platform to save structured and unstructured data and index them quickly.</Typography>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Typography variant='body1'>以可以应对多种类型数据的最佳收集平台为基础，实时收集并加工庞大的媒体数据。</Typography>
                        <Typography variant='body1'>通过最适合处理不规则自然语言数据形式的正规自然语言处理（NLP）引擎，开发深度学习的核心预处理信息。</Typography>
                        <Typography variant='body1'>分散储存大容量规则、不规则数据，并运行可以快速检索的大数据平台。</Typography>
                    </React.Fragment>
                }
                <div style={{ height: '140px' }} />
                <div>
                {layout.language === 'kor'? 
                    <Typography variant='body1'>미디어 빅 데이터 핵심 단어 280억 개</Typography>
                : layout.language === 'eng'? 
                    <Typography variant='body1'>28 Billion Media Big Data keywords</Typography>
                :
                    <Typography variant='body1'>媒体大数据核心单词280亿个</Typography>
                }
                    {flag1 ?
                        <Typography variant='subtitle1'>&nbsp;</Typography>
                        :
                        <Counter countNum={28000000000} />
                    }
                    <div style={{ height: '65px' }} />
                    {layout.language === 'kor'? 
                        <Typography variant='body1'>미디어 빅 데이터 의미 문장 28억개</Typography>
                    : layout.language === 'eng'? 
                        <Typography variant='body1'>2.8 Billion Media Big Data Sentences</Typography>
                    :
                        <Typography variant='body1'>媒体大数据有意义文章28亿条</Typography>
                    }
                    

                    {flag2 ?
                        <Typography variant='subtitle1'>&nbsp;</Typography>
                        :
                        <Counter countNum={2800000000} />
                    }
                    <div style={{ height: '65px' }} />
                    {layout.language === 'kor'? 
                        <Typography variant='body1'>미디어 빅 데이터 누적 기사 3.6억 개</Typography>
                    : layout.language === 'eng'? 
                        <Typography variant='body1'>360 Million Media Big Data Articles</Typography>
                    :
                        <Typography variant='body1'>媒体大数据累计新闻报道3.6亿条</Typography>
                    }
                    

                    {flag3 ?
                        <Typography variant='subtitle1'>&nbsp;</Typography>
                        :
                        <Counter countNum={360000000} />
                    }
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React, { useEffect } from 'react';
import { m_size } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Clients from './Clients';
import Partners from './Partners';
import CustomButton2 from '../../../common/CustomButton2';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    tab:{
        width: '100%',
        height: '62px',
        margin: 'auto',
        backgroundColor: theme.palette.background.sub.subBackground,
        borderBottom: '1px solid ' + theme.palette.subBox
    },
    tabBody: {
        width: '100%',
        height: '62px',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        display:'flex',
        alignItems:'flex-end',
    },
    tabItemEnable: {
        width: '50%',
        height: '57px',
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',
        cursor:'pointer',
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid ' + theme.palette.subBox,
        borderLeft: props => props.partnerTab === 0 ? '1px solid ' + theme.palette.default : '1px solid ' + theme.palette.subBox,
        borderRight: props => props.partnerTab === 1 ? '1px solid ' + theme.palette.default : '1px solid ' + theme.palette.subBox,
        borderBottom: '1px solid ' + theme.palette.background.default,
        borderRadius: '3px 3px 0px 0px / 3px 3px 0px 0px',
    },
    tabItemDisable: {
        width:'50%',
        height: '57px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.sub.subBackground,
        borderTop: '1px solid ' + theme.palette.background.subBackground,
        borderLeft: '1px solid ' + theme.palette.background.subBackground,
        borderRight: '1px solid ' + theme.palette.background.subBackground,
        borderBottom: '1px solid ' + theme.palette.subBox,
        '&:hover' : {
            backgroundColor: theme.palette.background.sub.tabHover,
            fontFamily: 's-core_dream4_regular',
        }
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
    borderColor: {
        backgroundColor: theme.palette.circleBorder
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const handleClick = () => {
        if(layout.partnerTab === 0){
            window.open('http://bflysoft.com/Partners.html?key=2#')
        }else{
            window.open('http://bflysoft.com/Partners.html?key=1#')
        }
    }

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.tab} >
                <div className={classes.tabBody}>
                    <Typography 
                        variant={layout.partnerTab === 0 ? 'body2' : 'body1'} 
                        className={layout.partnerTab === 0 ? classes.tabItemEnable : classes.tabItemDisable} 
                        onClick={(e) => layout.setPartnerTab(0)}>
                            CLIENTS
                    </Typography>
                    <Typography 
                        variant={layout.partnerTab === 1 ? 'body2' : 'body1'} 
                        className={layout.partnerTab === 1 ? classes.tabItemEnable : classes.tabItemDisable} 
                        onClick={(e) => layout.setPartnerTab(1)}>
                            PARTNERS
                    </Typography>
                </div>
            </div>
            <div className={classes.body}>
                <div style={{height:'100px'}} />
                {layout.partnerTab === 0 && <Clients />}
                {layout.partnerTab === 1 && <Partners />}
                <div style={{ height: '40px' }} />
                <Divider className={classes.borderColor} />
                <div style={{ height: '40px' }} />
                <div style={{display:'flex',justifyContent:'center'}} >
                <CustomButton2 
                    text={layout.partnerTab === 0 ? 'more clients' : 'more partners'} 
                    size={{ width: '130px', height: '35px' }} 
                    img={{
                        src:'/images/common/ic_button_arrow.png',
                        width:'5px',
                        height:'8.48px'
                    }}
                        onclick={handleClick}
                    />
                </div>
                <div style={{ height: '150px' }} />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
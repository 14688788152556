import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { makeStyles, Typography } from '@material-ui/core';
import NumberTitle from '../../home/NumberTitle';

import { inject, observer } from 'mobx-react';
import HomeChapterDivider from '../../home/HomeChapterDivider';

import MobileVideoComponent from './MobileVideoComponent';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '895px',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        padding: m_size.padding,
        backgroundImage: props => 'url(/images/home/back_chapter3_' + props.mode + '.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 419px',
        backgroundSize: '65% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div>
                    <NumberTitle
                        number='03'
                        text={
                            layout.language === 'kor'? 
                            ['미래 미디어의 초상', '플랫폼 리스의 기술']
                            :layout.language === 'eng'? 
                            ['Portrait of the Future','Media', 'Platformless Technology']
                            :
                            ['未来媒体初印象','平台租赁技术']
                        }
                        top='-62px'
                    /> 
                    <div style={{height:'30px'}} />
                    {layout.language === 'kor'? 
                        <div style={{ margin: '0px 0px', width:'328px'}}>
                            <Typography variant='body1'>· 성장할 수 있는 저널리즘을 지원합니다. </Typography>
                            <Typography variant='body1'>· 투명하고 건강한 미디어 생태계를 구축합니다.</Typography>
                            <div style={{ height: '20px' }} />
                            <Typography variant='body1'>로제우스는 15년간 언론 미디어 파트너와 함께 일했습니다. 미디어 네트워크, 미디어 서비스에 최적화된 핵심 기술 및 주요 엔진, 그리고 수십억 건의 편집 및 가공된 합법적 데이터를 가지고 있습니다.</Typography>
                        </div>
                    : layout.language === 'eng'? 
                        <div style={{ margin: '0px 0px', width:'328px'}}>
                            <Typography variant='body1'>· Support the sustainable growth of journalism</Typography>
                            <Typography variant='body1'>· Build up the transparent and healthy media ecosystem</Typography>
                        <div style={{ height: '20px' }} />
                            <Typography variant='body1'>Rozeus has worked with press media partners for 15 years.</Typography>
                            <Typography variant='body1'>It has secured media networks, core technologies and key engines optimized for media services, and billions of edited and processed legitimate data.</Typography>
                        </div>  
                    :
                    <div style={{ margin: '0px 0px', width:'328px'}}>
                        <Typography variant='body1'>· 支持考虑公众利益与发展的新闻媒体。</Typography>
                        <Typography variant='body1'>· 构建透明而健康的媒体生态体系。</Typography>
                        <div style={{ height: '20px' }} />
                        <Typography variant='body1'>ROZEUS在过去15年间，一直与新闻媒体合作发展。不仅拥有媒体人脉、最适合媒体服务的核心技术与主要引擎，还拥有数十亿编辑、加工的合法数据。</Typography>
                    </div> 

                    }

                    <div style={{ height: '50px' }} />
                    <HomeChapterDivider />
                    <div style={{ height: '80px' }} />
                    <div>
                        <MobileVideoComponent
                            imgPath='/images/home/small/video_thumnail_chap3.png'
                            videoPath='https://www.youtube.com/embed/lUmsCpF0aLA?autoplay=1&wmode=opaque'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));
import React, { useCallback, useEffect, useState } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { throttle } from 'lodash';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',

        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
    chartCircleBorder : {
        width: '22vw', 
        height: '22vw', 
        border: '1px solid ' + theme.palette.circleBorder,
        borderRadius: '50%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        flexDirection: 'column'
    },
    chartCircleFill: {
        width: '22vw',
        height: '22vw',
        animation: '$circle 0.6s ease-in normal',
        border: '1px solid ' + theme.palette.button.hover,
        background: theme.palette.button.hover,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    '@keyframes circle': {
        '0%':   { background: 'linear-gradient(to top, ' + theme.palette.background.default + ' 100%, ' + theme.palette.button.hover + ')' },
        '5%':   { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 95%, ' + theme.palette.button.hover + ')' },
        '10%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 90%, ' + theme.palette.button.hover + ')' },
        '15%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 85%, ' + theme.palette.button.hover + ')' },
        '20%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 80%, ' + theme.palette.button.hover + ')' },
        '25%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 75%, ' + theme.palette.button.hover + ')' },
        '30%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 70%, ' + theme.palette.button.hover + ')' },
        '35%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 65%, ' + theme.palette.button.hover + ')' },
        '40%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 60%, ' + theme.palette.button.hover + ')' },
        '45%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 55%, ' + theme.palette.button.hover + ')' },
        '50%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 50%, ' + theme.palette.button.hover + ')' },
        '55%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 45%, ' + theme.palette.button.hover + ')' },
        '60%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 40%, ' + theme.palette.button.hover + ')' },
        '65%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 35%, ' + theme.palette.button.hover + ')' },
        '70%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 30%, ' + theme.palette.button.hover + ')' },
        '75%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 25%, ' + theme.palette.button.hover + ')' },
        '80%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 20%, ' + theme.palette.button.hover + ')' },
        '85%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +  ' 10%, ' + theme.palette.button.hover + ')' },
        '90%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +   ' 5%, ' + theme.palette.button.hover + ')' },
        '95%':  { background: 'linear-gradient(to top, ' + theme.palette.background.default +   ' 2%, ' + theme.palette.button.hover + ')' },
        '100%': { background: 'linear-gradient(to top, ' + theme.palette.background.default +   ' 0%, ' + theme.palette.button.hover + ')' },
    }
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();
    const [scrollTop, setScrollTop] = useState(0);
    const [circleStart, setCircleStart] = useState(false);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const listener = useCallback(() => {
        const scroll = window.scrollY || window.pageYOffset;
        const section = document.querySelector("#top");
        const sectionHeight = section.getBoundingClientRect().height;
        const chap1Section = document.querySelector("#chap1");
        const chap1SectionHeight = chap1Section.getBoundingClientRect().height;
        setScrollTop(scroll - (sectionHeight + chap1SectionHeight + 100) + window.innerHeight / 2);
        if (scroll - (sectionHeight + chap1SectionHeight + 200) + window.innerHeight / 2 > (window.innerHeight / 2 + 120)){
            setCircleStart(true)
        }else{
            setCircleStart(false)
        }
    }, []);

    useEffect(() => {
        const throttleListener2 = throttle(listener, 100)
        window.addEventListener("scroll", throttleListener2, true);
        return () => {
            window.removeEventListener("scroll", throttleListener2, true);
        };
    }, [listener]);

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'100px'}} />
                <div >
                    <img
                        src={'/images/progress/numbers/small/chart_number_chap2_back_'+layout.mode+'_'+ layout.language+'.png'}
                        alt={'ChartBack'}
                        style={{
                            position: 'relative',
                            display:'block',
                            top: 0, left: 0,
                            width: '100%',
                            zIndex: -1000,
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: 100, left: 0,
                            width: 'calc(100% - 32px)',
                            height: scrollTop < 0 ? 0 : scrollTop,
                            margin:m_size.padding,
                            zIndex: -999,
                            backgroundSize: '100% auto',
                            backgroundImage: 'url(/images/progress/numbers/small/chart_number_chap2_over_' + layout.mode +'_'+ layout.language + '.png)',
                            backgroundRepeat:'no-repeat',
                        }}
                    />
                </div>
                <div style={{ width:'100%', display:'flex',justifyContent:'center', marginLeft:'2px'}}>
                    <div className={circleStart ? classes.chartCircleFill : classes.chartCircleBorder}>
                        { layout.language === 'cn'? 
                        <React.Fragment>
                            <Typography variant='subtitle2' style={circleStart ? { fontSize: '10px', lineHeight: '14px', color: '#ffffff' } : { fontSize: '10px', lineHeight: '14px', }}>媒体大数</Typography>
                            <Typography variant='subtitle2' style={circleStart ? { fontSize: '10px', lineHeight: '14px', color: '#ffffff' } : { fontSize: '10px', lineHeight: '14px', }}>据平台</Typography>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <Typography variant='subtitle2' style={circleStart ? { fontSize: '10px', lineHeight: '14px', color: '#ffffff' } : { fontSize: '10px', lineHeight: '14px', }}>Media</Typography>
                            <Typography variant='subtitle2' style={circleStart ? { fontSize: '10px', lineHeight: '14px', color: '#ffffff' } : { fontSize: '10px', lineHeight: '14px', }}>Big Data</Typography>
                            <Typography variant='subtitle2' style={circleStart ? { fontSize: '10px', lineHeight: '14px', color: '#ffffff' } : { fontSize: '10px', lineHeight: '14px', }}>Platform</Typography>
                        </React.Fragment>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
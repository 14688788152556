import React, { useEffect } from 'react';
import { size } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import Viewer from './Viewer';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'2862px',
        margin: 'auto',
        backgroundColor : theme.palette.background.sub.subBackground
    },
    body: {
        width: '100%',
        height: '2862px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },

}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 

            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'200px'}} />
                <div>
                    <Typography variant='h1'>Logo_Allvertical</Typography>
                    <div style={{ height: '40px' }} />
                    <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                        <Viewer type='light' text='Logo_Allvertical' img='/images/profile/pressKit/resources/Logo_Allvertical_red.png' hover={true} imgSize={{ width: '245px', height: '153.01px' }} url='/resources/pressKit/Logo_Allvertical_Red.ai' />
                        <Viewer type='dark' text='Logo_Allvertical' img='/images/profile/pressKit/resources/Logo_Allvertical_white.png' hover={true} imgSize={{ width: '245px', height: '153.01px' }} url='/resources/pressKit/Logo_Allvertical_White.ai' />
                    </div>
                </div>
                <div style={{ height: '150px' }} />
                <div>
                    <Typography variant='h1'>Logo_Allhorizontal</Typography>
                    <div style={{ height: '40px' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Viewer type='light' text='Logo_Allhorizontal' img='/images/profile/pressKit/resources/Logo_Allhorizontal_red.png' hover={true} imgSize={{ width: '300px', height: '48.92px' }} url='/resources/pressKit/Logo_Allhorizontal_Red.ai' />
                        <Viewer type='dark' text='Logo_Allhorizontal' img='/images/profile/pressKit/resources/Logo_Allhorizontal_white.png' hover={true} imgSize={{ width: '300px', height: '48.92px' }} url='/resources/pressKit/Logo_Allhorizontal_White.ai' />
                    </div>
                </div>
                <div style={{ height: '150px' }} />
                <div>
                    <Typography variant='h1'>Logo_Symvolonly</Typography>
                    <div style={{ height: '40px' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Viewer type='light' text='Logo_Symvolonly' img='/images/profile/pressKit/resources/Logo_Symvolonly_red.png' hover={true} imgSize={{ width: '100px', height: '110.91px' }} url='/resources/pressKit/Logo_SymvolOnly_Red.ai' />
                        <Viewer type='dark' text='Logo_Symvolonly' img='/images/profile/pressKit/resources/Logo_Symvolonly_white.png' hover={true} imgSize={{ width: '100px', height: '110.91px' }} url='/resources/pressKit/Logo_SymvolOnly_White.ai' />
                    </div>
                </div>
                <div style={{ height: '150px' }} />
                <div>
                    <Typography variant='h1'>Logo_Textonly</Typography>
                    <div style={{ height: '40px' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Viewer type='light' text='Logo_Textonly' img='/images/profile/pressKit/resources/Logo_Textonly_red.png' hover={true} imgSize={{ width: '300px', height: '37.63px' }} url='/resources/pressKit/Logo_Textonly_Red.ai' />
                        <Viewer type='dark' text='Logo_Textonly' img='/images/profile/pressKit/resources/Logo_Textonly_white.png' hover={true} imgSize={{ width: '300px', height: '37.63px' }} url='/resources/pressKit/Logo_Textonly_White.ai' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
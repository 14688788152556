import React from 'react';
import { m_size } from '../common/constants'

import { Divider, makeStyles } from '@material-ui/core';
 
const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '100% 553px',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
    color:{
        backgroundColor: theme.palette.footerDivider
    }
}));

const SmallFooterTemplate = ({ snsIcon, download, link, type }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                {snsIcon}
                <div style={{ height: '40px' }} />
                {type === 'main' && 
                    <React.Fragment>
                        <Divider className={classes.color}/>
                        <div style={{ height: '30px' }} />
                        {download}
                        <div style={{ height: '40px' }} />
                    </React.Fragment>
                }
            </div>
            {link}
        </div>
    )
}

export default SmallFooterTemplate;
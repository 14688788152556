import React, { useEffect, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    button:{
        color: theme.palette.text.primary,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        border: '1px solid '+theme.palette.menuUnderLine,
        borderRadius:'6px',
        transition: "all 0.3s ease-in",
        MozTransition: "all 0.3s ease-in",
        WebkitTransition: "all 0.3s ease-in",
        msTransition: "all 0.3s ease-in",
        OTransition: "all 0.3s ease-in",
        cursor:'pointer',
        "&:hover": {
            borderColor: theme.palette.button.hover,
            background: theme.palette.button.hover,
            color: theme.palette.button.hoverText,
        },
        "&:active": {
            borderColor: theme.palette.button.hactiveover,
            background: theme.palette.button.active,
            color: theme.palette.button.hoverText,
        },
    }
}));

const CustomButton = ({ layout, text, size, onclick }) => {

    const classes = useStyles();
    const [buttonIcon, setButtonIcon] = useState('/images/common/ic_button_end_' + layout.mode + '.png')

    useEffect(() => {
        setButtonIcon('/images/common/ic_button_end_' + layout.mode + '.png');
    },[layout.mode]);

    const handleMouseOver = () => {
        setButtonIcon('/images/common/ic_button_end_dark.png');
    }

    const handleMouseOut = () => {
        setButtonIcon('/images/common/ic_button_end_' + layout.mode + '.png');
    }

    const handleMouseDown = () => {
        setButtonIcon('/images/common/ic_button_end_dark.png');
    }


    return (
        <div
            className={classes.button} 
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onClick={onclick}
            style={{
                width: size.width,
                height: size.height,
            }}
        >
            <Typography variant='body1' style={{ color: 'inherit' }}>{text}</Typography>
            <div style={{width:'20px'}} />
            <img src={buttonIcon} alt='button' style={{ width: '16px', height: '19.43px' }} />
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(CustomButton));
import React, { useEffect } from 'react';
import { size, aos_option } from '../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import NumberTitle from './NumberTitle';
import VideoComponent from './VideoComponent';
import HomeChapterDivider from './HomeChapterDivider';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '703px',
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 133px',
        backgroundSize: '600px 496.91px',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '703px',
        margin:'auto',
    },
    contentWrap: {
        position: 'absolute', 
        left: '50%', 
        width: '50%', 
        height: '100%',
    },
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap} 
            style={{ backgroundImage: 'url(/images/home/back_chapter2_' + layout.mode +'.png)'}}>
            <div 
                data-aos='fade-up'
                data-aos-duration={aos_option.duration}
                data-aos-offset={aos_option.offset}
                className={classes.body}>
                <VideoComponent 
                    imgPath='/images/home/video_thumnail_chap2.png'
                    videoPath='https://www.youtube.com/embed/bicQtTc5hU8?autoplay=1&wmode=opaque'
                    vertical='bottom' 
                    horizontal='left' 
                />
                <div style={{ height: '100px' }} />
                <div className={classes.contentWrap}>
                    <NumberTitle
                        number='02'
                        text={
                            layout.language === 'kor'? 
                            ['똑똑한 서비스', '투명한 플랫폼', '건강한 생태계']
                            : layout.language === 'eng'?
                            ['Smart Service', 'Transparent Platform', 'Healthy Ecosystem']
                            : 
                            ['合法数据', '专业伙伴', '共生媒体']
                        }
                        top='-84px'
                    />
                    <div style={{ height: '30px' }} />
                    
                        {layout.language === 'kor'? 
                         <div style={{ margin: '0px 0px' }}>
                            <Typography variant='body1'>대중과 언론의 소통을 돕고 트렌드와 사용자에 집중합니다.</Typography>
                            <Typography variant='body1'>효율적 미디어 산업을 위한 시스템과 인프라를 실현합니다.</Typography>
                            <Typography variant='body1'>뉴스와 사람이 만나는 프로세스를 혁신합니다.</Typography>
                        </div>
                        : layout.language === 'eng'? 
                        <div style={{ margin: '0px 0px' }}>
                            <Typography variant='body1'>Support public communication with the media and focus on trends and users.</Typography>
                            <Typography variant='body1'>Realize systems and infrastructure for the efficient media industry.</Typography>
                            <Typography variant='body1'>Renovate the joining process between news and people.</Typography>
                        </div>
                        : 
                        <div style={{ margin: '0px 0px' }}>
                            <Typography variant='body1'>搭建公众与新闻媒体之间的沟通桥梁，关注用户与趋势。</Typography>
                            <Typography variant='body1'>为实现媒体行业高效发展，构建完备的体系与基础。</Typography>
                            <Typography variant='body1'>创新人与新闻交汇的流程。</Typography>
                        </div>
                        }                      

                     
                    <div style={{ height: '50px' }} />
                    <HomeChapterDivider />
                </div>
            </div>
        </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
import React, { useState } from 'react';
import { menuList } from '../../../common/constants'

import { makeStyles, Divider, Typography, } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ModalPopup from './ModalPopup';

const useStyles = makeStyles(theme => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0px'
        }
    },
    bodyWrap: {
        width:'280px',
        minWidth: '280px',
        maxWidth: '280px',
        margin: '0px 16px',
    },
    bar:{
        position: 'fixed', 
        backgroundColor: theme.palette.background.paper,
        width:'280px',
        height:'50px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'flex-start',
    },
    linkTitle: {
        fontFamily: 's-core_dream6_bold',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    linkText: {
        fontFamily: 's-core_dream4_regular',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
    },
    menuItem:{
        display: 'flex', 
        justifyContent:'space-between',
        alignItems:'center',
        cursor: 'pointer'
    },
    mainBorder:{
        width: '280px',
        backgroundColor:theme.palette.circleBorder
    }
}));

const Menu = ({ layout, menu }) => {

    const classes = useStyles();
    const history = useHistory();
    const [aboutTarget, setAboutTarget] = useState('');
    const handleAboutClick = (target) => {
        setAboutTarget(target);
        layout.setShowAboutModal(true);
    }
    
    return (
        <div className={classes.bodyWrap} onClick={(e) => menu.setSmallMenusShow(false)}>
            <div className={classes.bar}>
                <div style={{ height:'16px' }}/>
                <img 
                    src={'/images/header/small/ic_m_menu_active_' + layout.mode + '.png'} 
                    alt='MENU' 
                    style={{ width: '18px', height: '16px', cursor: 'pointer'}} 
                />
                <div style={{ height: '15px' }} />
                <Divider className={classes.mainBorder}/>
            </div>
            <div style={{ height: '50px' }} />
            <div style={{ height: '47px'}} />
            <div>
                <Typography className={classes.linkTitle}>PROFILE</Typography>
                <div style={{ height: '16px' }} />
                {menuList['PROFILE'].map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={classes.menuItem} onClick={(e) => history.push(item.url)}>
                                <Typography className={classes.linkText}>{item.name}</Typography>
                                <img src={'/images/header/small/ic_m_menu_arrow_' + layout.mode + '.png'} alt='ARROW' style={{ width: '6px', height: '9.75px' }} />
                            </div>
                            {index < (menuList['PROFILE'].length - 1) && <div style={{ height: '16px' }} />}
                        </React.Fragment>
                    )
                })}
            </div>
            <div style={{ height: '30px' }} />
            <Divider />
            <div style={{ height: '30px' }} />
            <div>
                <Typography className={classes.linkTitle}>PROGRESS</Typography>
                <div style={{ height: '16px' }} />
                {menuList['PROGRESS'].map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={classes.menuItem} onClick={(e) => history.push(item.url)}>
                                <Typography className={classes.linkText}>{item.name}</Typography>
                                <img src={'/images/header/small/ic_m_menu_arrow_' + layout.mode + '.png'} alt='ARROW' style={{ width: '6px', height: '9.75px' }} />
                            </div>
                            {index < (menuList['PROGRESS'].length - 1) && <div style={{ height: '16px' }} />}
                        </React.Fragment>
                    )
                })}
            </div>
            <div style={{ height: '30px' }} />
            <Divider />
            <div style={{ height: '30px' }} />
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography className={classes.linkTitle}>Service </Typography><img src={'/images/common/footer/ic_upArrow_' + layout.mode + '.png'} alt='Up Arrow' style={{ marginLeft: '7.5px', width: '9px', height: '9px' }} />
                </div>
                <div style={{ height: '16px' }} />
                {menuList['SERVICE'].map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={classes.menuItem} onClick={(e) => window.open(item.url)}>
                                <Typography className={classes.linkText}>{item.name}</Typography>
                                <img src={'/images/header/small/ic_m_menu_arrow_' + layout.mode + '.png'} alt='ARROW' style={{ width: '6px', height: '9.75px' }} />
                            </div>
                            {index < (menuList['SERVICE'].length - 1) && <div style={{ height: '16px' }} />}
                        </React.Fragment>
                    )
                })}
            </div>
            <div style={{ height: '30px' }} />
            <Divider />
            <div style={{ height: '30px' }} />
            <div>
                <Typography className={classes.linkTitle}>ABOUT rozeus.io</Typography>
                <div style={{ height: '16px' }} />
                {menuList['ABOUT'].map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={classes.menuItem}  onClick={(e) => item.name === 'Contact Us' ? history.push(item.url) : handleAboutClick(item.name)}>
                                <Typography className={classes.linkText}>{item.name}</Typography>
                                <img src={'/images/header/small/ic_m_menu_arrow_' + layout.mode + '.png'} alt='ARROW' style={{ width: '6px', height: '9.75px' }} />
                            </div>
                            {index < (menuList['ABOUT'].length - 1) && <div style={{ height: '16px' }} />}
                        </React.Fragment>
                    )
                })}
            </div>
            <div style={{ height: '60px' }} />
            <ModalPopup target={aboutTarget} />
        </div>
    );
}

export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu
}))(observer(Menu));

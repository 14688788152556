import React, { useEffect } from 'react';
import { size } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Update from './Update';
import Event from './Event';
import News from './News';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    tab:{
        width: '100%',
        height: '62px',
        margin: 'auto',
        backgroundColor: theme.palette.background.sub.subBackground,
        borderBottom: '1px solid ' + theme.palette.subBox
    },
    tabBody: {
        width: '100%',
        height: '62px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
        display:'flex',
        alignItems:'flex-end',
    },
    tabItemEnable: {
        width: '165px',
        height: '57px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid ' + theme.palette.subBox,
        borderLeft: '1px solid ' + theme.palette.subBox,
        borderRight: '1px solid ' + theme.palette.subBox,
        borderBottom: '1px solid ' + theme.palette.background.default,
        borderRadius: '3px 3px 0px 0px / 3px 3px 0px 0px',
    },
    tabItemDisable: {
        width: '165px',
        height: '57px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.sub.subBackground,
        borderTop: '1px solid ' + theme.palette.background.subBackground,
        borderLeft: '1px solid ' + theme.palette.background.subBackground,
        borderRight: '1px solid ' + theme.palette.background.subBackground,
        borderBottom: '1px solid ' + theme.palette.subBox,
        '&:hover': {
            backgroundColor: theme.palette.background.sub.tabHover,
            fontFamily: 's-core_dream4_regular',
        }
    },
    body: {
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },

}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.tab} >
                <div className={classes.tabBody}>
                    <Typography 
                        variant={layout.newsPrTab === 0 ? 'body2' : 'body1'}
                        className={layout.newsPrTab === 0 ? classes.tabItemEnable : classes.tabItemDisable} 
                        onClick={(e) => layout.setNewsPrTab(0)}
                        >
                        UPDATES
                    </Typography>
                    <Typography 
                        variant={layout.newsPrTab === 1 ? 'body2' : 'body1'}
                        className={layout.newsPrTab === 1 ? classes.tabItemEnable : classes.tabItemDisable} 
                        onClick={(e) => layout.setNewsPrTab(1)}
                        >
                        NEWS
                    </Typography>
                    <Typography 
                        variant={layout.newsPrTab === 2 ? 'body2' : 'body1'}
                        className={layout.newsPrTab === 2 ? classes.tabItemEnable : classes.tabItemDisable} 
                        onClick={(e) => layout.setNewsPrTab(2)}
                        >
                        EVENTS
                    </Typography>
                </div>
            </div>
            <div className={classes.body}>
                <div style={{height:'200px'}} />
                {layout.newsPrTab === 0 && <Update />}
                {layout.newsPrTab === 1 && <News />}
                {layout.newsPrTab === 2 && <Event />}
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React from 'react';
import { m_size } from '../../../common/constants'

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '400px',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        minHeight: '400px',
        maxHeight: '400px',
        margin: 'auto',
        padding: m_size.padding,
        backgroundImage:'url(/images/home/small/back_main.png)',
        backgroundSize:'auto 400px',
        backgroundPosition:'center center',
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '400px',
        margin: 'auto',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
    },
    headText: {
        fontFamily: 's-core_dream6_bold',
        fontStyle: 'normal',
        fontWeight: 200,
        fontSize: '14px',
        lineHeight: '22px',
        color:'#ffffff'
    },
    bodyText: {
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 200,
        fontSize: '12px',
        lineHeight: '22px',
        color: '#ffffff'
    },
}));

const Main = () => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <Typography className={classes.headText} >Rozeus is a trust-based</Typography>
                <Typography className={classes.headText} >global media open platform.</Typography>
                <div style={{height:'36px'}} />
                <Typography className={classes.bodyText} >· Profile ·</Typography>
                <Typography className={classes.bodyText} >A transparent and rational</Typography>
                <Typography className={classes.bodyText} >trust-based platform specialized just for</Typography>
                <Typography className={classes.bodyText} >the media industry.</Typography>
                <div style={{ height: '30px' }} />
                <Typography className={classes.bodyText} >· GOAL ·</Typography>
                <Typography className={classes.bodyText} >to form a healthy media ecosystem</Typography>
                <Typography className={classes.bodyText} >of credible and reliable information for all.</Typography>
            </div>
        </div>
    
    );
};

export default Main;
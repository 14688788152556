import React, { useEffect, useState } from 'react';

import HomeTemplate from '../templates/HomeTemplate';
import Main from '../components/home/Main';
import Chapter1 from '../components/home/Chapter1';
import Chapter2 from '../components/home/Chapter2';
import Chapter3 from '../components/home/Chapter3';
import Chapter4 from '../components/home/Chapter4';

import MMain from '../components/small/home/Main'
import MChapter1 from '../components/small/home/Chapter1'
import MChapter2 from '../components/small/home/Chapter2'
import MChapter3 from '../components/small/home/Chapter3'
import MChapter4 from '../components/small/home/Chapter4'

import { Hidden } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import {PUBLIC_URL, PUBLIC_PATH} from '../meta/meta'

const HomeContainer = () => {
    const imagePath = PUBLIC_URL+PUBLIC_PATH+'/images/common/io_og.png';
    return (
        <React.Fragment>
            {/**
            <Helmet>
                <meta itemprop="image" content={imagePath}/>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content='로제우스 - ROZEUS' />
                <meta name="twitter:description" content= '나와 상관없는 콘텐츠에 시간 낭비 지겹다면, 로제우스와 가치있는 경험을 시작하세요' />
                <meta property="twitter:image" content={imagePath}/>
                <meta name="description" content="나와 상관없는 콘텐츠에 시간 낭비 지겹다면, 로제우스와 가치있는 경험을 시작하세요" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="로제우스 - ROZEUS" />
                <meta property="og:description" content="나와 상관없는 콘텐츠에 시간 낭비 지겹다면, 로제우스와 가치있는 경험을 시작하세요" />
                <meta property="og:url" content={`${PUBLIC_URL+PUBLIC_PATH}/`} />
                <meta property="og:image" content={imagePath}/>
            </Helmet>
             */}
            <Hidden xsDown>
                <HomeTemplate 
                    main={<Main />}
                    chapter1={<Chapter1 />}
                    chapter2={<Chapter2 />}
                    chapter3={<Chapter3 />}
                    chapter4={<Chapter4 />}
                    marginTab={<div style={{ height: '290px' }} />}
                />
            </Hidden>
            <Hidden smUp>
                <HomeTemplate
                    main={<MMain />}
                    chapter1={<MChapter1 />}
                    chapter2={<MChapter2 />}
                    chapter3={<MChapter3 />}
                    chapter4={<MChapter4 />}
                />
            </Hidden>
        </React.Fragment>
    );

};
export default HomeContainer;
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react';
import RootStore from './stores';
import { BrowserRouter } from 'react-router-dom';
const root = new RootStore();
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider {...root}>
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
  
} else {
  ReactDOM.render(
    <Provider {...root}>
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
}
serviceWorker.unregister();

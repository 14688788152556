import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();
    const history = useHistory();
    
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={150}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'100px'}} />
                {layout.language === 'kor'?
                    <Typography variant='h1'>ROZ는 특별합니다.</Typography>
                : layout.language === 'eng'?
                    <Typography variant='h1'>ROZ Is Special.</Typography>
                :
                    <Typography variant='h1'>独特的ROZ</Typography>
                }
                <div style={{ height: '20px' }} />
                {layout.language === 'kor'?
                    <Typography variant='body1'>로제우스 전체 생태계는 2개의 통화(Currency)와 2개의 지표(INDEX)를 바탕으로 운영됩니다.</Typography>
                : layout.language === 'eng'?
                    <Typography variant='body1'>The entire Rozeus's ecosystem is operated on two currencies and two indices.</Typography>
                :
                    <Typography variant='body1'>ROZEUS的整个生态系统以两个货币（Currency）与2个指标（INDEX）为基础来运行。</Typography>
                }
                <div style={{ height: '16px' }} />
                {layout.language === 'kor'?
                    <Typography variant='overline' onClick={(e) => history.push('/progress/resources')}>더 자세한 내용은 PROGRESS 탭의 RESOURCES에서 확인 할 수 있습니다.<img src={'/images/common/ic_link_arrow_' + layout.mode + '.png'} alt='LINK' style={{ width: '6px', marginLeft: '5px' }} /></Typography>
                : layout.language === 'eng'?
                    <Typography variant='overline' onClick={(e) => history.push('/progress/resources')}>More detail information is in Resources on PROGRESS tap.<img src={'/images/common/ic_link_arrow_' + layout.mode + '.png'} alt='LINK' style={{ width: '6px', marginLeft: '5px' }} /></Typography>
                :
                    <Typography variant='overline' onClick={(e) => history.push('/progress/resources')}>更多详细内容可以通过PROGESS dApp的Resourcess进行确认。<img src={'/images/common/ic_link_arrow_' + layout.mode + '.png'} alt='LINK' style={{ width: '6px', marginLeft: '5px' }} /></Typography>
                }
                
                <div style={{ height: '58px' }} />
                <div
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                    >
                    <Divider />
                    <div style={{ height: '50px' }} />

                   {layout.language === 'kor'?
                    <React.Fragment>
                        <Typography variant='body2'>통화 (Currency)</Typography>
                        <Typography variant='body1'>2개의 통화는 ROZEUS 내부 경제 운영을 위한 “BUD”와 ROZEUS 생태계 및 외부 시장에서 경제 중개를 위한 “ROZ”입니다.</Typography>
                    </React.Fragment>
                    : layout.language === 'eng'?
                    <React.Fragment>
                        <Typography variant='body2'>Currency</Typography>
                        <Typography variant='body1'>The two currencies are "BUD" for Rozeus's internal economic operations and "ROZ" for economic intermediary in the Rozeus ecosystem and external markets.</Typography>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Typography variant='body2'>货币（Currency）</Typography>
                        <Typography variant='body1'>两个货币分别是构成ROZEUS内循环经济运行的“BUD”和ROZEUS生态体系与外部市场的媒介“ROZ”。</Typography>
                    </React.Fragment>
                    
                    }
                    <div style={{ height: '60px' }} />
                    <div style={{ display:'flex',flexDirection:'column',alignItems:'center' }}>
                        <img src={'/images/profile/roz/small/chart_roz_chap2_1_1_' + layout.mode +'_'+layout.language+ '.png'} alt='CHART 1_1' style={{ width: '75%', height:'75%' }} />
                        <div style={{ height: '40px' }} />
                        <img src={'/images/profile/roz/small/chart_roz_chap2_1_2_' + layout.mode +'_'+layout.language+ '.png'} alt='CHART 1_2' style={{ width: '75%', height: '75%' }} />
                        <div style={{ height: '40px' }} />
                        <img src={'/images/profile/roz/small/chart_roz_chap2_1_3_' + layout.mode +'_'+layout.language+ '.png'} alt='CHART 1_3' style={{ width: '75%', height: '75%' }} />
                    </div>
                    <div style={{ height: '70px' }} />
                    <Divider />
                    <div style={{ height: '60px' }} />
                    {layout.language === 'kor'?
                    <React.Fragment>
                        <Typography variant='body2'>지표 (Index)</Typography>
                        <div style={{ height: '5px' }} />
                        <Typography variant='body1'>2개의 지표는 “G-SCORE”와 “LEVEL”입니다.</Typography>
                    </React.Fragment>
                    : layout.language === 'eng'?
                    <React.Fragment>
                        <Typography variant='body2'>Index</Typography>
                        <div style={{ height: '5px' }} />
                        <Typography variant='body1'>Two indices are "G-score" and "Level". </Typography>
                    </React.Fragment>
                    : 
                    <React.Fragment>
                        <Typography variant='body2'>指标（Index）</Typography>
                        <div style={{ height: '5px' }} />
                        <Typography variant='body1'>2个指标为“G-SCORE”和“LEVEL”  </Typography>
                    </React.Fragment>
                    }
                    
                    <div style={{ height: '60px' }} />
                    <img src={'/images/profile/roz/small/chart_roz_chap2_2_1_' + layout.mode +'_'+layout.language+ '.png'} alt='CHART 2_1' style={{ width: '100%', height:'100%' }} />
                    <div style={{ height: '50px' }} />
                    <img src={'/images/profile/roz/small/chart_roz_chap2_2_2_' + layout.mode +'_'+layout.language+ '.png'} alt='CHART 2_2' style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={{ height: '150px' }} />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
import React, { useEffect } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'1556px',
        margin: 'auto',
        backgroundColor: theme.palette.background.sub.chapBack
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '1556px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={150}
            className={classes.bodyWrap}>
            <div style={{ position: 'absolute', width: '100%', height: '1556px', backgroundImage: 'url(/images/profile/roz/back_roz_chap3_1_' + layout.mode + '.png)', backgroundRepeat: 'no-repeat', backgroundSize: '394.01px 569px', backgroundPosition: 'right 21px' }} />
            <div style={{ position: 'absolute', width: '100%', height: '1556px', backgroundImage: 'url(/images/profile/roz/back_roz_chap3_2_' + layout.mode + '.png)', backgroundRepeat: 'no-repeat', backgroundSize: '1419px 957px', backgroundPosition: 'left bottom' }} />
            <div className={classes.body}>
                <div style={{height:'174px'}} />
                {layout.language === 'kor'?               
                    <React.Fragment>
                    <Typography variant='h1' style={{ color: '#ffffff' }}>지속 가능한 성장을 위한 설계</Typography>
                    <div style={{ height: '30px' }} />
                    <Typography variant='body1' style={{color:'#ffffff'}}>대부분의 프로젝트는 사용자의 증가에 따른 네트워크 효과에 막연히 의지하여 가치가 무한대로 성장할 것이라는 논리를 펼치고 있습니다.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>이것은 사용자가 무한대로 증가한다는 가설을 전체로 하고 있습니다.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>이러한 모델에 대하여 전문가들은 잘못 설계된 이코노미이며 궁극적으로 성장에 한계를 가져 올 수 밖에 없는 구조라는 의견이 주를 이루고 있습니다.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>로제우스는 팩트체인 프로토콜(Fact-chain protocol), 베이스(BASE) 그리고 유틸리티 최대화(Maximizing utiliy)등의 장치와</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>여러 설계 원칙을 가지고 “ROZ”를 만들었습니다.</Typography>
                    <div style={{ height: '14px' }} />
                    <Typography variant='overline' style={{ color: '#ffffff' }} onClick={(e) => history.push('/progress/resources')}>더 자세한 내용은 PROGRESS 탭의 RESOURCES에서 확인 할 수 있습니다.<img src={'/images/common/ic_link_arrow_white.png'} alt='LINK' style={{ width: '8px', height: '13px', marginLeft: '5px' }} /></Typography>
                    </React.Fragment>
                :layout.language === 'eng'?  
                    <React.Fragment>
                    <Typography variant='h1' style={{ color: '#ffffff' }}>Designed for Sustainable Growth</Typography>
                    <div style={{ height: '30px' }} />
                    <Typography variant='body1' style={{color:'#ffffff'}}>Most of the other projects are relying vaguely on the network effects of increasing users and relying on the logic that value will increase to infinity.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>The logic above is based on the hypothetical premise that the number of users will increase indefinitely.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>Many experts say that these models are a poorly designed economy and that it is vulnerable to limited growth. </Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>Rozeus has created "ROZ" with several devices such as Fact-chain protocol, Base, Maximizing utility, and other design principles.</Typography>
                    <div style={{ height: '14px' }} />
                    <Typography variant='body2' style={{ color: '#ffffff' }} onClick={(e) => history.push('/progress/resources')}>For more information, you may find them on RESOURCES under PROGRESS tab. <img src={'/images/common/ic_link_arrow_white.png'} alt='LINK' style={{ width: '8px', height: '13px', marginLeft: '5px' }} /></Typography>
                    </React.Fragment>
                :
                    <React.Fragment>
                    <Typography variant='h1' style={{ color: '#ffffff' }}>可持续发展的设计</Typography>
                    <div style={{ height: '30px' }} />
                    <Typography variant='body1' style={{color:'#ffffff'}}>大部分项目采取随着用户增加，完全依赖社群效应的价值无限增长原理。</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>这一方式以无限增加的用户为前提。</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>专家评价称，这种模型是设计错误的经济，最终会带来发展的受限的结构。</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>ROZEUS通过事实核查协议（Fact-chain protocol）、基础（BASE）以及效用最大化（Maximizing utility）等条件与各种设计原则创造了“ROZ”。</Typography>
                    <div style={{ height: '14px' }} />
                    <Typography variant='overline' style={{ color: '#ffffff' }} onClick={(e) => history.push('/progress/resources')}><img src={'/images/common/ic_link_arrow_white.png'} alt='LINK' style={{ width: '8px', height: '13px', marginLeft: '5px' }} />更多详细内容可以通过PROGESS dApp的Resourcess进行确认。</Typography>
                    </React.Fragment>
                }
                <div style={{ height: '140px'}} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{flexGrow:1}}><Divider style={{backgroundColor:'#ffffff'}}/></div><img src='/images/profile/roz/ic_roz_common_double_circle.png' alt='circle' style={{ width: '16px', height: '16px' }} />
                    <div style={{ width: '30px' }} />
                    {layout.language === 'kor'?     
                    <Typography variant='body2' style={{ color: '#ffffff' }}>ROZ를 바탕의 로제우스 경제의 순환</Typography>
                    : layout.language === 'eng'?
                    <Typography variant='body2' style={{ color: '#ffffff' }}>The cycle of the Roseus economy based on the ROZ coin</Typography>
                    :
                    <Typography variant='body2' style={{ color: '#ffffff' }}>ROZ를 바탕의 로제우스 경제의 순환</Typography>
                    }
                    <div style={{ width: '30px' }} />
                    <img src='/images/profile/roz/ic_roz_common_double_circle.png' alt='circle' style={{ width: '16px', height: '16px' }} /><div style={{ flexGrow: 1 }}><Divider style={{ backgroundColor: '#ffffff' }}/></div>
                </div>
                <div style={{ height: '64px' }} />
                <img src={'/images/profile/roz/ic_roz_chap3_chart_' + layout.mode + '.png'} alt='chart3' style={{ width: '100%', minWidth: size.minWidth, maxWidth: size.maxWidth }} />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));
import React, { useEffect } from 'react';
import { size } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import Viewer from './Viewer';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'1620px',
        margin: 'auto',
    },
    body: {
        width: '100%',
        height: '1620px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },

}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 

            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'200px'}} />
                <Typography variant='h1'>Logo Don`t</Typography>
                <div style={{ height: '40px' }} />
                <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                    <div>
                        <Viewer type={layout.mode} img={'/images/profile/pressKit/resources/ic_dont1_'+layout.mode+'.png'} hover={false} imgSize={{ width: '300px', height: '280px' }} url='' />
                        <div style={{height:'20px'}} />
                        {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 심볼과 텍스트 간격을 변경하지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the symbol and text spacing of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 심볼과 텍스트 간격을 변경하지 마세요.</Typography>
                        }

                    </div>
                    <div>
                        <Viewer type={layout.mode} img={'/images/profile/pressKit/resources/ic_dont2_' + layout.mode + '.png'} hover={false} imgSize={{ width: '280px', height: '280px' }} url='' />
                        <div style={{height:'20px'}} />
                        {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 색상을 변경하지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the color of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 색상을 변경하지 마세요.</Typography>
                        }

                    </div>
                </div>
                <div style={{ height: '80px' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        <Viewer type={layout.mode} img={'/images/profile/pressKit/resources/ic_dont3_' + layout.mode + '.png'} hover={false} imgSize={{ width: '280px', height: '280px' }} url='' />
                        <div style={{ height: '20px' }} />
                        {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 각도를 변형시키지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the angle of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 각도를 변형시키지 마세요.</Typography>
                        }

                    </div>
                    <div>
                        <Viewer type={layout.mode} img={'/images/profile/pressKit/resources/ic_dont4_' + layout.mode + '.png'} hover={false} imgSize={{ width: '280px', height: '280px' }} url='' />
                        <div style={{ height: '20px' }} />
                        {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 비율을 변경하지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the ratio of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 비율을 변경하지 마세요.</Typography>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
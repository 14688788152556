import React, { useState } from 'react';
import { size } from '../../../common/constants'

import { Fade, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Typist from 'react-typist';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'1062px',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '1062px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    fontStyle:{
        fontFamily: 'Nanum Myeongjo',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '24px',
        lineHeight: '40px',
    },
    fontStyle2: {
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '20px',
        lineHeight: '70px',
    }
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();

    const [typistInit, setTypistInit] = useState(false);
    const [showTom, setShowTom] = useState(false);

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{ display: 'flex', flexDirection:'column',alignItems: 'center' }}>
                    <div style={{height:'306.28px'}} />
                    <img src={'/images/profile/how/ic_how_chap2_open_'+layout.mode+'.png'} alt='"' style={{width:'27.19px',height:'23.09px'}} />
                    <div style={{ height: '91.66px' }} />
                    <div style={{height:'206px'}}>
                        {!typistInit ?
                        <div onMouseOver={(e)=> setTypistInit(true)}>
                            {layout.language === 'kor'? 
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    정보의 통제는, 특히 독재 정치 체제의, 엘리트 들이 항상 하는 일 입니다.<br />
                                    정보와 지식은 힘이기 때문입니다.<br />
                                    정보를 제어하는 것은 사람을 제어하는 것과 같습니다.
                                </Typography>
                            : layout.language === 'eng'?
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    The control of information is something the elite always does, particularly in a despotic form of government. <br />
                                    Information, knowledge, is power. <br />
                                    If you can control information, you can control people.
                                </Typography>
                            :
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    信息管制，是垄断政治体制中精英们经常做的事情。<br/>
                                    因为信息与知识是力量的源泉。控制信息与控制人本身是一样的。
                                </Typography> 
                            }
                            

                            <div style={{ height: '16px' }} />
                            <div style={{ height: '70px' }}>
                                <Typography className={classes.fontStyle2} style={{ textAlign: 'center' }}>- Tom Clancy</Typography>
                            </div>
                        </div>
                        :
                        <div onMouseOut={(e) => {setTypistInit(false); setShowTom(false);}}>
                                <Typist cursor={{ show: false }} onTypingDone={(e)=>setShowTom(true)}>

                            {layout.language === 'kor'? 
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    정보의 통제는, 특히 독재 정치 체제의, 엘리트 들이 항상 하는 일 입니다.<br />
                                    정보와 지식은 힘이기 때문입니다.<br />
                                    정보를 제어하는 것은 사람을 제어하는 것과 같습니다.
                                </Typography>
                            : layout.language === 'eng'?
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    Information control is what the elite always do, especially in the dictatorship.<br />
                                    Because information and knowledge are power. <br />
                                    Controlling information is the same as controlling people.
                                </Typography>
                            :
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    信息管制，是垄断政治体制中精英们经常做的事情。<br/>
                                    因为信息与知识是力量的源泉。控制信息与控制人本身是一样的。
                                </Typography> 
                            }

                            </Typist>
                            {showTom &&
                                <React.Fragment>
                                    <div style={{ height: '16px' }} />
                                    <Fade in={showTom} timeout={2000}>
                                    <div style={{ height: '70px' }} >
                                        <Typography className={classes.fontStyle2} style={{ textAlign: 'center' }}>- Tom Clancy</Typography>
                                    </div>
                                    </Fade>
                                </React.Fragment>
                            }
                        </div>
                        }
                    </div>
                    <div style={{ height: '80.22px' }} />
                    <img src={'/images/profile/how/ic_how_chap2_close_' + layout.mode + '.png'} alt='"' style={{ width: '27.28px', height: '23.06px' }} />
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
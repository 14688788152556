import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import Slider from './Slider';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        backgroundImage: props=>'url(/images/profile/rozeus/back_rozeus_chap1_' + props.mode + '.png)',
        backgroundColor: theme.palette.background.sub.subBackground,
        backgroundSize: '242px 366px',
        backgroundPosition: 'right -38px top 25%',
        backgroundRepeat: 'no-repeat',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        padding: m_size.padding,
    },
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <React.Fragment>
            <div className={classes.bodyWrap}>
                <div className={classes.body}>
                    <div style={{height:'100px'}} />
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <div 
                            data-aos='fade-up'
                            data-aos-duration={aos_option.duration}
                            data-aos-offset={aos_option.offset}
                            >
                            {layout.language === 'kor'? 
                                <Typography variant='h1'>로제우스 소개</Typography>
                            : layout.language === 'eng'?
                                <Typography variant='h1'>About Rozeus</Typography>
                            :
                                <Typography variant='h1'>关于Rozeus</Typography>
                            }
                            <div style={{ height: '20px' }} />
                            {layout.language === 'kor'? 
                                <React.Fragment>
                                {/**
                                <Typography variant='body1'>
                                    로제우스는 모든 사람들이 미디어를 통해 어울리고 작용하는 데이터 기반 플랫폼과 디지털 화폐인 로즈(ROZ)가
                                    순환하는 바탕이 되는 모든 기반 기술과 시스템입니다.
                                    현재 미디어 산업의 문제를 해결하기 위한 솔루션이자 미디어 특화 글로벌 오픈
                                    플랫폼 입니다.
                                </Typography>
                                 */}
                                <Typography variant='body1'>
                                    로제우스는 모든 사람들이 미디어를 통해 어울리고 작용하는 데이터 기반 플랫폼이며,
                                    현재 미디어 산업의 문제를 해결하기 위한 솔루션이자 미디어 특화 글로벌 오픈 플랫폼 입니다.
                                </Typography>
                                </React.Fragment>
                            : layout.language === 'eng'? 
                                <React.Fragment>
                                {/**
                                    <Typography variant='body1'>Rozeus is technology-based system for a data-based platform where everyone can work together through the media, based on the circulation of our digital currency, ROZ. </Typography>
                                    <Typography variant='body1'>We provide the solution as a specialist in media global open platform for existing media issues in the industry. </Typography>
                                */}
                                    <Typography variant='body1'>Rozeus is technology-based system for a data-based platform where everyone can work together</Typography>
                                    <Typography variant='body1'>through the media. We provide the solution as a specialist in media global open platform for existing media issues in the industry.</Typography>
                                </React.Fragment>
                            :
                                <React.Fragment>
                                {/**
                                <Typography variant='body1'>ROZEUS是让所有人通过媒体进行沟通与交流的基于</Typography>
                                <Typography variant='body1'>数据的平台与通过数字货币</Typography>
                                <Typography variant='body1'>ROZ进行循环的所有技术基础与系统。</Typography>
                                <Typography variant='body1'>为解决媒体行业现存的问题，提供解决方案与专业国</Typography>
                                <Typography variant='body1'>际化媒体开放平台。</Typography>
                                 */}
                                
                                <Typography variant='body1'>Rozeus是一个让每一位用户都能通过媒体进行交流与互动的基于数据的平台，</Typography>
                                <Typography variant='body1'>旨在解决媒体行业现有问题，也是一个专业的全球媒体开放平台。</Typography>
                                </React.Fragment>
                            }
                            <div style={{ height: '120px' }} />
                            <Typography variant='h1'>Strong Points</Typography>
                            <div style={{ height: '20px' }} />
                            <div style={{display:'flex', justifyItems:'flex-start', alignItems:'flex-start'}}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth:'10px', height: '20px'}}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px'}} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>집단지성의 미디어 활용능력과 콘텐츠 올바른 활용과 콘텐츠 신뢰 측정 인덱스를 사용합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Apply collective intelligence's media literacy and content credibility measurement index.</Typography>
                                :
                                    <Typography variant='body1'>使用集体智慧的媒体使用能力与内容可信度测评指标。</Typography>
                                }
                            </div>
                            <div style={{ height: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', height: '20px' }}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>콘텐츠에 신뢰도를 수치화 합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Measure the credibility of news contents.</Typography>
                                :
                                    <Typography variant='body1'>将内容的可信度数值化。</Typography>
                                }

                            </div>
                            <div style={{ height: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', height: '20px' }}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>개인의 플랫폼 기여도를 보상합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Reward individual's contributions to the platform</Typography>
                                :
                                    <Typography variant='body1'>为个人对平台的贡献程度提供报酬。</Typography>
                                }

                            </div>
                            <div style={{ height: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', height: '20px' }}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>가치 공유를 통해 공평하고 투명한 생태계를 운영합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Operate a fair and transparent ecosystem through sharing values.</Typography>
                                :
                                    <Typography variant='body1'>通过共享价值，运行公平而透明的生态系统。</Typography>
                                }

                            </div>
                            <div style={{ height: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', height: '20px' }}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>가치 공유 현황을 위한 별도의 서비스를 제공합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Provide separate services for the status of sharing values.</Typography>
                                :
                                    <Typography variant='body1'>为价值共享现状，提供特别服务。</Typography>
                                }

                            </div>
                            <div style={{ height: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', height: '20px' }}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>사용자의 소비 형태에 최적화된 UX를 제공합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Provide optimized UX according to users' consumption patterns.</Typography>
                                :
                                    <Typography variant='body1'>提供最适合用户使用习惯的UX。</Typography>
                                }
                            </div>
                            <div style={{ height: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '10px', minWidth: '10px', height: '20px' }}>
                                    <img src={'/images/common/ic_chap_circle_' + layout.mode + '.png'} alt='chapter li' style={{ width: '4px', height: '4px' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>전문적인 큐레이션과 맞춤형 큐레이션등을 연구합니다.</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Research for professional and customizing curation.</Typography>
                                :
                                    <Typography variant='body1'>研究专业的新闻呈现模式与个性化定制内容等。</Typography>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '356px' }} />
                </div>
            </div>
            <div
                data-aos='fade-up'
                data-aos-duration={aos_option.duration}
                data-aos-offset={aos_option.offset}
                style={{ position: 'relative', top: -236, marginBottom: '-86px', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                <Slider />
            </div>
        </React.Fragment>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React, { useEffect, useState } from 'react';
import SubMain from '../components/common/SubMain';
import MSubMain from '../components/small/common/SubMain';
import ResourcesChapter1 from '../components/progress/resources/Chapter1';
import RoadmapChapter1 from '../components/progress/roadmap/Chapter1';
import NumbersChapter1 from '../components/progress/numbers/Chapter1';
import NumbersChapter2 from '../components/progress/numbers/Chapter2';
import PartnerChapter1 from '../components/progress/partner/Chapter1';

import MResourcesChapter1 from '../components/small/progress/resources/Chapter1';
import MRoadmapChapter1 from '../components/small/progress/roadmap/Chapter1';
import MNumbersChapter1 from '../components/small/progress/numbers/Chapter1';
import MNumbersChapter2 from '../components/small/progress/numbers/Chapter2';
import MPartnerChapter1 from '../components/small/progress/partner/Chapter1';

import ProgressTemplates from '../templates/ProgressTemplates';
import FooterContainer from './FooterContainer';
import { Hidden } from '@material-ui/core';

const ProgressContainer = ({match}) => {

    const [target, setTarget] = useState('resources');

    const subComponentList = {
        resources : [
            <ResourcesChapter1 />
        ],
        roadmap: [
            <RoadmapChapter1 />
        ],
        numbers:[
            <NumbersChapter1 />, <NumbersChapter2 />
        ],
        partner: [
            <PartnerChapter1 />
        ],
    }

    const subSmallComponentList = {
        resources: [
            <MResourcesChapter1 />, <div style={{ height: '150px' }} />
        ],
        roadmap: [
            <MRoadmapChapter1 />, <div style={{ height: '150px' }} />
        ],
        numbers: [
            <MNumbersChapter1 />, <MNumbersChapter2 />, <div style={{ height: '150px' }} />
        ],
        partner: [
            <MPartnerChapter1 />, <div style={{ height: '150px' }} />
        ],
    }

    useEffect(() => {
        if(match){
            if(match.params && match.params.target){
                setTarget(match.params.target);
                const anchor = (document).querySelector(
                    "#back-to-top-anchor"
                );

                if (anchor) {
                    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        }
    },[match]);

    return (
        <React.Fragment>
            <Hidden xsDown>
                <ProgressTemplates 
                    main={
                        <SubMain 
                            menu='progress' 
                            keyword={target}
                        />}
                    subComponent={subComponentList[target]}
                    footer={<FooterContainer type='sub' />}
                    target={target}
                />
            </Hidden>
            <Hidden smUp>
                <ProgressTemplates
                    main={
                        <MSubMain
                            menu='progress'
                            keyword={target}
                        />}
                    subComponent={subSmallComponentList[target]}
                    footer={<FooterContainer type='sub' />}
                    target={target}
                />
            </Hidden>
        </React.Fragment>
    )
};

export default ProgressContainer;

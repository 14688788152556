import React, { useEffect } from 'react';
import { size, aos_option } from '../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import NumberTitle from './NumberTitle';
import HomeChapterDivider from './HomeChapterDivider';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1098px',
        margin: 'auto',
    },
    bodyBackground :{
        position: 'absolute', 
        width: '50%',
        height: '1098px',
        minWidth: '50%',
        maxWidth: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 65px top 340px',
        backgroundSize: '794px 758px',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '1098px',
        margin:'auto',
    },
    contentWrap: {
        position: 'absolute', 
        right: '0px', 
        width: '50%', 
        height: '100%',
    },
}));

const Chapter4 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap} >
            <div className={classes.bodyBackground} style={{ backgroundImage: 'url(/images/home/back_chapter4_' + layout.mode + '.png)' }}>
                <img
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                    src={'/images/home/move_chapter4_face_' + layout.mode + '.png'}
                    alt='symbol'
                    style={{
                        position: 'absolute',
                        top:'432px',
                        right:'188px',
                        width: '420px', 
                        height: '508px' 
                    }}
                />
            </div>
            <div 
                data-aos='fade-up'
                data-aos-duration={aos_option.duration}
                data-aos-offset={aos_option.offset}
                className={classes.body}>
                <div className={classes.contentWrap}>
                    <div style={{height:'264px'}} />
                    <NumberTitle
                        number='04' 
                        text={
                            layout.language === 'kor'? 
                            ['가능성을 열고', '생각합니다.']
                            : layout.language === 'eng'? 
                            ['Think the possibility ', 'with open-heart']
                            :
                            ['打开所有可能性','进行思考。']
                        }
                        top='-84px'
                    />
                    <div style={{ height: '46px' }} />
                    {layout.language === 'kor'? 
                    <div style={{ margin: '-16px 0px' }}>
                        <Typography variant='body1'>컨텍스트 디지타이징 구현 기술, 자연어를 이해하고 가공 및 분류 하는 기술,</Typography>
                        <Typography variant='body1'>온/오프라인 네트워크의 디지타이징 기술, 빅데이터 분석 및 처리 기술, </Typography>
                        <Typography variant='body1'>머신러닝(AI) 기술 ‘비정형 데이터’를 분석하는 기술 등</Typography>
                        <Typography variant='body1'>기반 기술을 바탕으로 차세대 미디어 서비스를 생각합니다.</Typography>
                        <Typography variant='body1'>뉴스와 미디어에 잃어버린 사람들의 신뢰를 회복할 방법을 생각합니다.</Typography>
                    </div>
                    : layout.language === 'eng'? 
                    <div style={{ margin: '-16px 0px' }}>
                         
                        <Typography variant='body1'>Consider the next generation of media services based on the underlying</Typography>
                        <Typography variant='body1'>technologies of context digitization implementation, of natural language  </Typography>
                        <Typography variant='body1'>understanding, processing and classification, of on and off-line network </Typography>
                        <Typography variant='body1'>digitization, of big data analysis and processing, of machine learning (AI), and of </Typography>
                        <Typography variant='body1'>'non-stationary data' technology and others.</Typography>
                        <br></br>
                        <Typography variant='body1'>Also consider on how to restore the lost trust of the news and the media.</Typography>
                    </div>
                    :
                    <div style={{ margin: '-16px 0px' }}>
                        <Typography variant='body1'>以文字数字化呈现技术、自然语言理解加工与分类技术，</Typography>
                        <Typography variant='body1'>线上、线下网络的数字化技术，大数据分析与处理技术，</Typography>
                        <Typography variant='body1'>深度学习（AI）技术，不规则数据的分析技术等技术为基础，</Typography>
                        <Typography variant='body1'>思考新一代媒体服务。</Typography>
                        <Typography variant='body1'>思考恢复人们对新闻与媒体信任的方法。</Typography>
                    </div>
                    }
                    <div style={{ height: '50px' }} />
                    <HomeChapterDivider type='dot'/>
                </div>
            </div>
        </div>
    );
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter4));
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { size, subMain } from '../../common/constants'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import CustomButton2 from './CustomButton2';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '717px',
        margin: 'auto',
    },
    bodyWrapBgColor: {
        position: 'relative',
        width: '100%',
        height: '532px',
        margin: 'auto',
        backgroundColor : theme.palette.background.sub.subBackground,
        zIndex:-9999
    },
    body: {
        position:'relative',
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        height: '717px',
        margin: 'auto',
    },
    bodyBgColor: {
        position: 'relative',
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        height: '532px',
        margin: 'auto',
    },
    contentText: {
        position: 'relative',
        width: size.maxWidth / 2,
        zIndex:0
    },
    contentImg: {
        position:'absolute',
        WebkitTransform: 'translateZ(0)',
        top: 0,
        width:'100%',
        height:'717px',
        zIndex: -1
    },
    contentImgBgColor: {
        position: 'absolute',
        WebkitTransform: 'translateZ(0)',
        top: 0,
        width: '100%',
        height: '532px',
        zIndex: -1
    },
    pageName:{
        color: theme.palette.text.sub.primary
    },
}));

const SubMain = ({ layout, menu, keyword, button }) => {

    const classes = useStyles();
    const [sizeShow,setSizeShow] = useState(true);

    const [backimg, setBackImg] = useState(subMain.img[menu][keyword].path + 'back_' + layout.mode + '.png')
    const [logoimg, setLogoImg] = useState(subMain.img[menu][keyword].path + 'logo_' + layout.mode + '.png')
    const [twinkleimg, setTwinkleImg] = useState(subMain.img[menu][keyword].path + 'twinkle_' + layout.mode + '.png')
    const [symbolImg, setSymbolImg] = useState(subMain.img[menu][keyword].path + 'symbol_' + layout.mode + '.png')


    useLayoutEffect(() => {
        function updateSize() {
            window.innerWidth > size.chageLineWidth ? setSizeShow(true) : setSizeShow(false);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    useEffect(() => {
        setBackImg(subMain.img[menu][keyword].path + 'back_' + layout.mode + '.png');
        setLogoImg(subMain.img[menu][keyword].path + 'logo_' + layout.mode + '.png');
        setTwinkleImg(subMain.img[menu][keyword].path + 'twinkle_' + layout.mode + '.png');
        setSymbolImg(subMain.img[menu][keyword].path + 'symbol_' + layout.mode + '.png');
    }, [layout.mode, menu, keyword, button]);

    const imgLoadErr = (val) => {
        // console.log("SUB MAIN IMG LOAD FAIL => ", val);
        switch(val){
            case 'back' :
                setBackImg('');
                return ;
            case 'logo':
                setLogoImg('');
                return;
            case 'twinkle':
                setTwinkleImg('');
                return;
            case 'symbol':
                setSymbolImg('');
                return;
            default :
                return;
        }
    }

    return (
        <React.Fragment>
            <div className={!subMain.img[menu][keyword].backgroundColor ? classes.bodyWrap : classes.bodyWrapBgColor} style={subMain.img[menu][keyword].backHeight ? { height: subMain.img[menu][keyword].backHeight}:{}}>
                <div className={classes.body}>
                    <div className={classes.contentText} style={subMain.img[menu][keyword].backHeight ? { height: subMain.img[menu][keyword].backHeight } : {}, {width: '1000px'}}>
                        <div style={{ height: '200px'}} />
                            <ContentText
                                pageName={subMain.pageName[menu][keyword+'_'+layout.language]}
                                title={subMain.title[menu][keyword + (sizeShow ? 'Long' : 'Short')+'_'+layout.language]}
                                subTitle={subMain.subTitle[menu][keyword+'_'+layout.language]}
                                button={button} 
                            />
                    </div>
                    <div className={!subMain.img[menu][keyword].backgroundColor ? classes.contentImg : classes.contentImgBgColor}>
                        {backimg !== '' &&
                        <img 
                            src={backimg} 
                            alt={keyword} 
                            style={{ 
                                position:'absolute',
                                top: subMain.img[menu][keyword].top, 
                                right: 0, 
                                width: subMain.img[menu][keyword].imgWidth,
                                //height: subMain.img[menu][keyword].imgHeight,
                                zIndex:-1008
                            }}
                            onError={(e) => imgLoadErr('back')}
                        />
                        }
                        {logoimg !== '' &&
                        <img
                            className={subMain.img[menu][keyword].effect ? 'subLogo' : ''}
                            src={logoimg}
                            alt={keyword}
                            style={{
                                position: 'absolute',
                                top: subMain.img[menu][keyword].top,
                                right: 0,
                                width: subMain.img[menu][keyword].imgWidth,
                                //height: subMain.img[menu][keyword].imgHeight,
                                zIndex: subMain.img[menu][keyword].zIndex.logo,
                            }}
                            onError={(e) => imgLoadErr('logo')}
                        />
                        }
                        {symbolImg !== '' && subMain.img[menu][keyword].effect &&
                        <img
                            src={symbolImg}
                            alt={keyword}
                            style={{
                                position: 'absolute',
                                top: subMain.img[menu][keyword].top,
                                right: 0,
                                width: subMain.img[menu][keyword].imgWidth,
                                //height: subMain.img[menu][keyword].imgHeight,
                                zIndex: subMain.img[menu][keyword].zIndex.symbol
                            }}
                            onError={(e) => imgLoadErr('symbol')}
                        />
                        }
                        {twinkleimg !== '' &&
                        <img
                            className='twinkle'
                            src={twinkleimg}
                            alt={keyword}
                            style={{
                                position: 'absolute',
                                top: subMain.img[menu][keyword].top,
                                right: 0,
                                width: subMain.img[menu][keyword].imgWidth,
                                //height: subMain.img[menu][keyword].imgHeight,
                                zIndex: -1001,
                            }}
                            onError={(e) => imgLoadErr('twinkle')}
                        />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const ContentText = ({ pageName, title, subTitle, button}) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {pageName &&
                <React.Fragment>
                    <div>
                        <Typography variant='subtitle2' className={classes.pageName}>{pageName}</Typography>
                    </div>
                    <div style={{height:'20px'}} />
                </React.Fragment>
            }
            <div>
                {title && 
                    title.map((item, index)=>{
                        return <Typography key={index} variant='h4'>{item}</Typography>
                    })
                }
            </div>
            {subTitle &&
                <React.Fragment>
                <div style={{ height: '30px' }} />
                <div>
                    {subTitle.map((item, index) => {
                        return <Typography key={index} variant='body1'>{item}</Typography>
                    })}
                </div>
            </React.Fragment>
            }
            {button && 
                <React.Fragment>
                    <div style={{ height: '50px' }} />
                    <div>
                    <CustomButton2 
                        text={button.text} 
                        size={{ width: '178px', height: '44px' }} 
                        img={{ src: '/images/common/ic_button_download_dark.png', width: '14px', height: '12px' }}
                        onclick={button.event} /> 
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(SubMain));
import { makeStyles, Popper, List, ListItem, ListItemText, Typography  } from '@material-ui/core';
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';


const useStyles = makeStyles(theme  => ({
    menuHover: {
        display: 'flex',
        alignItems: 'flex-start',
        height: '58px',
        margin: '0px 4px',
        cursor: 'pointer',
        marginTop: '2px',
        borderBottom: '2px solid transparent',
        '&:hover': {
            marginTop: '2px',
            borderBottom: '2px solid',
            borderColor: theme.palette.menuUnderLine
        }
        
    },
    popperMain : {
        width: '159px',
        height: '166px',
        background: theme.palette.background.paper,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '0px 0px 4px 4px'
    }
}));

const Language = ({ imgPath, alt, width, height, menu, layout}) => {

    const classes = useStyles();
    const [mode, setMode] = useState('');
    const [show, setShow] = useState();

    const handleMode = (mode) => {
        setMode(mode)
    }

    const handleClick = (event) => {
        menu.select('lang', event.currentTarget); 
         
    }
 
 
    return (
        <React.Fragment>
            
            <div className={classes.menuHover} style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
                <img 
                    src={imgPath + mode + '.png'} 
                    alt={alt} 
                    style={{ display:'block', width: width, height: height, cursor: 'pointer'}} 
                    onClick={handleClick}
                    onMouseOver={(e) => handleMode('_over')}
                    onMouseOut={(e) => handleMode('')}
                    onMouseDown={(e) => handleMode('_down')}
                    onMouseUp={(e) => handleMode('')}
                    onTouchStart={(e) => handleMode('_down')}
                    onTouchEnd={(e) => handleMode('')}
                />

            </div>
            <Popper open={menu.target === 'lang'} anchorEl={menu.subMenuObj} placement='bottom-end' transition>
                <div className={classes.popperMain} >
                    <List component="nav" aria-label="SubMenu">
                        <ListItem  button style={{ height: '46px', padding: '0px' }} onClick={(e) => layout.setLanguage('eng')}>
                            <ListItemText primary={
                                <Typography variant='h5' className={classes.menuText} style={{ fontFamily: 's-core_dream4_regular', textAlign:'center'}}> English </Typography>
                            } />
                        </ListItem>
                        <ListItem button style={{ height: '46px', padding: '0px' }} onClick={(e) => layout.setLanguage('kor')}>
                            <ListItemText primary={
                                <Typography variant='h5' className={classes.menuText} style={{ fontFamily: 's-core_dream4_regular', textAlign:'center'}}> 한국어 </Typography>
                                } />
                        </ListItem>
                        <ListItem button style={{ height: '46px', padding: '0px' }} onClick={(e) => layout.setLanguage('cn')}>
                            <ListItemText primary={
                                <Typography variant='h5' className={classes.menuText} style={{ fontFamily: 'Noto Sans SC', textAlign:'center'}}> 体中文 </Typography>
                            } />
                        </ListItem>
                    </List>
                </div>
            </Popper>
        
        </React.Fragment>
    );
}


export default inject(({ menu, layout }) => ({
    menu: menu,
    layout: layout
}))(observer(Language));
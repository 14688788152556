import React, { useEffect, useState } from 'react';
import { m_size, subMain } from '../../../common/constants'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import CustomButton2 from '../../common/CustomButton2';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    bodyWrapBgColor: {
        position: 'relative',
        width: '100%',
        margin: 'auto',
        backgroundColor : theme.palette.background.sub.subBackground,
        zIndex:-9999
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
    },
    bodyBgColor: {
        // position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        //height: '532px',
        margin: 'auto',
    },
    contentText: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
    },
    contentImg: {
        position:'relative',
        WebkitTransform: 'translateZ(0)',
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems: 'center'
    },
    contentImgBgColor: {
        WebkitTransform: 'translateZ(0)',
        position: 'relative',
        width: '100%',
    },
    pageName:{
        color: theme.palette.text.sub.primary
    },
}));

const SubMain = ({ layout, menu, keyword, button }) => {

    const classes = useStyles();

    const [backimg, setBackImg] = useState(subMain.img[menu][keyword].smallPath + 'back_' + layout.mode + '.png')
    const [logoimg, setLogoImg] = useState(subMain.img[menu][keyword].smallPath + 'logo_' + layout.mode + '.png')
    const [twinkleimg, setTwinkleImg] = useState(subMain.img[menu][keyword].smallPath + 'twinkle_' + layout.mode + '.png')
    const [symbolImg, setSymbolImg] = useState(subMain.img[menu][keyword].smallPath + 'symbol_' + layout.mode + '.png')

    useEffect(() => {
        setBackImg(subMain.img[menu][keyword].smallPath + 'back_' + layout.mode + '.png');
        setLogoImg(subMain.img[menu][keyword].smallPath + 'logo_' + layout.mode + '.png');
        setTwinkleImg(subMain.img[menu][keyword].smallPath + 'twinkle_' + layout.mode + '.png');
        setSymbolImg(subMain.img[menu][keyword].smallPath + 'symbol_' + layout.mode + '.png');
    }, [layout.mode, menu, keyword, button]);

    const imgLoadErr = (val) => {
        // console.log("SUB MAIN IMG LOAD FAIL => ", val);
        switch(val){
            case 'back' :
                setBackImg('');
                return ;
            case 'logo':
                setLogoImg('');
                return;
            case 'twinkle':
                setTwinkleImg('');
                return;
            case 'symbol':
                setSymbolImg('');
                return;
            default :
                return;
        }
    }

    return (
        <React.Fragment>
            <div className={!subMain.img[menu][keyword].backgroundColor ? classes.bodyWrap : classes.bodyWrapBgColor} >
                <div className={classes.body}>
                    <div style={{height:'10px'}} />
                    <div 
                        className={!subMain.img[menu][keyword].backgroundColor ? classes.contentImg : classes.contentImgBgColor}
                    >
                        {backimg !== '' &&
                            <img
                                src={backimg}
                                alt={keyword}
                                style={{
                                    position: 'relative',
                                    // top: subMain.img[menu][keyword].top,
                                    top: 0,
                                    right: 0,
                                    width: '100%',
                                    //height: subMain.img[menu][keyword].imgHeight,
                                    height:'100%',
                                    zIndex: -1008,
                                }}
                                onError={(e) => imgLoadErr('back')}
                            />
                        }
                        {logoimg !== '' &&
                            <img
                                className={subMain.img[menu][keyword].effect ? 'subLogo' : ''}
                                src={logoimg}
                                alt={keyword}
                                style={{
                                    position: 'absolute',
                                    // top: subMain.img[menu][keyword].top,
                                    top:0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    //height: subMain.img[menu][keyword].imgHeight,
                                    zIndex: subMain.img[menu][keyword].zIndex.logo,
                                }}
                                onError={(e) => imgLoadErr('logo')}
                            />
                        }
                        {symbolImg !== '' && subMain.img[menu][keyword].effect &&
                            <img
                                src={symbolImg}
                                alt={keyword}
                                style={{
                                    position: 'absolute',
                                    // top: subMain.img[menu][keyword].top,
                                    top: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    //height: subMain.img[menu][keyword].imgHeight,
                                    zIndex: subMain.img[menu][keyword].zIndex.symbol
                                }}
                                onError={(e) => imgLoadErr('symbol')}
                            />
                        }
                        {twinkleimg !== '' &&
                            <img
                                className='twinkle'
                                src={twinkleimg}
                                alt={keyword}
                                style={{
                                    position: 'absolute',
                                    // top: subMain.img[menu][keyword].top,
                                    top: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    //height: subMain.img[menu][keyword].imgHeight,
                                    zIndex: -1001,
                                }}
                                onError={(e) => imgLoadErr('twinkle')}
                            />
                        }
                    </div>
                    <div className={classes.contentText}>
                        <ContentText
                            pageName={subMain.pageName[menu][keyword+'_'+layout.language]}
                            title={subMain.title[menu][keyword+'Short_'+layout.language]}
                            subTitle={subMain.subTitle[menu]['small_'+keyword+'_'+layout.language]}
                            button={button}
                        />
                    </div>
                </div>
                <div style={{height:'100px'}} />
            </div>
        </React.Fragment>
    );
};

const ContentText = ({ pageName, title, subTitle, button}) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {pageName &&
                <React.Fragment>
                    <div>
                        <Typography variant='subtitle2' className={classes.pageName}>{pageName}</Typography>
                    </div>
                    <div style={{height:'13px'}} />
                </React.Fragment>
            }
            <div>
                {title && 
                    title.map((item, index)=>{
                        return <Typography key={index} variant='h4'>{item}</Typography>
                    })
                }
            </div>
            {subTitle &&
                <React.Fragment>
                <div style={{ height: '26px' }} />
                <div>
                        {subTitle.map((item, index) => {
                            return <Typography key={index} variant='body1'>{item}</Typography>
                        })}
                </div>
            </React.Fragment>
            }
            {button && 
                <React.Fragment>
                    <div style={{ height: '24px' }} />
                    <div>
                        <CustomButton2 
                            text={button.text} 
                            size={{ width: '142px', height: '40px' }} 
                            img={{ src: '/images/common/ic_button_download_dark.png', width: '11px', height: '9.43px' }}
                            onclick={button.event} /> 
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(SubMain));
import React, { useEffect, useState } from 'react';

import SubMain from '../components/common/SubMain';
import RozeusChapter1 from '../components/profile/rozeus/Chapter1';
import RozeusChapter2 from '../components/profile/rozeus/Chapter2';
import RozeusChapter3 from '../components/profile/rozeus/Chapter3';
import HowChapter1 from '../components/profile/how/Chapter1';
import HowChapter2 from '../components/profile/how/Chapter2';
import HowChapter3 from '../components/profile/how/Chapter3';
import HowChapter4 from '../components/profile/how/Chapter4';
import ROZChapter1 from '../components/profile/roz/Chapter1';
import ROZChapter2 from '../components/profile/roz/Chapter2';
import ROZChapter3 from '../components/profile/roz/Chapter3';
import NEWSPRChapter1 from '../components/profile/newsPr/Chapter1';
import PressKitChapter1 from '../components/profile/pressKit/Chapter1';
import PressKitChapter2 from '../components/profile/pressKit/Chapter2';

import MSubMain from '../components/small/common/SubMain';
import MRozeusChapter1 from '../components/small/profile/rozeus/Chapter1';
import MRozeusChapter2 from '../components/small/profile/rozeus/Chapter2';
import MRozeusChapter3 from '../components/small/profile/rozeus/Chapter3';
import MHowChapter1 from '../components/small/profile/how/Chapter1';
import MHowChapter2 from '../components/small/profile/how/Chapter2';
import MHowChapter3 from '../components/small/profile/how/Chapter3';
import MHowChapter4 from '../components/small/profile/how/Chapter4';
import MROZChapter1 from '../components/small/profile/roz/Chapter1';
import MROZChapter2 from '../components/small/profile/roz/Chapter2';
import MROZChapter3 from '../components/small/profile/roz/Chapter3';
import MNEWSPRChapter1 from '../components/small/profile/newsPr/Chapter1';
import MPressKitChapter1 from '../components/small/profile/pressKit/Chapter1';
import MPressKitChapter2 from '../components/small/profile/pressKit/Chapter2';

import ProfileTemplates from '../templates/ProfileTemplates';
import FooterContainer from './FooterContainer';
import { Hidden } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const ProfileContainer = ({match, layout}) => {
    const [target, setTarget] = useState('whatIsRozeus');

    const subComponentList = {
        whatIsRozeus : [
            <RozeusChapter1 />, <RozeusChapter2 />, <RozeusChapter3 />,<div style={{height:'300px'}} />
        ],
        whyAndHow: [
            <HowChapter1 />, <HowChapter2 />, <HowChapter3 />, <HowChapter4 />
        ],
        whatIsROZ: [
            <ROZChapter1 />, <ROZChapter2 />, <ROZChapter3 />, <div style={{ height: '32px' }} />
        ],
        newsPr:[
            <NEWSPRChapter1 />, <div style={{ height: '260px' }} />
        ],
        pressKit:[
            <PressKitChapter1 />, <PressKitChapter2 />
        ]
    }

    const subSmallComponentList = {
        whatIsRozeus: [
            <MRozeusChapter1 />, <MRozeusChapter2 />, <MRozeusChapter3 />, <div style={{ height: '150px' }} />
        ],
        whyAndHow: [
            <MHowChapter1 />, <MHowChapter2 />, <MHowChapter3 />, <MHowChapter4 />, <div style={{ height: '150px' }} />,
        ],
        whatIsROZ: [
            <MROZChapter1 />, <MROZChapter2 />, <MROZChapter3 />,<div style={{ height: '150px' }} />
        ],
        newsPr: [
            <MNEWSPRChapter1 />, <div style={{ height: '150px' }} />
        ],
        pressKit: [
            <MPressKitChapter1 />, <MPressKitChapter2 />, <div style={{ height: '150px' }} />
        ]
    }

    useEffect(() => {
        if(match){
            if(match.params && match.params.target){
                setTarget(match.params.target);
                const anchor = (document).querySelector(
                    "#back-to-top-anchor"
                );

                if (anchor) {
                    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        }
    },[match]);

    const pressKitButton = () => {
        window.location.href ='/resources/pressKit/Logo_ALL.ai'
        return;
    }

    return (
        <React.Fragment>
            <Hidden xsDown>
                <ProfileTemplates
                    main={
                        <SubMain
                            menu='profile'
                            keyword={target}
                            button={target === 'pressKit' && { text: 'All DownLoad', event: pressKitButton }}
                        />}
                    subComponent={subComponentList[target]}
                    footer={<FooterContainer type='sub' />}
                    target={target}
                />
            </Hidden>
            <Hidden smUp>
                <ProfileTemplates
                    main={
                        <MSubMain
                            menu='profile'
                            keyword={target}
                            button={target === 'pressKit' && { text: 'All DownLoad', event: pressKitButton }}
                        />}
                    subComponent={subSmallComponentList[target]}
                    footer={<FooterContainer type='sub' />}
                    target={target}
                />
            </Hidden>
        </React.Fragment>
    )
};

export default inject(({ layout }) => ({
    layout: layout
}))(observer(ProfileContainer));
import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    background_embed: {
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#000000',
        zIndex: -99,
        margin: 'auto',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'center',
    },
    embed:{
        margin: 'auto', 
        width:'100%',
        //maxWidth: '100%',
        height:'100%',
        maxHeight: 'calc(100vh - 60px)',
    },
    mouseAni: {
        position:'absolute',
        left:'calc(50% - 13px)',
        bottom:'8%',
    },
}));

const Chapter1 = () => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.background_embed}>
                <video id="background-video" preload={`auto`} loop autoPlay muted className={classes.embed}>
                    <source src='/media/home/20201223_io_landing_BG_with_words.mp4' type="video/mp4" />
                </video>
                <div className={classes.mouseAni}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '26px', height: '46px', borderRadius: '18px', border: '1px solid #ffffff' }}>
                        <div className='topDownMove' />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Chapter1;
import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { VerticalAlignTop } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '32%',
    },
    body: {
        position:'relative',
        top: 0,
        width: '100%',
        transition: "all 0.2s ease-in",
        MozTransition: "all 0.2s ease-in",
        WebkitTransition: "all 0.2s ease-in",
        msTransition: "all 0.2s ease-in",
        OTransition: "all 0.2s ease-in",
        borderRadius: '0px 0px 6px 6px / 0px 0px 6px 6px',
        marginBottom: '80px',
        '&:hover': {
            border: '0px solid ',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
            top: -5,
        }
    },
    cardBody:{
        backgroundColor: theme.palette.subCard,
        //height:'438.3px',
        padding:'20px',
        paddingBottom:'34px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        border: '1px solid ' + theme.palette.divider,
        borderRadius: '0px 0px 6px 6px / 0px 0px 6px 6px',
    },
    linkFont : {
        cursor: 'pointer',
        fontFamily: 's-core_dream4_regular',
        color: theme.palette.text.secondary,
        fontWeight: 200,
        lineHeight: '20px',
        marginBottom:'8px',
        borderBottom: '1.5px solid ' + theme.palette.subCard,
        transition: "all 0.2s ease-in",
        MozTransition: "all 0.2s ease-in",
        WebkitTransition: "all 0.2s ease-in",
        msTransition: "all 0.2s ease-in",
        OTransition: "all 0.2s ease-in",
        "&:hover": {
            borderBottom: '1.5px solid ' + theme.palette.text.secondary
        }
    },
    blogLinkFont: {
        cursor: 'pointer',
        fontFamily: 's-core_dream4_regular',
        lineHeight: '20px',
        marginBottom: '8px',
        borderBottom: '1.5px solid ' + theme.palette.subCard,
        transition: "all 0.2s ease-in",
        MozTransition: "all 0.2s ease-in",
        WebkitTransition: "all 0.2s ease-in",
        msTransition: "all 0.2s ease-in",
        OTransition: "all 0.2s ease-in",
        "&:hover": {
            borderBottom: '1.5px solid '+ theme.palette.menuUnderLine
        }
    }
}));

const NewsCard = ({ target, layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <img src={target.img} alt='블로그' style={{ width: '100%', display: 'block' }} />
                <div className={classes.cardBody} >
                    <Typography variant='body1' style={{ fontSize: '14px' }} >{target.date}</Typography>
                    <div style={{ height: '22px' }} />
                    {layout.language === 'kor'? 
                        <Typography variant='subtitle2' style={{ lineHeight: '32px', height: '32px' }} >{target.title}</Typography>
                    : layout.language === 'eng'?
                        <Typography variant='subtitle2' style={{ lineHeight: '32px', height: '64px', verticalAlign: 'super'}}>{target.title_eng} </Typography>
                                            :
                        <Typography variant='subtitle2' style={{ lineHeight: '32px', height: '32px' }} >{target.title_cn}</Typography>
                    }
                    <div style={{ height: '20px' }} />
                    <Typography variant='body1' style={{ lineHeight: '26px', height: '104px' }} >{layout.language === 'kor'? target.text: layout.language === 'eng'? target.text_eng : target.text_cn}</Typography>
                    <div style={{ height: '34px' }} />
                    <div style={{ height: '88px' }} >
                    {target.link.map((item,index)=>{
                        return <div style={{display:'flex'}}><Typography key={index} variant='body1' className={classes.linkFont} onClick={(e) => window.open(item.link)} >
                            {layout.language === 'kor'? item.text : layout.language === 'eng'? item.text_eng : item.text}
                        </Typography></div>
                    })}
                    </div>
                    <div style={{ height: '10px' }} ></div>
                    <div style={{ height: '56px' }}>
                        {target.blogLink.map((item, index) => {
                            return <div style={{ display: 'flex' }}><Typography key={index} variant='body1' className={classes.blogLinkFont} onClick={(e) => window.open(item.link)} >
                                {layout.language === 'kor'? item.text : layout.language === 'eng'? item.text_eng : item.text}
                        </Typography></div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(NewsCard));
import React, { useCallback, useEffect, useState } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Counter from '../../home/Counter';
import { throttle } from 'lodash';


const Clients = ({ layout }) => {

    const arr = Array(92).fill().map((item,index)=>++index);

    const [flag1, setFlag1] = useState(true);
    

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
        listener();
    });

    const listener = useCallback(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section && section.getBoundingClientRect().height;
        if ((height + scrollTop) > (sectionHeight + 650) && flag1) {
            setFlag1(false)
        }
    }, [flag1]);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener, true);
        return () => {
            window.removeEventListener("scroll", throttleListener, true);
        };
    }, [listener]);

    useEffect(() => {
        setFlag1(true);
    }, [layout.partnerTab]);

    useEffect(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section.getBoundingClientRect().height;
        if ((height + scrollTop) > (sectionHeight + 630) && flag1) {
            setFlag1(false)
        }
    }, [flag1]);

    return (
        <React.Fragment>
            {layout.language === 'kor'? 
                <React.Fragment>
                    <Typography variant='h1'>한국 최다 규모</Typography>
                    <Typography variant='h1'>미디어 B2B 클라이언트</Typography>
                </React.Fragment>
            :layout.language === 'eng'? 
                <React.Fragment>
                    <Typography variant='h1'>Korea's largest </Typography>
                    <Typography variant='h1'>media B2B Clients</Typography>
                </React.Fragment>
            :
                <React.Fragment>
                    <Typography variant='h1'>韩国规模最大的媒体B2B平台</Typography>
                </React.Fragment>

            }

            <div style={{ height: '30px' }} />
            {layout.language === 'kor'? 
                <Typography variant='body1'>유수의 Clients에서 Media Big Data Platform을 제공 합니다.</Typography>
            : layout.language === 'eng'?
                <Typography variant='body1'>We provide Media Big Data Platform for outstanding clients.</Typography>
            :
                <Typography variant='body1'>为大企业用户提供Media Big Data Platform。</Typography>
            }

            <div style={{ height: '100px' }} />
            {layout.language === 'kor'? 
                <Typography variant='body1'>로제우스 누적 고객사 수</Typography>
            : layout.language === 'eng'?
                <Typography variant='body1'>Cumulative Rozeus' clients </Typography>
            :
                <Typography variant='body1'>ROZEUS累计用户企业数量</Typography>
            }

            {flag1 ?
                <Typography variant='subtitle1'>&nbsp;</Typography>
                :
                <Counter countNum={1500} />
            }
            <div style={{ height: '115px' }} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start',flexWrap:'wrap' }}>
                {
                    arr.map((item, index) => {
                        const fileName = (index + 1) > 9 ? '0' + (index + 1) : '00' + (index + 1);
                        return (
                            <React.Fragment>
                                <img key={index} src={'/images/progress/partner/clients/c_' + fileName + '.png'} alt={'client logo' + fileName} style={{ width: '134px', height: '134px', marginBottom:'24px' }} />
                                {(index+1)%8  !== 0 && <div style={{width:'24px'}}/>}
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </React.Fragment>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Clients));
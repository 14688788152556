import React from 'react';
import { inject, observer } from 'mobx-react';

import { size } from '../../../common/constants'

import { makeStyles } from '@material-ui/core';
import ICB from '../ICB';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
    },
    icon : {
        width:'32px',
        height:'32px',
        marginLeft:'12px',
        cursor:'pointer'
    }
}));

const SNSIcon = ({ layout }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <ICB imgPath={'/images/common/footer/naver_' + layout.mode} alt='naver' width='32px' height='32px' handleClick={(e) => window.open('https://blog.naver.com/rozeus8')} />
                <div style={{width:'12px'}} />
                <ICB imgPath={'/images/common/footer/sinaWeibo_' + layout.mode} alt='weibo' width='32px' height='32px' handleClick={(e) => window.open('https://weibo.com/p/1005057199901989/home?from=page_100505&mod=TAB&is_all=1#place')}/>
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/youtube_' + layout.mode} alt='youtube' width='32px' height='32px' handleClick={(e) => window.open('https://www.youtube.com/channel/UC-iUWWR6LPC3PmOOxH5E3kg')}/>
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/medium_' + layout.mode} alt='medium_' width='32px' height='32px' handleClick={(e) => window.open('https://www.youtube.com/channel/UC-iUWWR6LPC3PmOOxH5E3kg')} />
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/telegram_' + layout.mode} alt='telegram' width='32px' height='32px' handleClick={(e) => window.open('https://t.me/rozeus_2019')} />
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/facebook_' + layout.mode} alt='facebook' width='32px' height='32px' handleClick={(e) => window.open('https://www.facebook.com/rozeuskr/')}/>
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/ic_gitHub_' + layout.mode} alt='github' width='32px' height='32px' handleClick={(e) => window.open('https://github.com/RozeusBlockChain')}/>
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/zhihu_' + layout.mode} alt='zhihu' width='32px' height='32px' handleClick={(e) => window.open('https://www.zhihu.com/people/rozeus/posts')}/>
                <div style={{ width: '12px' }} />
                <ICB imgPath={'/images/common/footer/tudou_' + layout.mode} alt='tudou' width='32px' height='32px' handleClick={(e) => window.open('http://id.tudou.com/i/UNzc0NDU4NjE2OA==?spm=a2h28.8313469.ln.du_host')}/>
            </div>
        </div>
    )

}; 

export default inject(({ layout }) => ({
    layout: layout
}))(observer(SNSIcon));
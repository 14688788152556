import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
// import { menuList, subMain } from '../common/constants'
import { inject, observer } from 'mobx-react';
export const PUBLIC_URL = 'http://106.247.124.178';
// export const PUBLIC_PATH = '/rozeus_io';
export const PUBLIC_PATH = '';

const contents = {
    whatIsRozeus : {
        title: '디지털 미래의 글로벌 미디어 오픈 플랫폼,  ROZEUS',
        desc: '로제우스는 미래 디지털 시대의 미디어를 위한 투명하고 합리적인 신뢰 기반 플랫폼입니다. 믿을 수 있고 신뢰할 수 있는 건강한 미디어 생태계를 만들어 갑니다.'
    },
    whyAndHow : {
        title: '미디어를 위한 새로운 땅, ROZEUS',
        desc: '인공 지능 알고리즘이 적용된 빅데이터분석 기술을 적용한 미디어 플랫폼을 구축해 뉴스와 미디어 그리고 사람이 만나는 프로세스를 혁신합니다.'
    },
    whatIsROZ : {
        title: '디지털 미디어 시대의 새로운 통화, ROZ',
        desc: 'ROZ는 로제우스 생태계를 동작하게 만들어 주는 플랫폼의 대표 가상 자산입니다.'
    },
    newsPr : {
        title: 'ROZEUS NEWS & PR',
        desc: 'ROZEUS가 발행한 보도자료입니다.'
    },
    pressKit : {
        title: 'ROZEUS Brand Asset',
        desc: 'ROZEUS의 logo를 확인해보세요.'
    },
    resources : {
        title: 'ROZEUS Paper',
        desc: '로제우스 백서, IR을 확인할 수 있습니다.'
    },
    roadmap : {
        title: 'ROZEUS Roadmap',
        desc: '건강한 미디어 생태계를 만들기 위해 로제우스가 걸어갈 길입니다.'
    },
    numbers : {
        title: 'Media big data Numbers in ROZEUS',
        desc: '2,300개 이상의 온라인 뉴스, 신문, 방송 등의 채널과 함께 280억 건 이상의 미디어 빅데이터를 쌓고 있습니다.'
    },
    partner : {
        title: 'Partners with ROZEUS',
        desc: '로제우스는 한국 최대 규모의 미디어 파트너쉽을 맺고 있습니다. 로제우스와 함께하는 파트너를 확인해보세요.'
    },
    contactUs : {
        title: 'Contact ROZEUS',
        desc: '로제우스는 투명하고 합리적인 신뢰 기반 플랫폼입니다. 로제우스에게 궁금한 점이 있다면 자유롭게 연락해주세요.'
    },
}


function Meta({ language, target, parent, layout }) {
    // const description = subMain.subTitle[parent][target+'_'+language] != null? subMain.subTitle[parent][target+'_'+language].toString() : '';
    // const title = subMain.title[parent][target+'Long_'+language].toString();
    const title = contents[target].title;
    const description = contents[target].desc;
    const image = `${PUBLIC_URL+PUBLIC_PATH}/images/common/io_og_${target}.png`;
    return (
        <Helmet>
            <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
{/**
            <meta itemprop="name" content={title}/>
            <meta itemprop="description" content= {description}/>
            <meta itemprop="image" content={image} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content= {description} />
            <meta property="twitter:image" content={image} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content= {description}/>
            <meta property="og:url" content={`${PUBLIC_URL+PUBLIC_PATH}/${parent}/${target}`} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image}/>
 */}
            <title>{title}</title>
        </Helmet>
    )
}
// export default Meta
export default inject(({ layout }) => ({
    layout: layout
}))(observer(Meta));
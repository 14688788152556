import React from 'react';

import { Dialog, makeStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'70vh',
        margin: 'auto',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
}));

const VideoModal = ({ layout }) => {

    const classes = useStyles();

    return (
        <Dialog fullWidth maxWidth='lg' onClose={layout.closeModal} aria-labelledby="simple-dialog-title" open={layout.showModal}>
            <div className={classes.bodyWrap}>
                <iframe title='YOUTUBE' style={{width: '100%', height: '70vh',}} src={layout.modalViedeoPath} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>
        </Dialog>
    )

};

export default inject(({ layout }) => ({
    layout: layout
}))(observer(VideoModal));
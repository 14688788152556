import React, { useEffect } from 'react';
import { m_size } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },

}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 

            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'120px'}} />
                <Typography variant='h1'>Logo Don`t</Typography>
                <div style={{ height: '15px' }} />

                <img src={'/images/profile/pressKit/resources/small/ic_dont1_' + layout.mode + '.png'} alt='download' style={{ width: '100%' }} />
                <div style={{height:'15px'}} />

                {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 심볼과 텍스트 간격을 변경하지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the symbol and text spacing of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 심볼과 텍스트 간격을 변경하지 마세요.</Typography>
                }
                <div style={{ height: '40px' }} />
                <img src={'/images/profile/pressKit/resources/small/ic_dont2_' + layout.mode + '.png'} alt='download' style={{ width: '100%' }} />
                <div style={{height:'15px'}} />
                {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 색상을 변경하지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the color of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 색상을 변경하지 마세요.</Typography>
                }
                <div style={{ height: '40px' }} />
                <img src={'/images/profile/pressKit/resources/small/ic_dont3_' + layout.mode + '.png'} alt='download' style={{ width: '100%' }} />
                <div style={{ height: '15px' }} />
                {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 각도를 변형시키지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the angle of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 각도를 변형시키지 마세요.</Typography>
                }
                <div style={{ height: '40px' }} />
                <img src={'/images/profile/pressKit/resources/small/ic_dont4_' + layout.mode + '.png'} alt='download' style={{ width: '100%' }} />
                <div style={{ height: '15px' }} />
                {layout.language === 'kor'?  
                            <Typography variant='body1'>로고의 비율을 변경하지 마세요.</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Don't change the ratio of the logo.</Typography>
                        :
                            <Typography variant='body1'>로고의 비율을 변경하지 마세요.</Typography>
                }
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
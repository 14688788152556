import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
    },
    body: {
        position: 'relative',
        top: 0,
        width: '100%',
        // transition: "all 0.2s ease-in",
        // MozTransition: "all 0.2s ease-in",
        // WebkitTransition: "all 0.2s ease-in",
        // msTransition: "all 0.2s ease-in",
        // OTransition: "all 0.2s ease-in",
        //borderRadius: '0px 0px 6px 6px / 0px 0px 6px 6px',
        marginBottom: '40px',
        // '&:hover': {
        //     //border: '0px solid ',
        //     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        //     top: -5,
        // }
    },
    cardBody: {
        //backgroundColor: theme.palette.subCard,
        width:'100%',
        padding: '16px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderBottom: '1px solid ' + theme.palette.divider,
        //borderRadius: '0px 0px 6px 6px / 0px 0px 6px 6px',
    },
    linkFont: {
        cursor: 'pointer',
        fontFamily: 's-core_dream4_regular',
        color: theme.palette.text.secondary,
        fontWeight: 200,
        fontSize: '13px',
        lineHeight: '22px',
        backgroundColor: theme.palette.background.sub.subBackground,
        padding: '3px 10px',
        border: '1px solid ' + theme.palette.subBox,
        borderRadius:'20px',
        marginTop:'9px',
        transition: "all 0.2s ease-in",
        MozTransition: "all 0.2s ease-in",
        WebkitTransition: "all 0.2s ease-in",
        msTransition: "all 0.2s ease-in",
        OTransition: "all 0.2s ease-in",
        '&:hover':{
            border: '1px solid ' + theme.palette.text.secondary,
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.background.sub.subBackground,
        }
    }
}));

const NewsCard = ({ target, layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <img src={target.img} alt='블로그' style={{ width:'100%', display: 'block' }} />
                <div className={classes.cardBody} >
                    <div>
                        <Typography variant='body1' style={{ fontSize: '14px' }} >{target.date}</Typography>
                        <div style={{ height: '8px' }} />
                        {layout.language === 'kor'?  
                            <Typography variant='h1' style={{ lineHeight: '32px' }} >{target.title}</Typography>
                        : layout.language === 'eng'? 
                            <Typography variant='h1' style={{ lineHeight: '32px' }} >{target.title_eng}</Typography>
                        :
                            <Typography variant='h1' style={{ lineHeight: '32px' }} >{target.title_cn}</Typography>
                        }
                    </div>
                    <div style={{ height: '60px' }} />
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                    {target.linkList.map((item, index) => {
                        return (
                            <React.Fragment>
                                {index > 0 && <div style={{width:'4px'}} />}
                                <Typography key={index} className={classes.linkFont} onClick={(e) => window.open(item.link)} >{item.name}</Typography>
                            </React.Fragment>
                        )
                    })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(NewsCard));
import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        backgroundSize: '100% auto',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/images/profile/rozeus/small/back_rozeus_chap3.png)',
        backgroundColor: theme.palette.background.sub.chapBack,
    },
    body: {
        position: 'relative',
        width: '100%',
        padding: m_size.padding,
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',

    },
    chap3Card:{
        width:'100%',
        height:'155px',
        border:'1px solid #ffffff',
        borderRadius:'4px',
        padding:'20px',
        color: '#ffffff',
        backgroundRepeat:'no-repeat',
        backgroundImage:'url(/images/profile/rozeus/small/ic_chap3_card_arrow.png)',
        backgroundSize:'20px 15px',
        backgroundPosition:'right 20px bottom 20px',
        transition: "all 0.3s ease-in",
        MozTransition: "all 0.3s ease-in",
        WebkitTransition: "all 0.3s ease-in",
        msTransition: "all 0.3s ease-in",
        OTransition: "all 0.3s ease-in",
        "&:hover": {
            backgroundColor:'#ffffff',
            color: theme.palette.text.sub.sub3,
            backgroundImage: props => 'url(/images/profile/rozeus/small/ic_chap3_card_arrow_hover_'+props.mode+'.png)',
        },
    },
    descFont: {
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '15px',
        lineHeight: '20px',
        color: 'inherit'
    }
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles(layout);
    const history = useHistory();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap} >
            <div className={classes.body}>
                <div style={{height:'100px'}} />
                {layout.language === 'kor'? 
                    <div>
                        <Typography variant='h1' style={{color:'#ffffff'}}>로제우스를 만나보세요</Typography>
                        <div style={{height:'20px'}} />
                        {/**
                        <Typography variant='body1' style={{ color: '#ffffff' }}>로제우스 ROZ, ROZEUS WALLET, 그리고 ROZEUS SERVICE를 경험 할 수 있습니다.</Typography>
                        */}
                        <Typography variant='body1' style={{ color: '#ffffff' }}>로제우스와 관련된 BFLYSOFT, ROZEUS AI, 그리고 ROZEUS SERVICE를 경험 할 수 있습니다.</Typography>
                    </div>
                : layout.language === 'eng'? 
                    <div>
                        <Typography variant='h1' style={{color:'#ffffff'}}>Experience Rozeus</Typography>
                        <div style={{height:'20px'}} />
                        {/**
                        <Typography variant='body1' style={{ color: '#ffffff' }}>You can experience ROZ, ROZEUS Wallet, and ROZEUS service.</Typography>
                        */}
                            <Typography variant='body1' style={{ color: '#ffffff' }}>Experience BFLYSOFT, ROZUES AI, and ROZEUS SERVICE.</Typography>
                    </div>                
                :
                    <div>
                        <Typography variant='h1' style={{color:'#ffffff'}}>体验Rozeus</Typography>
                        <div style={{height:'20px'}} />
                        {/**
                        <Typography variant='body1' style={{ color: '#ffffff' }}>体验ROZEUS的ROZ、ROZEUS WALLET，以及ROZEUS服务吧！</Typography>
                         */}
                            <Typography variant='body1' style={{ color: '#ffffff' }}>立即体验与Rozeus相关的 BFLYSOFT、ROZEUS AI以及ROZEUS服务吧！</Typography>
                    </div>
                }
                <div style={{ height: '80px' }} />
                <div>
                    <div className={classes.chap3Card} onClick={(e) => window.open('https://blog.naver.com/PostList.nhn?blogId=bflysoft_biz&parentCategoryNo=20&skinType=&skinId=&from=menu&userSelectMenu=true')}>
                        {/**
                        <div className={classes.chap3Card} onClick={(e) => history.push('/profile/whatIsROZ')}>
                        <Typography variant='h2'>ROZ</Typography>
                         */}
                    {layout.language === 'cn'? <Typography variant='h2'>BFLYSOFT 大数据 </Typography>
                    : <Typography variant='h2'>BFLYSOFT BIG DATA</Typography>
                    }                        
                        <div style={{ height: '12px' }} />
                    {layout.language === 'kor'? 
                        <React.Fragment>
                        {/**
                        <Typography className={classes.descFont}>로제우스의 네이티브 가상자산입니다. 로제우스 서비스 및 로제우스 미디어 댑에서 사용 하거나 친구에게 자산을 전송할 수 있습니다.</Typography>
                         */}
                        <Typography className={classes.descFont}>'트렌드인사이트, 사회이슈, 현상을 바라보는 전문가의 시각, 분야별 주요뉴스까지, PR/MKT인들이 알아두어야 할 다채로운 내용으로 채워져있습니다.'</Typography>
                        </React.Fragment>
                    :layout.language === 'eng'? 
                        <React.Fragment>
                        {/**
                        <Typography className={classes.descFont}>ROZ is Rozeus' native virtual currency. You can use it on Rozeus service and media dApp or transfer it to your friends.</Typography>
                         */}
                        <Typography className={classes.descFont}>Trend insights, social issues, professional opinions on phenomenons, and top news for PR professionals and marketers.</Typography>
                        </React.Fragment>
                    :
                       <React.Fragment>
                        {/**
                        <Typography className={classes.descFont}>ROZEUS用户的虚拟资产。可以在ROZEUS服务与ROZEUS媒体dApp上使用，或转账给朋友。</Typography>
                        */}
                        <Typography className={classes.descFont}>趋势解读、社会热点、专家解读、各领域主要新闻等推广宣传与营销专业人士必需的多领域、高品质内容。</Typography>
                        </React.Fragment>
                    }
                    </div>
                    <div style={{ height: '12px' }} />
                    <div className={classes.chap3Card} onClick={(e) => window.open('https://blog.naver.com/rozeus8')}>
                        {/**
                        <div className={classes.chap3Card} onClick={(e) => window.open('https://play.google.com/store/apps/details?id=com.rwallet')}>
                        <Typography variant='h2'>ROZEUS WALLET</Typography>
                         */}
                        {layout.language === 'cn'? <Typography variant='h2'>ROZEUS 人工智能</Typography>
                        : <Typography variant='h2'>ROZEUS AI</Typography>}
                        <div style={{ height: '12px' }} />
                        {layout.language === 'kor'? 
                            <React.Fragment>
                            {/**
                            <Typography className={classes.descFont}>로제우스의 가상 자산 지갑입니다. 이더리움과 ROZ를 보관하고 전송할 수 있습니다.</Typography>
                             */}
                            <Typography className={classes.descFont}>AI 기술 브랜드 아래 자연어 처리 분야에서 차별화된 기술력을 보여드립니다.</Typography>
                            </React.Fragment>
                        :layout.language === 'eng'? 
                            <React.Fragment>
                            {/**
                            <Typography className={classes.descFont}>Rozeus wallet is Rozeus' virtual asset property wallet. You can store and send Ethereum and ROZ.</Typography>
                             */}
                            <Typography className={classes.descFont}>Differentiated technological skills in natural language processing under Artificial Intelligence technology. </Typography>
                            </React.Fragment>
                        :
                            <React.Fragment>
                            {/**
                            <Typography className={classes.descFont}>ROZEUS的虚拟资产钱包。可以保管并转账以太币与ROZ。</Typography>
                            */}
                            <Typography className={classes.descFont}>基于人工智能技术的专业自然语言处理技术。</Typography>
                            </React.Fragment>
                        }
                    </div>
                    <div style={{ height: '12px' }} />
                    <div className={classes.chap3Card} onClick={(e) => window.open('https://www.rozeus.com')}>
                        <Typography variant='h2'>ROZEUS SERVICE</Typography>
                        <div style={{ height: '12px' }} />
                        {layout.language === 'kor'? 
                            <Typography className={classes.descFont}>로제우스 첫 번째 미디어 dApp 서비스로, 사용자에게 맞춤 콘텐츠를 보여줍니다.</Typography>
                        :layout.language === 'eng'? 
                            <Typography className={classes.descFont}>Rozeus' first media dApp service to show customized contents to users.</Typography>
                        :
                            <Typography className={classes.descFont}>作为ROZEUS的首个媒体dApp服务，为用户提供个人订制内容。</Typography>
                        }
                    </div>
                    <div style={{ height: '12px' }} />
                    <div className={classes.chap3Card} onClick={(e) => history.push('/profile/newsPr')}>
                        <Typography variant='h2'>MORE</Typography>
                        <div style={{ height: '12px' }} />
                        {layout.language === 'kor'? 
                            <Typography className={classes.descFont}>로제우스 보도 자료</Typography>
                        :layout.language === 'eng'? 
                            <Typography className={classes.descFont}>Rozeus' press release</Typography>
                        :
                            <Typography className={classes.descFont}>ROZEUS媒体报道资料</Typography>
                        }
                    </div>
                </div>
                <div style={{ height: '150px' }} />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));
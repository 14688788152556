import React from 'react';

import { menuList } from '../../common/constants'
import { Fade, List, ListItem, ListItemText, makeStyles, Popper, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    menu: {
        minWidth:'160px',
        background: theme.palette.background.paper,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '0px 0px 4px 4px',
        padding:'6px 0px',
    },
    menuText:{
        fontFamily: 's-core_dream4_regular',
        textDecoration: 'none', 
        margin: '0px 20px', 
        fontSize: '15px', 
        lineHeight: '18px',
        "&:hover": {
            color: theme.palette.text.highlight,
        },
    }
}));

const SubMenuList = ({ name, margin, menu }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = (type, val) => {
        if(type){
            window.open(val)
        }else{
            //window.location.href = val
            history.push(val)
            menu.deselect()
        }
    }

    return (
        <Popper open={menu.target===name} anchorEl={menu.subMenuObj} placement='bottom' transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <div className={classes.menu} style={{ marginLeft: margin}}>
                        <List component="nav" aria-label="SubMenu">
                            {menuList[name] && 
                                menuList[name].map((item, index)=>{
                                return (
                                    <ListItem key={index} button style={{ height: '46px', padding: '0px' }} onClick={(e) => handleClick(item.type, item.url)}>
                                        <ListItemText primary={
                                            <Typography variant='h5' className={classes.menuText}>{item.name}</Typography>
                                        } />
                                    </ListItem>
                                    )
                                })
                            }
                        </List>
                    </div>
                </Fade>
            )}
        </Popper>
    );
}

export default inject(({ menu }) => ({
    menu: menu
}))(observer(SubMenuList));

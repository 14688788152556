import React from 'react';
import Download from '../components/common/footer/Download';
import SNSIcon from '../components/common/footer/SNSIcon';
import Link from '../components/common/footer/Link';

import MDownload from '../components/small/common/footer/Download';
import MSNSIcon from '../components/small/common/footer/SNSIcon';
import MLink from '../components/small/common/footer/Link';

import FooterTemplate from '../templates/FooterTemplate';
import { Hidden } from '@material-ui/core';
import SmallFooterTemplate from '../templates/SmallFooterTemplate';

const FooterContainer = ({type}) => {

    return (
        <React.Fragment>
            <Hidden xsDown>
                <FooterTemplate
                    snsIcon={<SNSIcon />}
                    download={<Download />}
                    link={<Link />}
                    type={type}
                />
            </Hidden>
            <Hidden smUp>
                <SmallFooterTemplate
                    snsIcon={<MSNSIcon />}
                    download={<MDownload />}
                    link={<MLink />}
                    type={type}
                />
            </Hidden>
        </React.Fragment>
    );

};

export default FooterContainer;

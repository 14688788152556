import React, { useEffect, useState } from 'react';
import { m_size } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Dialog, Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';



import { post } from 'axios'
import CustomButton2 from '../../../common/CustomButton2';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
    },
    left:{
        width: '100%',
        backgroundColor:theme.palette.background.sub.subBackground,
        padding:'0px 30px',
    },
    leftTitle:{
        fontFamily: 's-core_dream4_regular',
    },
    right:{
        width:'100%',
        padding: m_size.padding,
    },
    titleStar: {
        color: theme.palette.text.secondary
    },
    inputForm:{
        width: '100%', 
        padding:'12px 12.5px',
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 200,
        fontSize: '14px',
        lineHeight: '22px',
        backgroundColor: theme.palette.background.default,
        border: '1px solid #5D5D5D',
        borderRadius:'2px',
        '&:hover': {
            border: '1px solid '+theme.palette.inputBorderHover,
        },
        '&:focus' :{
            outline:'none',
            border: '1px solid ' + theme.palette.inputBorderFocus,
        },
        '&::placeholder': {
            color:'#868686'
        }
    },
    endDivider:{
        backgroundColor: theme.palette.footerDivider
    },
    dialogBody:{
        width: '70vw',
        height:'200px',
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [msgPop, setMsgPop] = useState(false);
    const [msg, setMsg] = useState({});

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const handleChange = (e) => {
        switch(e.target.name){
            case 'name':
                setName(e.target.value);
                return;
            case 'email':
                setEmail(e.target.value);
                return;
            case 'message':
                setMessage(e.target.value);
                return;
            default:
                return;
        }
    }

    const handleClick = () => {

        var check = true

        if (message.length < 2) {
            check = false;
            if (message.length === 0) {
                setMsg({ title:'전송실패',text:'Message을 입력해주세요'});
            } else {
                setMsg({ title: '전송실패', text:'Message는 두글자 이상 입력해주세요'});
            }
        }

        if (email.length === 0) {
            check = false;
            setMsg({ title: '전송실패', text:'이메일을 입력해주세요'});
        } else {
            const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
            if (!reg_email.test(email)) {
                check = false;
                setMsg({ title: '전송실패', text:'이메일이 형식에 맞지 않습니다.'})
            }
        }

        if (name.length < 2) {
            check = false;
            if (name.length === 0) {
                setMsg({ title: '전송실패', text:'이름을 입력해주세요'});
            } else {
                setMsg({ title: '전송실패', text:'이름은 두글자 이상 입력해주세요'});
            }
        }

        if(check){
            // console.log('SEND SERVER')
            const config = {
                headers: {
                    'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                },
            }
            post('https://www.rozeus.com/RozeusAPI/service/send_mail' , {name:name,email:email,msg:message }, config)
                .then((response) => {
                    setMsg({ title: '전송완료', text: '작성하신 내용이 전송완료되었습니다.' });
                    setMsgPop(true);
                }).catch(err => {
                    return null;
                });

        }else{
            setMsgPop(true);
        }
    }

    const handleClose = () =>{
        setMsgPop(false);
    }
   
    return (
        <div 
            className={classes.bodyWrap}>
            <div className={classes.body}>
            {layout.language === 'kor'?  
                <Typography variant='subtitle2' style={{padding:m_size.padding}}>
                   Rozeus is supported by the Rozeus Team, Bflysoft, and the MEDIA ALLIANCE. <br/>
                   Please fill out the contact form and our team will be in touch with the best service to assit you.
                </Typography>
            : layout.language === 'eng'?  
                <Typography variant='subtitle2' style={{padding:m_size.padding}}>
                    Rozeus is supported by the Rozeus Team, Bflysoft, and the MEDIA ALLIANCE. <br/>
                    Please fill out the contact form and our team will be in touch with the best service to assit you.
                </Typography>
            :
                <Typography variant='subtitle2' style={{padding:m_size.padding}}>
                    Rozeus平台由Rozeus项目组、Bflysoft和MEDIA ALLIANCE团队成员开发运营。 如有问题请先填写下列表格。 我们将安排最专业的相关部门负责人联系您。
                </Typography>
            }

                <div style={{ height: '100px' }} />
                <div className={classes.right}>
                    <div style={{display:'flex'}}>
                    {layout.language === 'kor'?  
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >Name</Typography>
                    : layout.language === 'eng'? 
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >Name</Typography>
                    :
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >您的姓名</Typography>
                    } 
                        &nbsp;
                        <Typography variant='body2' className={classes.titleStar} style={{ fontFamily: 's-core_dream4_regular' }} >*</Typography>
                    </div>
                    <div style={{height:'8px'}} />
                    {layout.language === 'kor'?  
                        <input type='text' name='name' className={classes.inputForm} placeholder='이름을 입력해주세요.' style={{ height:'50px' }} onChange={handleChange}/>
                    : layout.language === 'eng'?  
                        <input type='text' name='name' className={classes.inputForm} placeholder='Name' style={{ height:'50px' }} onChange={handleChange}/>
                    :
                        <input type='text' name='name' className={classes.inputForm} placeholder='姓名' style={{ height:'50px' }} onChange={handleChange}/>
                    }
                    <div style={{ height: '50px' }} />
                    <div style={{ display: 'flex' }}>
                    {layout.language === 'kor'?  
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >E-mail</Typography>
                    :layout.language === 'eng'?  
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >E-mail</Typography>
                    :
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >邮箱地址</Typography>
                    }
                        &nbsp;
                        <Typography variant='body2' className={classes.titleStar} style={{ fontFamily: 's-core_dream4_regular' }} >*</Typography>
                    </div>
                    <div style={{ height: '8px' }} />
                    {layout.language === 'kor'?  
                        <input type='text' name='email' className={classes.inputForm} placeholder='이메일을 입력해주세요.' style={{ height: '50px' }} onChange={handleChange}/>
                    :layout.language === 'eng'?  
                        <input type='text' name='email' className={classes.inputForm} placeholder='Email' style={{ height: '50px' }} onChange={handleChange}/>
                    :
                        <input type='text' name='email' className={classes.inputForm} placeholder='电子邮件' style={{ height: '50px' }} onChange={handleChange}/> 
                    }
                    <div style={{height:'50px'}}/>
                    <div style={{ display: 'flex' }}>
                    {layout.language === 'kor'?  
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >Message</Typography>
                    :layout.language === 'eng'?   
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >Message</Typography>
                    :
                        <Typography variant='body2' style={{ fontFamily: 's-core_dream4_regular' }} >留言</Typography>
                    }
                        &nbsp;
                        <Typography variant='body2' className={classes.titleStar} style={{ fontFamily: 's-core_dream4_regular' }} >*</Typography>
                    </div>
                    <div style={{ height: '8px' }} />
                    {layout.language === 'kor'?  
                        <textarea name='message' placeholder='내용을 입력해주세요' className={classes.inputForm} style={{ resize: 'none', height: '180px' }} onChange={handleChange} />
                    : layout.language === 'eng'?
                        <textarea name='message' placeholder='Message' className={classes.inputForm} style={{ resize: 'none', height: '180px' }} onChange={handleChange} />
                    :
                        <textarea name='message' placeholder='请写下您的留言内容' className={classes.inputForm} style={{ resize: 'none', height: '180px' }} onChange={handleChange} />
                    }
                    
                    <div style={{ height: '40px' }} />
                    <Divider className={classes.endDivider} />
                    <div style={{ height: '40px' }} />
                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                        <CustomButton2
                            text={layout.language === 'kor'? 'SEND': layout.language === 'eng'? 'SEND' : '提交'}
                            size={{ width: '178px', height: '44px' }}
                            onclick={handleClick} />
                    </div>
                </div>
                <div style={{ height: '150px' }} />
                <div className={classes.left}>
                    <div style={{ height: '60px' }} />
                    {layout.language === 'cn'?  <Typography variant='body2' className={classes.leftTitle}>邮箱地址</Typography>
                        : <Typography variant='body2' className={classes.leftTitle}>E-mail</Typography>}
                    <Typography variant='body1' >help@rozeus.com</Typography>
                    <div style={{ height: '30px' }} />
                    {/**
                    {layout.language === 'cn'? <Typography variant='body2' className={classes.leftTitle}>联系方式</Typography> 
                        : <Typography variant='body2' className={classes.leftTitle}>Tel</Typography> }
                    <Typography variant='body1' >02-3487-0216</Typography>
                    <div style={{ height: '30px' }} />
                     */}
                    {layout.language === 'cn'? <Typography variant='body2' className={classes.leftTitle}>公司地址</Typography>
                        : <Typography variant='body2' className={classes.leftTitle}>Visit</Typography>}
                    {layout.language === 'cn'? 
                    <Typography variant='body1' >
                        韩国, 首尔特别市中区退溪路385, 准大厦 8F
                    </Typography>
                    :
                    <Typography variant='body1' >
                        INFO@ROZEUS.COM Seoul Head Office<br />
                        #385 Jun Tower 8F,<br/>
                        Toegye-ro<br />
                        Jung-gu Seoul<br />
                        South Korea
                    </Typography>
                    }
                    <div style={{ height: '60px' }} />
                </div>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={msgPop}>
                    <div style={{ height: '40px' }} />
                    <div className={classes.dialogBody}>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-between', height:'100%'}}>
                            <Typography variant='subtitle2'>{msg.title}</Typography>
                            <Typography variant='body1' style={{ flexGrow:1, display:'flex',alignItems:'center' }} >{msg.text}</Typography>
                            <CustomButton2
                                text='확인'
                                size={{ width: '120px', height: '40px' }}
                                onclick={handleClose} />
                        </div>
                    </div>
                    <div style={{ height: '40px' }} />
                </Dialog>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React, { useEffect, useState } from 'react';
import SubMain from '../components/common/SubMain';
import MSubMain from '../components/small/common/SubMain';
import ContactUsChapter1 from '../components/about/contactUs/Chapter1';
import MContactUsChapter1 from '../components/small/about/contactUs/Chapter1';



import AboutTemplates from '../templates/AboutTemplates';
import FooterContainer from './FooterContainer';
import { Hidden } from '@material-ui/core';



const AboutContainer = ({match}) => {

    const [target, setTarget] = useState('resources');

    const subComponentList = {
        privacy : [

        ],
        terms: [

        ],
        contactUs:[
            <ContactUsChapter1 />
        ],
    }

    const subSmallComponentList = {
        privacy: [

        ],
        terms: [

        ],
        contactUs: [
            <MContactUsChapter1 />, <div style={{height:'150px'}} />
        ],
    }

    useEffect(() => {
        if(match){
            if(match.params && match.params.target){
                setTarget(match.params.target);
                const anchor = (document).querySelector(
                    "#back-to-top-anchor"
                );

                if (anchor) {
                    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        }
    },[match]);

    return (
        <React.Fragment>
            <Hidden xsDown>
                <AboutTemplates 
                    main={
                        <SubMain 
                            menu='about' 
                            keyword={target}
                        />}
                    subComponent={subComponentList[target]}
                    footer={<FooterContainer type='sub' />}
                    target={target}
                />
            </Hidden>
            <Hidden smUp>
                <AboutTemplates
                    main={
                        <MSubMain
                            menu='about'
                            keyword={target}
                        />}
                    subComponent={subSmallComponentList[target]}
                    footer={<FooterContainer type='sub' />}
                    target={target}
                />
            </Hidden>
        </React.Fragment>
    )
};

export default AboutContainer;

import React from 'react';
import { newsPr_content } from '../../../common/constants'

import NewsCard from './NewsCard';
// import NewsCard2 from './NewsCard2';



const News = () => {

    return (
        <div style={{ width: '100%' }}>
            {newsPr_content.NEWS &&
                newsPr_content.NEWS.map((item, index) => {
                    return (
                        <React.Fragment>
                            <NewsCard key={index} target={item} />
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
};

export default News;
import React, { useEffect } from 'react';
import { aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    branchRight:{
        //display:'flex',
        position:'relative',
        alignItems:'center',
        height:'100%',
    },
    circleFull :{
        width:'11px',
        height:'11px',
        background: theme.palette.roadmapPrimary,
        borderRadius:'50%'
    },
    centerLine: {
        position: 'relative',
        left: 8,
        top: '0',
        width: '1px',
        height: '100%',
        borderLeft: '1px solid ' + theme.palette.roadmapPrimary
    }
}));

const Branch = ({title, desc, img, imgTop, last, imgPad, layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.branchRight}>
            {!last && <div className={classes.centerLine} />} 
            <div  style={{width:'100%'}}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div className={classes.circleFull} />
                    <div style={{ width: '12px' }} />
                    <Typography variant='body2'>{title}</Typography>
                </div>
                {desc && 
                    desc.map((item, index)=>{
                        return (
                            <div style={{display:'flex'}}>
                                <div style={{ width: '22px' }}/>
                                <Typography key={index} variant='body1'>{item}</Typography>
                            </div>
                        )
                    })}
            </div>
            {img && 
                <div style={{position:'absolute', top:imgTop, left:imgPad}}>
                    <div
                        data-aos='fade-up'
                        data-aos-duration={aos_option.duration}
                        data-aos-offset={aos_option.offset}
                        >
                        {img}
                    </div>
                </div>
            }
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Branch));
import React from 'react';
import { inject, observer } from 'mobx-react';
import { m_size } from '../../../../common/constants'

import { makeStyles, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
    },
    contents1 : {
        fontFamily: 'S-core_dream6_bold',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '22px',
        color:theme.palette.text.footer,
        paddingBottom:'6px'
    }
}));

const Download = ({ layout }) => {

    const classes = useStyles();

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                {layout.language === 'kor'? 
                    <React.Fragment>
                        <div>
                            <Typography className={classes.contents1}>지금 바로 로제우스를 만나보세요.</Typography>
                            <Typography variant='body1' style={{ fontSize: '12px', lineHeight: '20px' }}>로제우스 서비스는 Google Play와 App Store에서</Typography>
                            <Typography variant='body1' style={{ fontSize: '12px', lineHeight: '20px' }}>바로 다운로드 가능합니다.</Typography>
                        </div>
                    </React.Fragment>
                : layout.language === 'eng'? 
                    <React.Fragment>
                       <div>
                            <Typography className={classes.contents1}>Meet Rozeus on Google Play and App Store.</Typography>
                            <Typography variant='body1' style={{ fontSize: '12px', lineHeight: '20px' }}>You may download Rozeus directly </Typography>
                            <Typography variant='body1' style={{ fontSize: '12px', lineHeight: '20px' }}>from Google Play and App Store.</Typography>
                        </div>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <div>
                            <Typography className={classes.contents1}>Rozeus应用程序上架谷歌商城&amp;苹果商城</Typography>
                            <Typography variant='body1' style={{ fontSize: '12px', lineHeight: '20px' }}>您可以在谷歌商城和苹果商城下载并使用 </Typography>
                            <Typography variant='body1' style={{ fontSize: '12px', lineHeight: '20px' }}>Rozeus应用程序。</Typography>
                        </div>
                    </React.Fragment>
                }  

                <div style={{height:'40px'}} />
                <img 
                    src='/images/common/footer/googlePlay.png' 
                    alt='Google play' 
                    style={{ width: '100px', cursor:'pointer', paddingRight:'5.7px' }} 
                    onClick={(e) => window.open('https://play.google.com/store/apps/details?id=com.bflysoft.rozeus')}
                />
            
                <img 
                    src='/images/common/footer/appStore.png' 
                    alt='App store' 
                    style={{ width: '100px', cursor:'pointer' }} 
                    onClick={(e) => window.open('https://apps.apple.com/kr/app/%EB%A1%9C%EC%A0%9C%EC%9A%B0%EC%8A%A4-rozeus-%EA%BC%AD-%ED%95%84%EC%9A%94%ED%95%9C-%EB%89%B4%EC%8A%A4/id1570566887')}
                />
            </div>
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Download));
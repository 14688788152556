import React, { useEffect } from 'react';
import { m_size, aos_option, KOR, ENG, CN } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { makeStyles, Typography } from '@material-ui/core';
import NumberTitle from '../../home/NumberTitle';

import { inject, observer } from 'mobx-react';
import HomeChapterDivider from '../../home/HomeChapterDivider';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        padding: m_size.padding,
        backgroundImage: props => 'url(/images/home/back_chapter4_' + props.mode + '.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: props => props.language === KOR  ? 'left 440px' : props.language === ENG  ? 'left 540px' : 'left 460px' ,
        backgroundSize: '80% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const Chapter4 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div>
                    <NumberTitle
                        number='04'
                        text={
                            layout.language === 'kor'? 
                            ['가능성을 열고', '생각합니다.']
                            :layout.language === 'eng'? 
                            ['Think the possibility', 'with open-heart']
                            :
                            ['打开所有可能性', '行思考。']
                            
                        }
                        top='-62px'
                    /> 
                    <div style={{height:'30px'}} />
                    <div style={{ margin: '0px 0px', width:'262px' }}>
                    {layout.language === 'kor'? 
                        <Typography variant='body1'>컨텍스트 디지타이징 구현 기술, 자연어를 이해하고 가공 및 분류하는 기술, 온/오프라인 네트워크의 디지타이징 기술, 빅데이터 분석 및 처리 기술, 머신러닝(AI) 기술 ‘비정형 데이터’를 분석하는 기술 등 기반 기술을 바탕으로 차세대 미디어 서비스를 생각합니다. 사람들이 잃어버린 뉴스와 미디어에 대한 신뢰를 회복할 방법을 생각합니다.</Typography>
                    : layout.language === 'eng'? 
                        <React.Fragment>
                            <Typography variant='body1'>Consider the next generation of media services based on the underlying technologies of context digitization implementation, of natural language understanding, processing and classification, of on and off-line network digitization, of big data analysis and processing, of machine learning (AI), and of 'non-stationary data' technology and others.</Typography>
                            <div style={{height:'30px'}}/>
                            <Typography variant='body1'>Also consider on how to restore the lost trust of the news and the media.</Typography>
                        </React.Fragment>
                    :
                    <React.Fragment>
                        <Typography variant='body1'>以文字数字化呈现技术、自然语言理解加工</Typography>
                        <Typography variant='body1'>与分类技术，线上、线下网络的数字化技</Typography>
                        <Typography variant='body1'>术，大数据分析与处理技术，深度学习</Typography>
                        <Typography variant='body1'>（AI）技术，不规则数据的分析技术等技术为基础，</Typography>
                        <Typography variant='body1'>思考新一代媒体服务。</Typography>
                        <Typography variant='body1'>思考恢复人们对新闻与媒体信任的方法。</Typography>
                    </React.Fragment>
                    }
                    </div>
                    <div style={{ height: '50px' }} />
                    <HomeChapterDivider type='dots' />
                    <div style={{ height: '40px' }} />
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'column'}}>
            <img
                data-aos='fade-up'
                data-aos-duration={aos_option.duration}
                data-aos-offset={aos_option.offset}
                src={'/images/home/move_chapter4_face_' + layout.mode + '.png'}
                alt='symbol'
                style={{
                    width: '55%',
                    height:'55%',
                    display: 'block',
                    margin: 'auto',
                }}
            />
            </div>
            <div style={{height:'150px'}} />
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter4));
import React, { useCallback, useEffect, useState } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Counter from '../../../home/Counter';
import { throttle } from 'lodash';




const Partners = ({ layout }) => {

    const arr = Array(60).fill().map((item, index) => ++index);

    const [flag1, setFlag1] = useState(true);


    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const listener = useCallback(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section.getBoundingClientRect().height;
        if ((height + scrollTop) > (sectionHeight + 650) && flag1) {
            setFlag1(false)
        }
    }, [flag1]);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener, true);
        return () => {
            window.removeEventListener("scroll", throttleListener, true);
        };
    }, [listener]);

    useEffect(() => {
        setFlag1(true);
    }, [layout.partnerTab]);
    
    useEffect(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section.getBoundingClientRect().height;
        if ((height + scrollTop) > (sectionHeight + 630) && flag1) {
            setFlag1(false)
        }
    }, [flag1]);

    return (
        <React.Fragment>
            {layout.language === 'kor'? 
                <React.Fragment>
                    <Typography variant='h1'>한국 최대 규모</Typography>
                    <Typography variant='h1'>미디어 파트너쉽</Typography>
                </React.Fragment>
            : layout.language === 'eng'?
                <React.Fragment>
                    <Typography variant='h1'>Korea's largest</Typography>
                    <Typography variant='h1'>media partnership  </Typography>       
                </React.Fragment>
            :
                <React.Fragment>
                    <Typography variant='h1'>韩国规模最大的媒体合作伙伴</Typography>                  
                </React.Fragment>
            }
            <div style={{ height: '30px' }} />

            {layout.language === 'kor'? 
                <React.Fragment>
                    <Typography variant='body1'>국내 최대 미디어빅데이터 센터에서 국내,외에서 생산되는 모든 신문의 디지타이징 가공 처리 및</Typography>
                    <Typography variant='body1'>방송 콘텐츠 실시간 수집&모니터링 온라인 미디어 콘텐츠 실시간 수집 및 가공 플랫폼을 제공합니다.</Typography>
                </React.Fragment>
            : layout.language === 'eng'?
                <React.Fragment>
                    <Typography variant='body1'>Korea's largest media big data center provides a real-time online </Typography>
                    <Typography variant='body1'>media content collection and processing platform for digitizing</Typography>
                    <Typography variant='body1'>and broadcasting contents of all newspaper produced in Korea and overseas.</Typography>
                </React.Fragment>
            :
                <React.Fragment>
                    <Typography variant='body1'>在韩国最大的媒体大数据中心，对在韩国和海外生产的所有新闻进行数字化加工处理，</Typography>
                    <Typography variant='body1'>并实时手机电视内容&实时收集并监测网络媒体内容，同时提供加工平台。</Typography>
                </React.Fragment>
            }
            <div style={{ height: '100px' }} />

            {layout.language === 'kor'? 
                <Typography variant='body1'>로제우스 누적 파트너 수</Typography>
            : layout.language === 'eng'?
                <Typography variant='body1'>Cumulative Rozeus' partners</Typography>
            :
                <Typography variant='body1'>ROZEUS累计合作企业数量</Typography>
            }
            
            {flag1 ?
                <Typography variant='subtitle1'>&nbsp;</Typography>
                :
                <Counter countNum={2300} />
            }
            <div style={{ height: '115px' }} />
            <div style={{ width: '100%', display: 'flex',alignItems:'center',justifyContent: 'flex-start',flexWrap:'wrap' }}>
                {
                    arr.map((item, index) => {
                        const fileName = (index + 1) > 9 ? '0' + (index + 1) : '00' + (index + 1);
                        return (
                            <React.Fragment>
                                <div style={{ width: '30%', marginBottom: '10px' }}>
                                    <img key={index} src={'/images/progress/partner/partners/p_' + fileName + '.png'} alt={'client logo' + fileName} style={{width:'100%'}} />
                                </div>
                                { (index + 1) % 3 !== 0 && <div style={{ width: '10px' }} /> }
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </React.Fragment>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Partners));
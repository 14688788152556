import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '32%',
        cursor:'pointer',
    },
    body: {
        position:'relative',
        top: 0,
        width: '100%',
        transition: "all 0.2s ease-in",
        MozTransition: "all 0.2s ease-in",
        WebkitTransition: "all 0.2s ease-in",
        msTransition: "all 0.2s ease-in",
        OTransition: "all 0.2s ease-in",
        borderRadius: '0px 0px 6px 6px / 0px 0px 6px 6px',
        marginBottom: '80px',
        '&:hover': {
            border: '0px solid ',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
            top: -5,
        }
    },
    cardBody:{
        backgroundColor: theme.palette.subCard,
        height:'250.3px',
        padding:'20px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        border: '1px solid ' + theme.palette.divider,
        borderTop: '1px solid ' + theme.palette.subCard,
        borderRadius: '0px 0px 6px 6px / 0px 0px 6px 6px',
    },
    categoryFont : {
        color:theme.palette.text.secondary
    }
}));

const SubCard = ({ target , layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap} onClick={(e) => window.open(target.url)}>
            <div className={classes.body}>
                <img src={target.img} alt='블로그' style={{width:'100%', display:'block'}} />
                <div className={classes.cardBody}>
                    <Typography variant='h3' className={classes.categoryFont}>{target.category}</Typography>
                    <div style={{height:'20px'}} />
                    {layout.language === 'kor'?  
                        <Typography variant='subtitle2' style={{lineHeight:'32px', flexGrow:1}}>{target.text}</Typography>
                    : layout.language === 'eng'?
                        <Typography variant='subtitle2' style={{lineHeight:'32px', flexGrow:1}}>{target.text_eng}</Typography>
                    :
                        <Typography variant='subtitle2' style={{lineHeight:'32px', flexGrow:1}}>{target.text_cn}</Typography>
                    }  
                    <Typography variant='body1' style={{fontSize:'14px'}}>{target.date}</Typography>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(SubCard));
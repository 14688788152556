import React, { useEffect } from 'react';
import { m_size, download } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import CustomButton from '../../../common/CustomButton';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
    titleFont:{
        fontWeight: 600,
        color:theme.palette.text.primary
    },
    divider:{
        backgroundColor: theme.palette.divider2
    },
    button:{
        width:'397px',
        height:'90px',
        color: theme.palette.text.primary,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        border: '1px solid '+theme.palette.menuUnderLine,
        borderRadius:'6px',
        "&:hover": {
            borderColor: theme.palette.button.hover,
            background: theme.palette.button.hover,
            color: theme.palette.button.hoverText,
        },
        "&:active": {
            borderColor: theme.palette.button.hactiveover,
            background: theme.palette.button.active,
            color: theme.palette.button.hoverText,
        },
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const handleClick = (target, lan) => {
        window.open(download[target][lan]);
    }
   
    return (
        <div 
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{ height: '20px' }} />
                <div>
                {layout.language === 'kor'?  
                    <Typography variant='body1' className={classes.titleFont}>백서 Whitepaper</Typography>
                :layout.language === 'eng'?
                    <Typography variant='body1' className={classes.titleFont}>Whitepaper</Typography>
                :
                    <Typography variant='body1' className={classes.titleFont} style={{fontWeight:300}}>白皮书</Typography>
                }  
                    <div style={{height:'10px'}} />
                    <div style={{ display: 'flex', alignItems:'baseline'}}>
                        <div style={{width:'116px'}}>
                            <Divider className={classes.divider} style={{ height: '3px' }} />
                        </div>
                        <div style={{ flexGrow:1 }}>
                            <Divider className={classes.divider}/>
                        </div>
                    </div>
                    <div style={{ height: '20px' }} />
                    <CustomButton text='Korean_DOWNLOAD' size={{ width: '100%', height: '60px'}} onclick={(e) => handleClick('whitepaper','kor')} />
                    <div style={{ height: '10px' }} />
                    <CustomButton text='English_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={(e) => handleClick('whitepaper', 'eng')} />
                    <div style={{ height: '10px' }} />
                    <CustomButton text='Chinese_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={(e) => handleClick('whitepaper', 'ch')} />
                </div>
                <div style={{ height: '120px' }} />
                <div>

                    
                {layout.language === 'kor'? 
                    <Typography variant='body1' className={classes.titleFont}>요약본</Typography>
                :layout.language === 'eng'?
                    <Typography variant='body1' className={classes.titleFont}>Summary</Typography> 
                :
                    <Typography variant='body1' className={classes.titleFont}>精编版</Typography>
                }
                    <div style={{ height: '10px' }} />
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <div style={{ width: '115px' }}>
                            <Divider className={classes.divider} style={{ height: '3px' }} />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <Divider className={classes.divider} />
                        </div>
                    </div>
                    <div style={{ height: '20px' }} />
                    <CustomButton text='Korean_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={(e) => handleClick('summary', 'kor')} />
                    <div style={{ height: '10px' }} />
                    <CustomButton text='English_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={(e) => handleClick('summary', 'eng')} />
                    <div style={{ height: '10px' }} />
                    <CustomButton text='Chinese_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={(e) => handleClick('summary', 'ch')} />
                </div>
                <div style={{ height: '120px' }} />
                <div>
                    <Typography variant='body1' className={classes.titleFont}>IR</Typography>
                    <div style={{ height: '10px' }} />
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <div style={{ width: '12px' }}>
                            <Divider className={classes.divider} style={{ height: '3px' }} />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <Divider className={classes.divider} />
                        </div>
                    </div>
                    <div style={{ height: '20px' }} />
                    <CustomButton text='Korean_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={null} />
                    <div style={{ height: '10px' }} />
                    <CustomButton text='English_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={null} />
                    <div style={{ height: '10px' }} />
                    <CustomButton text='Chinese_DOWNLOAD' size={{ width: '100%', height: '60px' }} onclick={null} />
                </div>
            </div>
            <div style={{ height: '150px' }} />
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React, { useState } from 'react';
import { m_size } from '../../../../common/constants'

import { Fade, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Typist from 'react-typist';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
    },
    fontStyle:{
        fontFamily: 'Nanum Myeongjo',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '26px',
    },
    fontStyle2: {
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '20px',
    }
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();

    const [typistInit, setTypistInit] = useState(false);
    const [showTom, setShowTom] = useState(false);

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{ display: 'flex', flexDirection:'column',alignItems: 'center' }}>
                    <div style={{height:'120px'}} />
                    <img src={'/images/profile/how/ic_how_chap2_open_'+layout.mode+'.png'} alt='"' style={{width:'14px'}} />
                    <div style={{ height: '50px' }} />
                    <div style={{height:'206px'}}>
                        {!typistInit ?
                        <div onMouseOver={(e)=> setTypistInit(true)}>
                        {layout.language === 'kor'? 
                            <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    정보의 통제는,<br />
                                    특히 독재 정치 체제의,<br />
                                    엘리트 들이 항상 하는 일 입니다.<br />
                                    정보와 지식은 힘이기 때문입니다.<br />
                                    정보를 제어하는 것은<br />
                                    사람을 제어하는 것과 같습니다.
                            </Typography>
                        :layout.language === 'eng'?
                            <Typography className={classes.fontStyle} style={{ textAlign: 'center' , padding:'0px 20px'}}>
                                    The control of information is something the elite always does, particularly in a despotic form of government.<br/>
                                    Information, knowledge, is power. If you can control information, you can control people.<br/>
                            </Typography>
                        :
                            <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    信息管制，是垄断政治体制中精<br/>
                                    英们经常做的事情。<br/>
                                    因为信息与知识是力量的源泉。<br/>
                                    控制信息与控制人本身<br/>
                                    是一样的。
                            </Typography>
                        } 
                            <div style={{ height: '30px' }} />
                            <div style={{ height: '70px' }}>
                                <Typography className={classes.fontStyle2} style={{ textAlign: 'center' }}>- Tom Clancy</Typography>
                            </div>
                        </div>
                        :
                        <div onMouseOut={(e) => {setTypistInit(false); setShowTom(false);}}>
                                <Typist cursor={{ show: false }} onTypingDone={(e)=>setShowTom(true)}>
                                {layout.language === 'kor'? 
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    정보의 통제는,<br />
                                    특히 독재 정치 체제의,<br />
                                    엘리트 들이 항상 하는 일 입니다.<br />
                                    정보와 지식은 힘이기 때문입니다.<br />
                                    정보를 제어하는 것은<br />
                                    사람을 제어하는 것과 같습니다.
                                </Typography>
                                :layout.language === 'eng'?
                                <Typography className={classes.fontStyle} style={{textAlign: 'center', padding:'0px 20px'}}>
                                    The control of information is something the elite always does, particularly in a despotic form of government.<br/>
                                    Information, knowledge, is power. If you can control information, you can control people.<br/>
                                </Typography>
                                :
                                <Typography className={classes.fontStyle} style={{ textAlign: 'center' }}>
                                    信息管制，是垄断政治体制中精<br/>
                                    英们经常做的事情。<br/>
                                    因为信息与知识是力量的源泉。<br/>
                                    控制信息与控制人本身<br/>
                                    是一样的。
                                </Typography>
                                } 

                            </Typist>
                            {showTom &&
                                <React.Fragment>
                                    <div style={{ height: '16px' }} />
                                    <Fade in={showTom} timeout={2000}>
                                    <div style={{ height: '70px' }} >
                                        <Typography className={classes.fontStyle2} style={{ textAlign: 'center' }}>- Tom Clancy</Typography>
                                    </div>
                                    </Fade>
                                </React.Fragment>
                            }
                        </div>
                        }
                    </div>
                    <div style={{ height: '50px' }} />
                    <img src={'/images/profile/how/ic_how_chap2_close_' + layout.mode + '.png'} alt='"' style={{ width: '14px' }} />
                    <div style={{ height: '150px' }} />
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
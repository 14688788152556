import React, { useEffect, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';

import { Dialog, Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import Axios from 'axios'

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
        padding:'30px',
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0px'
            }
        },
        color: theme.palette.text.primary
    },
    endDivider: {
        backgroundColor: theme.palette.footerDivider
    },
}));

const Chapter1 = ({ target, layout }) => {

    const classes = useStyles();
    const [hover, setHover] = useState(false);

    const handleClose = () =>{
        layout.setShowAboutModal(false);
    }

    useEffect(() => {
        if(target && target !== ''){
            const targetId = target === 'Privacy Policy' ? '6' : '5'
            const path = 'https://www.rozeus.com/RozeusAPI/user/termsList'
            const config = {
                headers: {
                    'Authorization': 'Basic cm96ZXVzOnJvemV1czEyMyE=',
                },
            }

            Axios.post(path, null, config)
                .then((response) => {
                    if(response.data.termsList){
                        const targetData = response.data.termsList;
                        targetData.map((item, index) => {
                            if (item.termsId === targetId) {
                                layout.setModalAboutTitle(item.termsNm)
                                if (item.termsContent) {
                                    const termsCont = item.termsContent;
                                    if (termsCont && termsCont !== null && termsCont !== '') {
                                        layout.setModalAboutContents(termsCont.replace(/color:#454545/gi, ''))
                                    }
                                }
                            }
                            return null;
                        })
                    }
                }).catch(err => {
                    return null;
                });
        }
    }, [layout, target]);

    return (
        <Dialog 
            fullWidth={true} 
            maxWidth='md'
            onClose={handleClose} open={layout.showAboutModal}>
            <div className={classes.bodyWrap}>
                <div style={{display:'flex',justifyContent:'space-between ', alignItems:'center'}}>
                    <Typography variant='subtitle2'>{layout.modalAboutTitle && layout.modalAboutTitle}</Typography>
                    <img 
                        src={'/images/common/footer/modal/ic_button_close_' + (hover ? 'hover_' : '') +layout.mode+'.png'} 
                        alt='Popup Close' 
                        style={{ display: 'block', width: '20px', height: '20px', cursor: 'pointer'}}
                        onMouseOver={(e) => setHover(true)}
                        onMouseOut={(e) => setHover(false)}
                        onClick={handleClose}
                        />
                </div>
                <div style={{height:'30px'}} />
                <Divider className={classes.endDivider} />
                <div style={{ height: '30px' }} />
                <div style={{height:'472px', maxHeight:'472px', overflow:'auto'}}>
                    {
                        (layout.modalAboutContents && layout.modalAboutContents !== '') && ReactHtmlParser(layout.modalAboutContents)
                    }
                </div>
            </div>
        </Dialog>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
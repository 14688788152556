import { Fade } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Meta from '../meta/meta';
import { LIGHT, DARK } from '../common/constants'

const ProfileTemplates = ({ main, subComponent, footer, menu, layout, target }) => {
    return (
        <React.Fragment>
            <Meta language={layout.language} target={target} parent='profile' />
            <Fade in={true} timeout={600}>
                <div onClick={menu.deselect}>
                {main}
                {subComponent && 
                    subComponent.map((item, index)=>{
                        return <React.Fragment key={index}>
                            {item}
                            </React.Fragment>
                    })
                }
                {footer}
                </div>
            </Fade>
        </React.Fragment>
    );
};

export default inject(({ menu, layout }) => ({
    menu: menu,
    layout: layout
}))(observer(ProfileTemplates));
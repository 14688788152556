import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { LIGHT, DARK } from '../common/constants'

const HomeTemplate = ({ main, chapter1, chapter2, chapter3, chapter4, marginTab, menu }) => {
    return (
        <Fade in={true} timeout={600}>
            <div onClick={menu.deselect}>
                <div id='top'>
                    {main}
                </div>
                {chapter1}
                {chapter2}
                {chapter3}
                {chapter4}
                {marginTab}
            </div>
        </Fade>
    );
};

export default inject(({ menu }) => ({
    menu: menu
}))(observer(HomeTemplate));
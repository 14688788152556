import React, { useEffect } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        width: '100%',
        height: '2125px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    titleCard :{
        width: '196px',
        height: '150px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        backgroundColor: theme.palette.background.sub.subBackground,
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{ height: '220px' }} />
                <Typography variant='h1'>The Tech</Typography>
                <div style={{height:'150px'}}/>
                <div style={{display:'flex',justifyContent:'center'}}>
                    <img src={'/images/profile/how/chart_how_chap4_'+layout.mode+'_'+layout.language+'.png'} alt='Chart' style={{width:'933px',height:'485px'}} />
                </div>
                <div style={{ height: '258px' }} />
                <div
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'center', height: '150px' }}>
                        <div className={classes.titleCard}>
                            <img src={'/images/profile/how/ic_how_chap4_tara_' + layout.mode + '.png'} alt='TARA' style={{ width: '45.07px', height: '52px' }} />
                            <div style={{height:'22px'}} />
                            <Typography variant='body2'>TARA</Typography>
                        </div>
                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'? 
                            <Typography variant='body1' style={{maxWidth:'1019px', minWidth:'700px'}}>“TARA”는 기사 및 기타 정보에 대한 보다 깊은 이해를 얻기 위해 사용자가 필요로 하는 광범위한 문맥 정보를 자동으로 식별, 선택 및 제시하는 기술입니다. “TARA”는 비플라이소프트가 개발한 미디어 지수, 자연어 처리 기술,텍스트 마이닝 등의 기술과 로제우스 생태계의 컨텐츠 분석 기술들을 통해 발전되고 실현 될 수 있습니다.</Typography>
                        :layout.language === 'eng'? 
                            <Typography variant='body1' style={{maxWidth:'1019px', minWidth:'700px'}}>"TARA" is a technology that automatically identifies, selects, and presents a wide range of contextual information that users need to gain a deeper understanding of news articles or other information.<br />
                                                                                                      "TARA" will be evolved and realized by combining Bflysoft's media indexing, natural language processing, text mining technologies and Rozeus content analysis technology.</Typography>
                        :
                            <Typography variant='body1' style={{maxWidth:'1019px', minWidth:'700px'}}>“TARA”是为了获得对新闻以及其他信息的更深入的理解，自动识别并选择推荐用户所需的广泛文章信息的技术。<br />
                                                                                                      “TARA"通过融合Bfly Soft公司开发的媒体指数、自然语言处理技术、文本挖掘等技术与ROZEUS生态体系的内容分析技术来发展实现。</Typography>

                        }
                    </div>
                    <div style={{ height: '50px' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '150px' }}>
                        <div className={classes.titleCard}>
                            <img src={'/images/profile/how/ic_how_chap4_mimic_' + layout.mode + '.png'} alt='TARA' style={{ width: '58.01px', height: '52px' }} />
                            <div style={{ height: '22px' }} />
                            <Typography variant='body2'>MIMIC</Typography>
                        </div>
                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'? 
                            <Typography variant='body1' style={{ maxWidth: '1019px', minWidth: '700px' }}>‘미믹(MIMIC)’은 컨텐츠 추천 알고리즘의 핵심 기술인 Metadata Information & Media Index & Category Optimization의 약자입니다. ‘미믹(MIMIC)’은 기존 ‘협력필터링’에 한발 더 나아가 메타정보 추출 알고리즘과 TARA 그리고 카테고리 최적화 기술을 머신러닝과 결합하여 사용자에게 최적의 뉴스를 추천합니다.</Typography>
                        :layout.language === 'eng'? 
                            <Typography variant='body1' style={{ maxWidth: '1019px', minWidth: '700px' }}>"MIMIC" is the acronym of Metadata Information & Media Index & Category Optimization, the in-house developed machine learning content recommendation system.</Typography>
                        :
                            <Typography variant='body1' style={{ maxWidth: '1019px', minWidth: '700px' }}>“MIMIC”是内容推荐算法核心技术Metadata information & Media Index & Category Optimization的缩写。“MIMIC”将进一步发展现有的“协作过滤器”，将Meta Info提取算法与TARA，以及目录优化技术与深度学习相结合，为用户推荐最合适的新闻。</Typography>
                        }
                    </div>
                    <div style={{ height: '50px' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '150px' }}>
                        <div className={classes.titleCard}>
                            <img src={'/images/profile/how/ic_how_chap4_chain_' + layout.mode + '.png'} alt='TARA' style={{ width: '55.16px', height: '52px' }} />
                            <div style={{ height: '22px' }} />
                            {layout.language === 'cn'? 
                            <Typography variant='body2' style={{textAlign:'center'}}>事实链 <br /> (FACT CHAIN）</Typography>
                            :
                            <Typography variant='body2'>FACT CHAIN</Typography>
                            }
                        </div>
                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'? 
                            <Typography variant='body1' style={{ maxWidth: '1019px', minWidth: '700px' }}>팩트 체인은 일정 수준과 일련의 검증 결과를 통화하여 사실 검증 여부의 최종화가 성립됐다고 판단되는 컨텐츠들의 체인입니다. 팩트 체인에 등록된 컨텐츠는 오리지널 작성자와 검증자 모두 그들의 저작물에 (각각 오리지널 컨텐츠와 그에 대한 검증 활동 내용)한 보호를 받습니다. 팩트 체인은 시간과 비례하여 네트워크의 가치를 실질적인 정보 체인의 형태로 정제하는 역할을 수행합니다.</Typography>
                        :layout.language === 'eng'? 
                            <Typography variant='body1' style={{ maxWidth: '1019px', minWidth: '700px' }}>The fact chain is a chain of contents that has gone through a certain standard and series of verified results in the rozeus internal service, and of which the matter of fact has been verified. The rozeus service users can receive compensation through the truth verification activity, and therefore we can expect the gradual advancement of contents verification activity. Hence, the Fact chain is in proportion to the time and refines the network value in the form of an actual information chain.</Typography>
                        :
                            <Typography variant='body1' style={{ maxWidth: '1019px', minWidth: '700px' }}>事实链将整合一定水平与验证结果，把最终确认事实验证结果的内容结合在一起形成内容链。记录在事实链上的内容原创作者与验证人的撰写作品（各原创内容与对其进行验证的活动内容）都将得到保护。事实链随着时间流逝，将履行把网络价值净化为实际信息链的责任。</Typography>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React from 'react';

import { makeStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    embedWrap: {
        position: 'relative',
        width: '264px',
        height:'264px',
        backgroundColor: '#000000',
    },
    embed: {
        width: '264px',
        height: '264px',
        opacity:'0.6',
    },
    playButton:{
        position: 'absolute',
        padding:'0px',
        margin:'0px',
        width:'60px',
        height:'60px',
        top:'calc(50% - 30px)',
        left:'calc(50% - 30px)',
        cursor:'pointer'
    },
    playIco: {
        position:'absolute',
        width: '60px',
        height: '60px',
        backgroundSize:'60px 60px',
        backgroundImage:'url(/images/common/ic_video_play.png)',
        backgroundRepeat:'no-repeat',
        transition: "all 0.3s ease-in",
        MozTransition: "all 0.3s ease-in",
        WebkitTransition: "all 0.3s ease-in",
        msTransition: "all 0.3s ease-in",
        OTransition: "all 0.3s ease-in",
        "&:hover": {
            backgroundImage: props => 'url(/images/common/ic_video_play_hover_' + props.mode + '.png)',
        },
        "&:active": {
            backgroundImage: props => 'url(/images/common/ic_video_play_active_' + props.mode + '.png)',
        },
    },
}));

const VideoComponent = ({ imgPath, videoPath, vertical, horizontal, layout }) => {

    const classes = useStyles(layout);

    const handleClick = () => {
        window.open(videoPath)
        //layout.showVideo(videoPath);
    }

    return (
        <div 
            className={classes.embedWrap} 
            onClick={handleClick}
            >{/**
            <video id="background-video-chapter2" preload={`auto`} muted className={classes.embed} ref={videoRef} onEnded={(e)=>setPlay(false)}>
                <source src={srcPath} type="video/mp4" />
            </video>
             */}
            <img src={imgPath} alt='thumnail' className={classes.embed} style={{height:'auto'}}/>
            <div className={classes.playButton}>
                <div className={classes.playIco}/>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout
}))(observer(VideoComponent));
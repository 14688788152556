export const SMALL = 'small';
export const LARGE = 'large';
export const LIGHT = 'light';
export const DARK = 'dark';
export const KOR = 'kor';
export const ENG = 'eng';
export const CN = 'cn';


export const themeType = {
    SMALL_LIGHT_KOR: SMALL + '_' + LIGHT + '_' + KOR,    
    SMALL_DARK_KOR: SMALL + '_' + DARK + '_' + KOR,
    LARGE_LIGHT_KOR: LARGE + '_' + LIGHT + '_' + KOR,
    LARGE_DARK_KOR: LARGE + '_' + DARK + '_' + KOR,
    SMALL_LIGHT_ENG: SMALL + '_' + LIGHT + '_' + ENG,    
    SMALL_DARK_ENG: SMALL + '_' + DARK + '_' + ENG,
    LARGE_LIGHT_ENG: LARGE + '_' + LIGHT + '_' + ENG,
    LARGE_DARK_ENG: LARGE + '_' + DARK + '_' + ENG,
    SMALL_LIGHT_CN: SMALL + '_' + LIGHT + '_' + CN,    
    SMALL_DARK_CN: SMALL + '_' + DARK + '_' + CN,
    LARGE_LIGHT_CN: LARGE + '_' + LIGHT + '_' + CN,
    LARGE_DARK_CN: LARGE + '_' + DARK + '_' + CN,
}

export const size = {
    maxWidth:1240,
    minWidth:920,
    videoHeight:932,
    chageLineWidth: 1200,
}

export const m_size = {
    maxWidth: 600,
    minWidth: 300,
    padding: '0px 16px',
}

export const color = {
    divider : {
        normal :{
            light: '',
            dark:''
        },
        sub : {
            light: '',
            dark: ''
        }
    }
}

export const aos_option = {
        duration : 2000,
        offset : 150
}
export const menuList = {
    PROFILE : [
        {
            name : 'What is Rozeus?',
            url : '/profile/whatIsRozeus',
        },
        {
            name: 'Why and How',
            url: '/profile/whyAndHow',
        },
        // {
        //     name: 'What is ROZ?',
        //     url: '/profile/whatIsROZ',
        // },
        {
            name: 'NEWS & PR',
            url: '/profile/newsPr',
        },
        {
            name: 'Press Kit',
            url: '/profile/pressKit',
        }
    ],
    PROGRESS : [
        // {
        //     name: 'Resources',
        //     url: '/progress/resources',
        // },
        {
            name: 'Roadmap',
            url: '/progress/roadmap',
        },
        {
            name: 'Numbers',
            url: '/progress/numbers',
        },
        {
            name: 'Partners',
            url: '/progress/partner',
        },
    ],
    SERVICE : [
        {
            name: 'Rozeus Service',
            url: 'http://www.rozeus.com',
            type:'new'
        },
        {
            name: 'News Briefing',
            url: 'https://blog.naver.com/PostList.nhn?blogId=bflysoft_biz&from=postList&categoryNo=1&parentCategoryNo=1',
            type: 'new'
        },
        {
            name: 'Media Deep Dive Blog',
            url: 'https://blog.naver.com/rozeus8',
            type: 'new'
        },
        // {
        //     name: 'ROZ Wallet',
        //     url: 'http://www.rozeus.io/app-universal-release.apk',
        //     type: 'new'
        // },
    ],
    ABOUT:[
        {
            name: 'Privacy Policy',
            url: '',
        },
        {
            name: 'Terms of Use',
            url: '',
        },
        {
            name: 'Contact Us',
            url: '/about/contactUs',
        }
    ],
    LANGUAGE:[
        {
            name: 'Language',
            url: ''
        },
        {
            name: 'English',
            url: ''
        },
        {
            name: 'Korean',
            url:''
        },
        {
            name: 'Chinese',
            url:''
        }
    ]

}

export const subMain = {
    pageName : {
        profile : {
            whatIsRozeus_kor: 'What is Rozeus?',
            whyAndHow_kor: 'Why and how?',
            whatIsROZ_kor: 'What is ROZ?',
            newsPr_kor: '',
            pressKit_kor: '',
            whatIsRozeus_eng: 'What is Rozeus?',
            whyAndHow_eng: 'Why and how?',
            whatIsROZ_eng: 'What is ROZ?',
            newsPr_eng: '',
            pressKit_eng: '',
            whatIsRozeus_cn: 'What is Rozeus?',
            whyAndHow_cn: 'Why and how?',
            whatIsROZ_cn: 'What is ROZ?',
            newsPr_cn: '',
            pressKit_cn: '',
        },
        progress: {
            resources_kor: 'Rozeus Paper',
            roadmap_kor: 'Where are we headed?',
            numbers_kor: 'Making data meaningful',
            partner_kor: 'Credible and reliable network',
            resources_cn: 'Rozeus Paper',
            roadmap_cn: 'Where are we headed?',
            numbers_cn: 'Making data meaningful',
            partner_cn: 'Credible and reliable network',
            resources_eng: 'Rozeus Paper',
            roadmap_eng: 'Where are we headed?',
            numbers_eng: 'Making data meaningful',
            partner_eng: 'Credible and reliable network',
        },
        about:{
            contactUs:'Contact us',
            contactUs_cn:'联系我们',
        }
    },
    title: {
        profile: {
            whatIsRozeusLong_kor: ['디지털 미래의', '글로벌 미디어 오픈 플랫폼'],
            whatIsRozeusShort_kor: ['디지털 미래의', '글로벌 미디어', '오픈 플랫폼'],
            whatIsRozeusLong_eng: ['Global Media Open Platform', 'for Digital Future'],
            whatIsRozeusShort_eng: ['Global Media', 'Open Platform for', 'Digital Future'],
            whatIsRozeusLong_cn: ['数字化时代的未来', '全球媒体开放平台'],
            whatIsRozeusShort_cn: ['数字化时代的未来', '全球媒体开放平台'],

            whyAndHowLong_kor: ['미디어를 위한','새로운 땅이 필요합니다.'],
            whyAndHowShort_kor: ['미디어를 위한', '새로운 땅이', '필요합니다.'],
            whyAndHowLong_eng: ['The media', ' needs', 'a new territory.'],
            whyAndHowShort_eng: ['The media', ' needs', 'a new territory.'],
            whyAndHowLong_cn: ['媒体需要全新的','发展平台'],
            whyAndHowShort_cn: ['媒体需要全新的','发展平台'],

            whatIsROZLong_kor: ['디지털 미디어 시대의 통화'],
            whatIsROZShort_kor: ['디지털 미디어','시대의 통화'],
            whatIsROZLong_eng: ['Currency of the digital', 'media era'],
            whatIsROZShort_eng: ['Currency of', 'the digital', 'media era'],
            whatIsROZLong_cn: ['数字媒体时代的货币'],
            whatIsROZShort_cn: ['数字媒体时代的货币'],

            newsPrLong_kor: ['NEWS &','PR'],
            newsPrShort_kor: ['NEWS &', 'PR'],
            newsPrLong_eng: ['NEWS &','PR'],
            newsPrShort_eng: ['NEWS &', 'PR'],
            newsPrLong_cn: ['NEWS &','PR'],
            newsPrShort_cn: ['NEWS &', 'PR'],

            pressKitLong_kor: ['PRESS KIT &', 'BRAND ASSETS'],
            pressKitShort_kor: ['PRESS KIT &', 'BRAND ASSETS'],
            pressKitLong_eng: ['PRESS KIT &', 'BRAND ASSETS'],
            pressKitShort_eng: ['PRESS KIT &', 'BRAND ASSETS'],
            pressKitLong_cn: ['PRESS KIT &', 'BRAND ASSETS'],
            pressKitShort_cn: ['PRESS KIT &', 'BRAND ASSETS'],
        },
        progress: {
            resourcesLong_kor: ['백서 및 문서', 'RESOURCES'],
            resourcesShort_kor: ['백서 및 문서', 'RESOURCES'],
            resourcesLong_eng: ['Whitepaper', 'RESOURCES'],
            resourcesShort_eng: ['Whitepaper', 'RESOURCES'],
            resourcesLong_cn: ['白皮书与文件','资源'],
            resourcesShort_cn: ['白皮书与文件','资源'],

            roadmapLong_kor: ['ROZEUS', 'ROADMAP'],
            roadmapShort_kor: ['ROZEUS', 'ROADMAP'],
            roadmapLong_eng: ['ROZEUS', 'ROADMAP'],
            roadmapShort_eng: ['ROZEUS', 'ROADMAP'],
            roadmapLong_cn: ['ROZEUS', 'ROADMAP'],
            roadmapShort_cn: ['ROZEUS', 'ROADMAP'],

            numbersLong_kor: ['Media Big Data', 'Numbers'],
            numbersShort_kor: ['Media Big Data', 'Numbers'],
            numbersLong_eng: ['Media Big Data', 'Numbers'],
            numbersShort_eng: ['Media Big Data', 'Numbers'],
            numbersLong_cn: ['Media Big Data', 'Numbers'],
            numbersShort_cn: ['Media Big Data', 'Numbers'],

            partnerLong_kor: ['Clients &', 'Partners'], 
            partnerShort_kor: ['Clients &', 'Partners'],
            partnerLong_eng: ['Clients &', 'Partners'], 
            partnerShort_eng: ['Clients &', 'Partners'],
            partnerLong_cn: ['Clients &', 'Partners'], 
            partnerShort_cn: ['Clients &', 'Partners'],
        },
        about: {
            contactUsLong_kor: ['CONTACT', 'ROZEUS'],
            contactUsShort_kor: ['CONTACT', 'ROZEUS'],
            contactUsLong_eng: ['CONTACT', 'ROZEUS'],
            contactUsShort_eng: ['CONTACT', 'ROZEUS'],
            contactUsLong_cn: ['联系我们', 'ROZEUS'],
            contactUsShort_cn: ['联系我们', 'ROZEUS'],
        }
    },
    subTitle: {
        profile: {
            whatIsRozeus_kor: [
                '미래 디지털 시대의 미디어를 위한 투명하고 합리적인 신뢰 기반 플랫폼입니다.',
                '믿을 수 있고 신뢰할 수 있는 건강한 미디어 생태계를 만들어 갑니다.'
            ],
            whatIsRozeus_eng: [
                'Rozeus is a transparent, reasonable, and reliable platform for the future digital era media.',
                'We are developing a reliable and healthy media ecosystem.'
            ],
            whatIsRozeus_cn: [
                '未来型数字化时代媒体的透明、合理、可信任平台。',
                '构建值得信任的健康媒体生态体系。'
            ],

            small_whatIsRozeus_kor: [
                '미래 디지털 시대의 미디어를 위한',
                '투명하고 합리적인 신뢰 기반 플랫폼입니다.',
                '믿을 수 있고 신뢰할 수 있는',
                '건강한 미디어 생태계를 만들어 갑니다.'
            ],
            small_whatIsRozeus_eng: [
                'Rozeus is a transparent, reasonable, and reliable platform',
                'for the future digital era media.',
                'We are developing a reliable  ',
                'and healthy media ecosystem.'
            ],
            small_whatIsRozeus_cn: [
                '未来型数字化时代媒体的透明、',
                '合理、可信任平台。',
                '构建值得信任的健康媒体生态体系。'
            ],
            
            whyAndHow_kor: [
                '인공 지능 알고리즘이 적용된 빅데이터분석 기술을 적용한 미디어 플랫폼을 구축해', 
                '뉴스와 미디어 그리고 사람이 만나는 프로세스를 혁신합니다.'
            ],
            whyAndHow_eng: [
                'Revolution of interaction between news, ',
                'media, and people through a media platform', 
                'that uses Big data analysis with',
                'artificial intelligence algorithms.'
            ],
            whyAndHow_cn: [
                '构建采用人工智能算法大数据分级',
                '技术的媒体平台，创新新闻与媒体',
                '以及用户使用的流程。'
            ],

            small_whyAndHow: [
                '인공 지능 알고리즘이 적용된 빅데이터분석',
                '기술을 적용한 미디어 플랫폼을 구축해',
                '뉴스와 미디어 그리고 사람이 만나는',
                '프로세스를 혁신합니다.'
            ],
            small_whyAndHow_eng: [
                'Revolution of interaction between news, ',
                'media, and people through a media platform', 
                'that uses Big data analysis with',
                'artificial intelligence algorithms.'
            ],
            small_whyAndHow_cn: [
                '构建采用人工智能算法大数据分级',
                '技术的媒体平台，创新新闻与媒体',
                '以及用户使用的流程。'
            ],

            
            whatIsROZ_kor: [
                'ROZ는 로제우스 생태계를 동작하게 만들어 주는 플랫폼의 대표 가상 자산입니다.',
                '비트 코인과 비슷하게 인터넷에서 사용할 수 있는 디지털 화폐입니다.',
                '암호 화폐가 처음이라면 ROZ가 기존 화폐와 어떻게 다른지 알아보세요.'
            ],
            whatIsROZ_eng: [
                'ROZ is the representative virtual asset of our platform that activates the Rozeus ecosystem. ',
                'ROZ is similar to BitCoin in that it can be used on the Internet.',
                'If you are not familiar with cryptocurrency, find out how ROZ differs from existing currency.'
            ],
            whatIsROZ_cn: [
                'ROZ是启动ROZEUS生态体系的平台代表性虚拟资产。',                
                '与比特币类似，是在互联网中使用的数字货币。',
                '如果您第一次接触加密货币，就来一起了解下ROZ与传统货币有什么区别吧！'
            ],
            small_whatIsROZ_kor: [
                'ROZ는 로제우스 생태계를 동작하게',
                '만들어 주는 플랫폼의 대표',
                '가상 자산입니다.',
                '비트 코인과 비슷하게 인터넷에서',
                '사용할 수 있는 디지털 화폐입니다.',
                '암호 화폐가 처음이라면 ROZ가',
                '기존 화폐와 어떻게 다른지 알아보세요.'
            ],
            small_whatIsROZ_eng: [
                'ROZ is the representative virtual asset of the platform that makes the Rozeus ecosystem work.',
                'This is a digital currency that can be used on the Internet similar to Bitcoin.',
                'You are suggested to find out what ROZ is different from existing currency if cryptocurrency is less familiar.'
            ],
            small_whatIsROZ_cn: [
                'ROZ是启动ROZEUS生态体系的平台代',
                '表性虚拟资产。',
                '与比特币类似，是在互联网中使用的数字货币。',
                '如果您第一次接触加密货币，就来一起',
                '了解下ROZ与传统货币有什么区别吧！'
            ],

            newsPr: '',
            small_newsPr: '',
            pressKit: '',
            small_pressKit: '',
        },
        progress: {
            resources: '',
            roadmap: '',
        },
        about: {
            contactUs:'',
        }
    },
    img:{
        profile:{
            whatIsRozeus:{
                path:'/images/profile/rozeus/symbol_whatIsRozeus_',
                smallPath: '/images/profile/rozeus/small/symbol_whatIsRozeus_',
                top:'16px',
                imgWidth:'54%',
                imgHeight:'529.64px',
                zIndex:{
                    logo: -1003,
                    symbol: -1002
                },
                effect: true,
                backgroundColor: false
            },
            whyAndHow: {
                path: '/images/profile/how/symbol_whyAndHow_',
                smallPath: '/images/profile/how/small/symbol_whyAndHow_',
                top: '54px',
                imgWidth: '56%',
                imgHeight: '451.51px',
                zIndex: {
                    logo: -1003,
                    symbol: -1002
                }, 
                effect: true,
                backgroundColor: false
            },
            whatIsROZ: {
                path: '/images/profile/roz/symbol_whatIsROZ_',
                smallPath: '/images/profile/roz/small/symbol_whatIsROZ_',
                top: '29px',
                imgWidth: '50%',
                imgHeight: '462.04px',
                zIndex: {
                    logo: -1003,
                    symbol: -1002
                },
                effect: true,
                backgroundColor: false
            },
            newsPr: {
                path: '/images/profile/newsPr/symbol_newsPr_',
                smallPath: '/images/profile/newsPr/small/symbol_newsPr_',
                top: '0px',
                imgWidth: '48%',
                imgHeight: '532px',
                backHeight: '532px',
                zIndex: {
                    logo: -1003,
                    symbol: -1002
                },
                effect: true,
                backgroundColor: true
            },
            pressKit: {
                path: '/images/profile/pressKit/symbol_pressKit_',
                smallPath: '/images/profile/pressKit/small/symbol_pressKit_',
                top: '74px',
                imgWidth: '50%',
                imgHeight: '377px',
                zIndex: {
                    logo: -1003,
                    symbol: -1002
                },
                effect: true,
                backgroundColor: false
            },
        },
        progress:{
            resources:{
                path:'/images/progress/resource/symbol_resources_',
                smallPath: '/images/progress/resource/small/symbol_resources_',
                top:'116px',
                imgWidth:'48%',
                imgHeight:'367.5px',
                zIndex: {
                    logo: -1003,
                    symbol: -1002
                },
                effect: true,
                backgroundColor: false
            },
            roadmap: {
                path: '/images/progress/roadmap/symbol_roadmap_',
                smallPath: '/images/progress/roadmap/small/symbol_roadmap_',
                top: '20px',
                imgWidth: '48%',
                imgHeight: '565.32px',
                zIndex: {
                    logo: -1002,
                    symbol: -1003
                },
                effect: false,
                backgroundColor: false
            },
            numbers: {
                path: '/images/progress/numbers/symbol_numbers_',
                smallPath: '/images/progress/numbers/small/symbol_numbers_',
                top: '77px',
                imgWidth: '52%',
                imgHeight: '489.82px',
                zIndex: {
                    logo: -1002,
                    symbol: -1003
                },
                effect: true,
                backgroundColor: false
            },
            partner: {
                path: '/images/progress/partner/symbol_partner_',
                smallPath: '/images/progress/partner/small/symbol_partner_',
                top: '66px',
                imgWidth: '30%',
                imgHeight: '510px',
                zIndex: {
                    logo: -1002,
                    symbol: -1003
                },
                effect: false,
                backgroundColor: true
            }
        },
        about:{
            contactUs: {
                path: '/images/about/contactUs/symbol_contactUs_',
                smallPath: '/images/about/contactUs/symbol_contactUs_',
                top: '159px',
                imgWidth: '50%',
                imgHeight: '510px',
                zIndex: {
                    logo: -1002,
                    symbol: -1003
                },
                effect: false,
                backgroundColor: false
            }
        }
    }
}

export const download = {
    whitepaper : {
        kor: '/resources/file/ROZEUS_Whitepaper_v.1.1_KO.pdf',
        eng: '/resources/file/ROZEUS_Whitepaper_v.1.1_EN.pdf',
        ch: '/resources/file/ROZEUS_Whitepaper_v.0.99_CH.pdf',
    },
    summary : {
        kor: '/resources/file/ROZEUS_Bruchure_VERSION_KO.pdf',
        eng: '/resources/file/ROZEUS_Bruchure_VERSION_EN.pdf',
        ch: '/resources/file/ROZEUS_Bruchure_VERSION_CH.pdf',
    },
    ir: {
        kor: '',
        eng: '',
        ch: '',
    },
}

export const newsPr_content = {
    UPDATE : [
        // {
        //     img: '/images/profile/newsPr/update13.png',
        //     url:'https://blog.naver.com/rozeus8/221911610214',
        //     category:'Naver Blog',
        //     size : {
        //         width:'',
        //         height:''
        //     },
        //     text:'로제우스 블록체인 지갑 마켓 출시 및 지갑 출시 이벤트 진행',
        //     text_eng:'Rozeus Released Blockchain Intergrated Wallet.',
        //     text_cn:'로제우스 블록체인 지갑 마켓 출시 및 지갑 출시 이벤트 진행',
        //     date: '2020.04.16',
        // },
        {
            img: '/images/profile/newsPr/update14.png',
            url: 'https://blog.naver.com/bflysoft_biz/222380951935',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: 'MXC delisting',
            text_eng: 'MXC delisting',
            text_cn: 'MXC delisting',
            date: '2021.06.02',
        },
        {
            img: '/images/profile/newsPr/update12.png',
            url: 'https://blog.naver.com/rozeus8/221764278396',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '비플라이소프트, 최고기술경영자로 고려대 강필성 교수 선임',
            text_eng: 'Best Data scientist professor Pil Sung Kang joins Bflysoft as Chief Technology Officer',
            text_cn: '비플라이소프트, 최고기술경영자로 고려대 강필성 교수 선임',
            date: '2020.01.08',
        },
        {
            img: '/images/profile/newsPr/update10.png',
            url: 'https://blog.naver.com/rozeus8/221746373657',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '로제우스, "글로벌 블록체인 리더 한국 서밋" 참석',
            text_eng: 'Rozeus gives a speech at "Global Blockchain Leader Korea Summit" ',
            text_cn: '로제우스, "글로벌 블록체인 리더 한국 서밋" 참석',
            date: '2019.12.23',
        },
        {
            img: '/images/profile/newsPr/update11.png',
            url: 'https://blog.naver.com/rozeus8/221746391820',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '[해시넷 페스티벌] 가짜뉴스 이렇게 해결한다…블록체인 플랫폼 로제우스',
            text_eng: '[Hashnet Festival] Fake News Solution Blockchain Platform Rozeus',
            text_cn: '[해시넷 페스티벌] 가짜뉴스 이렇게 해결한다…블록체인 플랫폼 로제우스',
            date: '2019.12.19',
        },
        {
            img: '/images/profile/newsPr/update05.png',
            url: 'https://blog.naver.com/rozeus8/221726648183',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '가짜뉴스 꼼짝마…AI가 팩트 체크',
            text_eng: 'Fight Fake News with AI',
            text_cn: '가짜뉴스 꼼짝마…AI가 팩트 체크',
            date: '2019.12.04',
        },
        // {
        //     img: '/images/profile/newsPr/update09.png',
        //     url: 'https://blog.naver.com/rozeus8/221769991671',
        //     category: 'Naver Blog',
        //     size: {
        //         width: '',
        //         height: ''
        //     },
        //     text: '코인니스, 디지털 자산 거래소 전략 세미나 16일 개최',
        //     text_eng: 'Coinness Held Digital Asset Exchange Strategy Seminar ',
        //     text_cn: '코인니스, 디지털 자산 거래소 전략 세미나 16일 개최',
        //     date: '2019.12.04',
        // },
        {
            img: '/images/profile/newsPr/update08.png',
            url: 'https://blog.naver.com/rozeus8/221653712499',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '세상을 이롭게 할 블록체인, 기술로 세상을 바꾼다',
            text_eng: 'World benefiting blockchain technology will change the world',
            text_cn: '세상을 이롭게 할 블록체인, 기술로 세상을 바꾼다',
            date: '2019.06.17',
        },
        {
            img: '/images/profile/newsPr/update07.png',
            url: 'https://blog.naver.com/rozeus8/221570979870',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '뉴스 직거래 수익 구조 실현되나..."로제우스 프로젝트" 가시화',
            text_eng: 'World benefiting blockchain technology will change the world',
            text_cn: '뉴스 직거래 수익 구조 실현되나..."로제우스 프로젝트" 가시화',
            date: '2019.06.24',
        },
        {
            img: '/images/profile/newsPr/update06.png',
            url: 'https://blog.naver.com/rozeus8/221764278396',
            category: 'Naver Blog',
            size: {
                width: '',
                height: ''
            },
            text: '공정∙상생의 뉴스 미디어 오픈 플랫폼 만든다',
            text_eng: 'An open platform for credible and righteous news media',
            text_cn: '공정∙상생의 뉴스 미디어 오픈 플랫폼 만든다',
            date: '2019.06.04',
        },
        {
            img: '/images/profile/newsPr/update04.png',
            url: 'https://medium.com/rozeus/prologue-bringing-back-the-credibility-245024c812a5',
            category: 'Medium',
            size: {
                width: '',
                height: ''
            },
            text: '프롤로그 : 신뢰를 되찾다',
            text_eng: 'Prologue: bringing back the credibility',
            text_cn: '프롤로그 : 신뢰를 되찾다',
            date: '2019.03.13',
        },
        {
            img: '/images/profile/newsPr/update00.png',
            url: 'https://medium.com/rozeus/rozeus-partners-with-namseoul-university-93d4765e8d6a',
            category: 'Medium',
            size: {
                width: '',
                height: ''
            },
            text: '로제우스-남서울대학교, 미디어 빅데이터 업무협약(MOU) 체결',
            text_eng: 'The Media Big Data Company, Rozeus, Partners With Namseoul University',
            text_cn: '로제우스-남서울대학교, 미디어 빅데이터 업무협약(MOU) 체결',
            date: '2019.03.13',
        },
        {
            img: '/images/profile/newsPr/update02.png',
            url: 'https://medium.com/rozeus/rozeus-partners-with-airbloc-a-blockchain-based-data-exchange-platform-7ddbe1bb7051',
            category: 'Medium',
            size: {
                width: '',
                height: ''
            },
            text: '빅데이터 전문기업 비플라이소프트 · 광고분석 전문기업 에이비일팔공 "업무협약(MOU) 체결"',
            text_eng: 'ROZEUS Partners With The Blockchain-Based User Data Exchange Platform',
            text_cn: '빅데이터 전문기업 비플라이소프트 · 광고분석 전문기업 에이비일팔공 "업무협약(MOU) 체결"',
            date: '2019.03.13',
        },
        {
            img: '/images/profile/newsPr/update01.png',
            url: 'https://medium.com/rozeus/rozeus-partners-with-blockchain-group-of-hanyang-university-9a212458ef6e',
            category: 'Medium',
            size: {
                width: '',
                height: ''
            },
            text: '로제우스-한양대학교 블록체인 연구원 업무협약(MOU) 체결',
            text_eng: 'ROZEUS Partners With Hanyang University\'s Blockchain R&E Group',
            text_cn: '로제우스-한양대학교 블록체인 연구원 업무협약(MOU) 체결',
            date: '2019.03.13',
        }
    ],
    NEWS: [
        {
            img: '/images/profile/newsPr/news16.png',
            date: '2020.02.27',
            title: '뉴스 직거래 수익 구조 실현되나... ‘로제우스 프로젝트’ 가시화',
            title_eng: 'A direct deal for news media revenue direct deal is realized… Rozeus Project ',
            title_cn: '뉴스 직거래 수익 구조 실현되나... ‘로제우스 프로젝트’ 가시화',
            linkList:[
                { name: 'Weibo', link:'https://m.weibo.cn/status/4476551293213657?'}
            ],
        },
        {
            img: '/images/profile/newsPr/news15.png',
            date: '2020.02.27',
            title: '공정·상생의 뉴스 미디어 오픈 플랫폼 만든다',
            title_eng: 'An open platform for credible and righteous news media',
            title_cn: '공정·상생의 뉴스 미디어 오픈 플랫폼 만든다',
            linkList: [
                { name: 'Weibo', link: 'https://card.weibo.com/article/m/show/id/2309404476548072734735?_wb_client_=1' }
            ],
        },
        {
            img: '/images/profile/newsPr/news14.png',
            date: '2020.02.14',
            title: 'Rozeus의 야심 : 블록체인 기술로 가짜뉴스가 없는 세상을 만든다',
            title_eng: 'Rozeus\' ambition : Creating a world without fake news by blockchain technology ',
            title_cn: 'Rozeus의 야심 : 블록체인 기술로 가짜뉴스가 없는 세상을 만든다',
            linkList: [
                { name: 'Weibo', link: 'https://card.weibo.com/article/m/show/id/2309404471861911093303?_wb_client_=1' }
            ],
        },
        {
            img: '/images/profile/newsPr/news13.png',
            date: '2020.01.23',
            title: '인터뷰 : 신문미디어와 인공지능이 결합한 블록체인',
            title_eng: 'Interview : Blockchain combining newspaper media and artificial intelligence',
            title_cn: '인터뷰 : 신문미디어와 인공지능이 결합한 블록체인',
            linkList: [
                { name: 'BiShiJie', link: 'https://www.bishijie.com/shendu/77198.html' },
                { name: 'Weibo', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404463848374534260#_0' }
            ],
        },
        {
            img: '/images/profile/newsPr/news12.png',
            date: '2020.01.16',
            title: '국가별 가짜뉴스 대응방안',
            title_eng: 'Countermeasures toward fake news by Country',
            title_cn: '국가별 가짜뉴스 대응방안',
            linkList: [
                { name: 'Weibo', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404461381167480887#_0' },
                { name: 'zhihu', link: 'https://zhuanlan.zhihu.com/p/103052685' },
                { name: 'BiShiJie', link: 'https://www.bishijie.com/shendu/75896.html?packetbit=android_bishijie_zh-cn&version=3.0.8&show_id=710655391&lang=zh-cn&app_name=android_bishijie&openid=710655391&channel=weixin' },
            ],
        },
        {
            img: '/images/profile/newsPr/news11.png',
            date: '2020.01.16',
            title: '가짜뉴스 남발, 블록체인기술은 어떻게 평정 할 것인가',
            title_eng: 'Overflowing fake news, how blockchain technology will subdue it',
            title_cn: '가짜뉴스 남발, 블록체인기술은 어떻게 평정 할 것인가',
            linkList: [
                { name: 'Weibo', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404461379858858057#_0' },
                { name: 'zhihu', link: 'https://zhuanlan.zhihu.com/p/103051731' },
                { name: 'BiShiJie', link: 'https://www.bishijie.com/shendu/75896.html?packetbit=android_bishijie_zh-cn&version=3.0.8&show_id=710655391&lang=zh-cn&app_name=android_bishijie&openid=710655391&channel=weixin' },
            ],
        },
        // {
        //     img: '/images/profile/newsPr/news10.png',
        //     date: '2020.01.13',
        //     title: '디지털 자산 거래소 전략 세미나 참석',
        //     title_eng: 'Joining Digital Asset Exchange Strategy Seminar',
        //     title_cn: '디지털 자산 거래소 전략 세미나 참석',
        //     linkList: [
        //         { name: 'Weibo', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404460209174085735#_0' }
        //     ],
        // },
        {
            img: '/images/profile/newsPr/news09.png',
            date: '2020.01.10',
            title: '블록체인+콘텐츠, 로제우스는 새로운 미디어의 문을 열수 있는가?',
            title_eng: 'Blockchain+Content, Can Rozeus open the door to new media',
            title_cn: '블록체인+콘텐츠, 로제우스는 새로운 미디어의 문을 열수 있는가?',
            linkList: [
                // { name: 'Weibo', link: 'https://www.tuoniaox.com/news/p-418575.html',check:'false' },
                { name: '深链财经', link: 'https://www.tuoniaox.com/news/p-418575.html' },
                { name: '火星财经', link: 'https://news.huoxing24.com/20200109172649508327.html' },
                { name: '猎云财经', link: 'https://www.lieyuncj.com/p/12713' },
                { name: '币快报', link: 'https://app2.beekuaibao.com/#/author/content/664883438380093440' },
                { name: '鸵鸟区块链', link: 'https://www.tuoniaox.com/news/p-418575.html' },
                // { name: '羊驼财经', link: 'https://yangtuobtc.com/z/xmbd/2020-01-09/2307.html', check: 'fail' },
                // { name: '零度财经', link: 'http://www.zerohello.com/archives/94494', check: '404' },
                { name: 'hubox', link: 'http://www.hubox.cn/information/news/171838' },
                { name: 'FN.com', link: 'https://www.fn.com/news/110536.html' },
                { name: '今日头条', link: 'https://www.toutiao.com/i6779889140243628548/' },
                { name: '网易号', link: 'http://dy.163.com/v2/article/detail/F2H7VRP60519TP33.html' },
                { name: '搜狐号', link: 'https://www.sohu.com/a/365966019_100124521' },
                { name: '雪球', link: 'https://xueqiu.com/9248323729/139078238' },
                { name: '币乎', link: 'https://bihu.com/article/1211559356' },
                { name: '百家号', link: 'https://mbd.baidu.com/newspage/data/landingshare?preview=1&pageType=1&isBdboxFrom=1&context=%7B%22nid%22%3A%22news_10007257569045429094%22%2C%22sourceFrom%22%3A%22bjh%22%7D'},
                { name: 'MyToken', link: 'https://m.mytokencap.com/news/121546?language=zh_CN&legal_currency=CNY' },
            ],
        },
        {
            img: '/images/profile/newsPr/news08.png',
            date: '2020.01.09',
            title: '비플라이소프트, 최고기술경영자로 고려대 강필성 교수 선임',
            title_eng: 'Bflysoft appoints Professor Pil-sung Kang of Korea University as its Chief Technology Officer',
            title_cn: '비플라이소프트, 최고기술경영자로 고려대 강필성 교수 선임',
            linkList: [
                // { name: 'Weibo', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404458762965483773#_0', check: 'no page' },
                { name: 'zhihu', link: 'https://zhuanlan.zhihu.com/p/101896808' },
            ],
        },
        {
            img: '/images/profile/newsPr/news07.png',
            date: '2020.01.06',
            title: '가짜뉴스를 걸러내는 미디어 프로젝트 “로제우스”',
            title_eng: 'Rozeus the media project filtering out fake news ',
            title_cn: '가짜뉴스를 걸러내는 미디어 프로젝트 “로제우스”',
            linkList: [
                { name: 'Weibo', link: 'https://m.weibo.cn/status/4457728573189873?' },
                { name: 'zhihu', link: 'https://zhuanlan.zhihu.com/p/97174068' },
            ],
        },
        {
            img: '/images/profile/newsPr/news06.png',
            date: '2019.12.20',
            title: '가짜뉴스 이렇게 해결한다 ... 블록체인 플랫폼 로제우스',
            title_eng: 'This is how to solve the fake news... Blockchain Platform Rozeus',
            title_cn: '가짜뉴스 이렇게 해결한다 ... 블록체인 플랫폼 로제우스',
            linkList: [
                { name: 'Weibo', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404451567167209474#_0' },
                { name: 'zhihu', link: 'https://zhuanlan.zhihu.com/p/98451559' },
            ],
        },
        // {
        //     img: '/images/profile/newsPr/news05.png',
        //     date: '2020.02.27',
        //     title: '부산블록체인써밋 2019',
        //     linkList: [
        //         { name: 'Weibo', link: 'https://m.weibo.cn/7199901989/4457726299692286', check: 'no page'  },
        //     ],
        // },
        {
            img: '/images/profile/newsPr/news04.png',
            date: '2020.02.27',
            title: '가짜뉴스, AI & 블록체인 기반 미디어 뉴스 플랫폼이 대안',
            title_eng: 'Against fake news, AI and blockchain-based media news platform as alternatives',
            title_cn: '가짜뉴스, AI & 블록체인 기반 미디어 뉴스 플랫폼이 대안',
            linkList: [
                { name: 'ycwb', link: 'http://3c.ycwb.com/2019-11/21/content_30391990.htm' },
                { name: 'eNet', link: 'http://www.enet.com.cn/article/2019/1121/A201911211046452.html' },
                // { name: 'Baidu', link: 'https://baijiahao.baidu.com/s?id=1650775263618291984', check: 'no page' },
                { name: 'Sina', link: 'https://t.cj.sina.com.cn/articles/view/7065663932/1a52579bc00100kv7m?from=tech&subch=otech' },
                // { name: 'Sohu', link: 'https://www.sohu.com/a/355105122_120321513', check: '404' },
                // { name: 'china.com', link: 'https://tech.china.com/article/20191121/kejiyuan0129414175.html', check: '404'  },
                // { name: 'dzwww', link: 'http://dzwww.com/bj/ywsd/201911/t20191121_17182530.htm', check: '404'   },
                // { name: 'ZOL', link: 'http://xa.zol.com.cn/html/netfixing/office/xw/31/311567.shtml', check: 'no page' },
                { name: 'IT168', link: 'http://software.it168.com/a2019/1120/6092/000006092039.shtml' },
                // { name: 'lnd.com.cn', link: 'http://qiye.lnd.com.cn/chuangxin/141344.html?1574242012', check: '404' },
                { name: 'cqcb', link: 'https://www.cqcb.com/dyh/live/dyh5113/2019-11-22/1993102_pc.html' },
                { name: 'BusinessNews', link: 'http://www.businessnews.cn/roll/2019/11/22/1551.html' },
                { name: '163.com', link: 'http://hlj.news.163.com/heihe/19/1122/09/EUJ1B9KB04239D40.html' },
                // { name: 'ChinaIRN', link: 'http://www.chinairn.com/news/20191122/105246667.shtml', check: 'no page' },
                { name: 'YNET', link: 'http://tech.ynet.com/2019/11/22/2219833t3264.html' },
                { name: 'hexun', link: 'https://shandong.hexun.com/2019-11-22/199376929.html' },
                // { name: 'PChome', link: 'https://article.pchome.net/content-2104427.html', check: '404' },
                // { name: 'iFeng', link: 'http://hunan.ifeng.com/a/20191122/7825095_0.shtml', check: 'no page' },
                { name: 'Ccidnet', link: 'http://www.ccidnet.com/2019/1122/10500014.shtml' },
                { name: 'yidianzixun', link: 'https://www.yidianzixun.com/article/0NrkIdOq' },
                { name: 'EEPW', link: 'http://www.eepw.com.cn/article/201911/407392.htm' },
                { name: 'isouthtech', link: 'http://www.isouthtech.com/v-1-399995.aspx?WebShieldDRSessionVerify=P60vrbYRoCJ8vSVi7L6l&WebShieldSessionVerify=TTcXDwkEb2vq0owq90df' },
                { name: 'ZAKER', link: 'http://www.myzaker.com/article/5dd7aac68e9f092b7875f2dd/' },
                { name: 'DOIT', link: 'https://www.doit.com.cn/p/348268.html' },
                { name: 'Toutiao', link: 'https://www.toutiao.com/i6762288659757531661/' },
                // { name: 'szonline', link: 'http://it.szonline.net/contents/20191125/20191144501.html', check: '404' },
                { name: 'ShandongNews', link: 'http://www.shandongnewss.com/n/2019/info1125_30305.html' },
                // { name: 'Xinhuanet', link: 'https://my-h5news.app.xinhuanet.com/h5/auditarticle.html?articleId=0c1fe2cb-af16-4e0b-8f17-34ff455fb79a', check: 'no page' },
                // { name: 'bandao.cn ', link: 'http://business.bandao.cn/news_html/201911/20191125/news_20191125_2888072.shtml', check: 'fail' }
            ],
        },
        // {
        //     img: '/images/profile/newsPr/news03.png',
        //     date: '2019.11.11',
        //     title: '로제우스가 변화시킬 블록체인 미디어',
        //     linkList: [
        //         { name: 'BiShiJie', link: 'https://i.bishijie.com/detail/662070', check: 'no page' },
        //     ],
        // },
        {
            img: '/images/profile/newsPr/news02.png',
            date: '2019.10.24',
            title: '로제우스 프로젝트 분석보고서',
            title_eng: 'Rozeus Project Analysis Report',
            title_cn: '로제우스 프로젝트 분석보고서',
            linkList: [
                { name: 'BiShiJie', link: 'https://i.bishijie.com/detail/628534' },
            ],
        },
        {
            img: '/images/profile/newsPr/news01.png',
            date: '2019.10.24',
            title: '로제우스는 고품질 콘텐츠 생산자',
            title_eng: 'Rozeus, a high-quality content producer',
            title_cn: '로제우스는 고품질 콘텐츠 생산자',
            linkList: [
                { name: 'BiShiJie', link: 'https://i.bishijie.com/detail/628114' },
            ],
        },
        {
            img: '/images/profile/newsPr/news00.png',
            date: '2019.10.23',
            title: '로제우스 deep chain 속보 모아보기',
            title_eng: 'Rozeus Deep Chain breaking news ',
            title_cn: '로제우스 deep chain 속보 모아보기',
            linkList: [
                { name: '深链财经', link: 'https://zhuanlan.zhihu.com/p/106065009' },
            ],
        },
        {
            img: '/images/profile/newsPr/news000.png',
            date: '2019.10.23',
            title: '로제우스 BiShiJie 속보 모아보기',
            title_eng: 'Rozeus Bi Shi Jie breaking news ',
            title_cn: '로제우스 BiShiJie 속보 모아보기',
            linkList: [
                { name: 'BiShiJie', link: 'https://card.weibo.com/article/m/show/id/2309404470415484387385?_wb_client_=1' },
            ],
        }
    ],
    EVENT: [
        {
            img: '/images/profile/newsPr/event05.png',
            date: '2019.12.19',
            title:'해시넷 블록체인 페스티벌',
            title_eng:'Hashnet Blockchain Festival',
            title_cn:'해시넷 블록체인 페스티벌',
            text: '과학기술정부통신부와 대통령직속 4차산업혁명위원회가 공동 주최하고, 지디넷코리아가 주관하는 ‘대한민국 4차 산업혁명 페스티벌 2020’의 다양한 행사 중 블록체인 분야에 특화된 행사',
            text_eng:'Specified event for blockchain field among the various events of \'The Fourth Industrial Revolution Festival 2020\'\, co-hosted by the Ministry of Science and ICT and the ...',
            text_cn:'과학기술정부통신부와 대통령직속 4차산업혁명위원회가 공동 주최하고, 지디넷코리아가 주관하는 ‘대한민국 4차 산업혁명 페스티벌 2020’의 다양한 행사 중 블록체인 분야에 특화된 행사',
            link: [
                { text: '한국블록체인뉴스 →', text_eng: 'HKBnews →', text_cn: ' →',  link: 'https://www.hkbnews.com/news/articleView.html?idxno=4974#_enliple' }, 
                { text: '코인리더스 →', text_eng: 'Coinreaders →', text_cn: ' →', link: 'http://coinreaders.com/5992' }
            ],
            blogLink: [
                { text: 'ROZEUS BLOG (한국어) →', text_eng: 'ROZEUS BLOG(KOREAN) →', text_cn: ' →', link: 'https://blog.naver.com/rozeus8/221746391820' }, 
                { text: 'ROZEUS BLOG (简体中文) →', text_eng: 'ROZEUS BLOG(简体中文) →', text_cn: 'ROZEUS BLOG(简体中文) →', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404451567167209474#_0' }
            ],
        },
        {
            img: '/images/profile/newsPr/event04.png',
            date: '2019.12.17',
            title: '글로벌 블록체인 리더 한국 서밋',
            title_eng:'Global Blockchain Leaders Korea Summit',
            title_cn: '글로벌 블록체인 리더 한국 서밋',
            text: '전 세계 20개 국가·지역의 블록체인 업계 리더 1,000명, 업계 주요인사 3,000명 등이 참석한 서밋',
            text_eng:'Summit attended by 1,000 blockchain industry leaders and 3,000 industry leaders from 20 countries and regions across the world.',
            text_cn:'전 세계 20개 국가·지역의 블록체인 업계 리더 1,000명, 업계 주요인사 3,000명 등이 참석한 서밋',
            link: [
                { text: '전자신문 →', text_eng: 'Etnews →', text_cn: ' →',  link: 'http://www.etnews.com/20191212000289' }, 
                { text: '아시아경제 →', text_eng: 'Asia Business Daily →', text_cn: ' →', link: 'https://view.asiae.co.kr/article/2019121310163904442' }, 
                { text: '아주경제 →', text_eng: 'Asia Business Daily →', text_cn: '', link: 'https://www.ajunews.com/view/20191213163909327' }],
            blogLink: [
                { text: 'ROZEUS BLOG (한국어) →', text_eng: 'ROZEUS BLOG(KOREAN) →', text_cn: ' →', link: 'https://blog.naver.com/rozeus8/221746373657' }, 
                { text: 'ROZEUS BLOG (简体中文) →', text_eng: 'ROZEUS BLOG(简体中文) →', text_cn: ' →', link: 'https://www.weibo.com/ttarticle/p/show?id=2309404448677174312997#_0' }
            ],
        },
        // {
        //     img: '/images/profile/newsPr/event03.png',
        //     date: '2019.12.16',
        //     title: '디지털 자산 거래소 전략 세미나',
        //     title_eng:'Digital Asset Exchange Strategy Seminar',
        //     title_cn:'디지털 자산 거래소 전략 세미나',
        //     text: '전세계를 무대로 활동하는 아시아 암호화폐 거래소들이 한데 모여 올해를 회고하고 내년 시장을 전망하는 세미나',
        //     text_eng:'A seminar in which Asian Cryptocurrency Exchanges working  across the world gathering together to review this year and to project the next year',
        //     text_cn:'전세계를 무대로 활동하는 아시아 암호화폐 거래소들이 한데 모여 올해를 회고하고 내년 시장을 전망하는 세미나',
        //     link: [
        //         { text: '매일신문 →', text_eng: 'Daily Newspaper →', text_cn: ' →', link: 'https://www.mk.co.kr/news/economy/view/2019/12/1015243/?a=1' }, 
        //         { text: '매일신문 → ', text_eng: 'Daily Newspaper →', text_cn: ' →', link: 'https://www.mk.co.kr/news/economy/view/2019/12/1053986/' }
        //     ],
        //     blogLink: [
        //         { text: 'ROZEUS BLOG (한국어) →', text_eng: 'ROZEUS BLOG(KOREAN) →', text_cn: ' →', link: 'https://blog.naver.com/rozeus8/221769991671' }, 
        //         { text: 'ROZEUS BLOG (简体中文) →', text_eng: 'ROZEUS BLOG(简体中文) →', text_cn: ' →', link: 'https://m.weibo.cn/7199901989/4460209173091749' }
        //     ],
        // },
        {
            img: '/images/profile/newsPr/event02.png',
            date: '2019.11.27',
            title: '부산 블록체인 컨퍼런스 2019',
            title_eng:'Busan Blockchain Conference 2019',
            title_cn:'부산 블록체인 컨퍼런스 2019',
            text: '블록체인특구 지정 이후 처음으로 블록체인 컨퍼런스, 금융·핀테크 특별전시, VIP파티 등을 포함한 컨퍼런스',
            text_eng:'The conference of a blockchain conference, a special exhibition of financial/fintech, VIP parties, etc., as the first event since the designation of the blockchain special zone.',
            text_cn:'블록체인특구 지정 이후 처음으로 블록체인 컨퍼런스, 금융·핀테크 특별전시, VIP파티 등을 포함한 컨퍼런스',
            link: [
                { text: '한국경제 →', text_eng: 'The Korea Economic Daily →', text_cn: ' →', link: 'https://www.hankyung.com/it/article/201911274953v' }, 
                { text: '한경닷컴 →', text_eng: 'The Korea Economic Daily →', text_cn: ' →', link: 'http://gametoc.hankyung.com/news/articleView.html?idxno=53458' }, 
                { text: '아웃소싱타임스 →', text_eng: 'The Outsourcing times →', text_cn: ' →', link: 'http://www.outsourcing.co.kr/news/articleView.html?idxno=86406' }
            ],
            blogLink: [
                { text: 'ROZEUS BLOG (한국어) →', text_eng: 'ROZEUS BLOG(KOREAN) →', text_cn: ' →', link: 'https://blog.naver.com/rozeus8/221721162824' }, 
                { text: 'ROZEUS BLOG (简体中文) →', text_eng: 'ROZEUS BLOG(简体中文) →', text_cn: ' →', link: 'https://www.weibo.com/u/7199901989?refer_flag=1005055010_&is_all=1' }
            ],
        },
        {
            img: '/images/profile/newsPr/event01.png',
            date: '2019.09.10',
            title: '한중 블록체인 미래 전망 포럼',
            title_eng:'Korea-China Blockchain Future Outlook Forum',
            title_cn:'한중 블록체인 미래 전망 포럼',
            text: '한국과 중국의 블록체인 프로젝트를 소개하고 토론하는 한중 교류 포럼',
            text_eng:'Korea-China Exchange Forum to introduce and discuss on mutual blockchain projects in Korea and China',
            text_cn:'한국과 중국의 블록체인 프로젝트를 소개하고 토론하는 한중 교류 포럼',
            link: [
                { text: '공유경제신문 →', text_eng: 'Seconomy →', text_cn: ' →', link: 'http://cnews.seconomy.kr/view.php?ud=2019091712152811726cf2d78c68_2' }, 
                { text: '공감신문 →', text_eng: 'Gokorea →', text_cn: ' →', link: 'http://www.gokorea.kr/news/articleView.html?idxno=410600' }, 
                { text: '아주경제 →', text_eng: 'Aju Business Daily →', text_cn: ' →', link: 'https://www.ajunews.com/view/20190910160415935' }],
            blogLink: [
                { text: 'ROZEUS BLOG (한국어) →', text_eng: 'ROZEUS BLOG(KOREAN) →', text_cn: ' →', link: 'https://blog.naver.com/rozeus8/221653712499' }, 
                { text: 'ROZEUS BLOG (简体中文) →', text_eng: 'ROZEUS BLOG (简体中文) →', text_cn: ' →', link: 'https://www.weibo.com/u/7199901989?refer_flag=1005055010_&is_all=1' }
            ],
        },
    ]
}
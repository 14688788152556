import React, { useEffect, useLayoutEffect, useState } from 'react';
import { m_size } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import Branch from './Branch';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },
    centerLine :{
        position: 'absolute', 
        left: 21, 
        top: 12, 
        width: '1px', 
        borderLeft: '1px solid ' + theme.palette.roadmapPrimary
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();
    const [sizeShow, setSizeShow] = useState(true);
    
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    useLayoutEffect(() => {
        function updateSize() {
            window.innerWidth > 390 ? setSizeShow(true) : setSizeShow(false);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div className={classes.centerLine} style={{ height:'calc(100% - 50px)'}} />
                <Branch
                    position='right'
                    top={29}
                    width={300}
                    title='2020.1Q'
                    desc={[
                        '- ROZEUS BackEnd System V1.0']}
                    // desc={['- ROZ Wallet Beta',
                    //        '- ROZEUS BackEnd System V1.0']}
                    // img={<img
                    // src={'/images/progress/roadmap/ic_roadmap_20201q_' + layout.mode + '.png'}
                    // alt='2020.1Q'
                    // style={{ width: '43.36px', height: '56.58px' }}
                    // />}
                    // imgTop={0}
                    // imgWidth={0}
                    // imgPad={275}
                    />
                <div style={{height:'50px'}} />
                <Branch
                    position='right'
                    top={129}
                    width={320}
                    title='2020.2Q'
                    desc={['ROZEUS MVP Release']}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={249}
                    width={320}
                    title='2020.3Q'
                    desc={['- ROZEUS SERVICE BETA OPEN'
                         , '- ROZEUS BackEnd System V1.5']}
                    img={<img
                    src={'/images/progress/roadmap/small/ic_roadmap_20203q_' + layout.mode + '.png'}
                    alt='2020.3Q'
                    style={{ display: 'block', width: '74px', height:'92px' }}
                    />}
                    imgTop={-60}
                    imgWidth={84.01}
                    imgPad={250}
                />
                <div style={{ height: '60px' }} />
                <Branch
                    position='right'
                    top={369}
                    width={320}
                    title='2020.4Q'
                    desc={['ROZEUS SERVICE BETA UPDATES'
                         , 'ROZEUS BackEnd System V2.0']}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={400}
                    width={150}
                    title='2021.1Q'
                    desc={['- ROZEUS BETA SERVICE UPDATES'
                         , '- ROZEUS Alpha SERVICE Development']}
                    imgTop={0}
                />
                <div style={{ height: '75px' }} />
                <Branch
                    position='right'
                    top={446}
                    width={275}
                    title='2021.2Q'
                    desc={['ROZEUS Alpha SERVICE Development']}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={510}
                    width={150}
                    title='2021.3Q'
                    desc={['ROZEUS Alpha SERVICE Open',
                           'ROZEUS Platform Engine and Module Development']}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={702}
                    width={331}
                    title='2021.4Q'
                    desc={['- ROZEUS Platform Engine and Module Development',
                           '- ROZEUS Block Chain TestNet Open',
                        //    '- ROZ Wallet Alpha ver. release',
                           '- Activate Reward engine and module']}
                    img={<img
                    src={'/images/progress/roadmap/ic_roadmap_20214q_' + layout.mode + '.png'}
                    alt='2021.4Q'
                    id='20214Q'
                    style={{ width: '40px', height: '44px' }}
                    />}
                    imgTop={-15}
                    imgWidth={-10}
                    imgPad={170}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={835}
                    width={48.5}
                    title='2022.1Q'
                    desc={['- Rozeus platform launching']}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={1206}
                    width={397}
                    title='2022.2Q'
                    desc={['ROZEUS GLOBAL Setup (for East Asia)']}
                    imgTop={0}
                />
                <div style={{ height: '80px' }} />
                <Branch
                    position='right'
                    top={1339}
                    width={125.5}
                    title='2022.4Q'
                    desc={['ROZEUS GLOBAL open(for East Asia)']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20224q_' + layout.mode + '.png'}
                        alt='2022.4Q'
                        style={{ width: '96px', height: '80.8px' }}
                    />}
                    imgTop={-70}
                    imgWidth={100}
                    imgPad={230}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={1528}
                    width={168.5}
                    title='2023.1Q'
                    desc={['ROZEUS GLOBAL open (for eng.)']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20231q_' + layout.mode + '.png'}
                        alt='20231Q'
                        id='20231Q'
                        style={{ width: '49px', height: '89.18px' }}
                    />}
                    imgTop={-25}
                    imgWidth={-10}
                    imgPad={255}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={1850}
                    width={168.5}
                    title='2023.2Q'
                    desc={['- Rozeus service engine system advancement']}
                />
                <div style={{ height: '50px' }} />
                <Branch
                    position='right'
                    top={1850}
                    width={168.5}
                    title='2023.4Q'
                    desc={['- Rozeus Blockchain Mainnet Open',
                           '-  Rozeus fact-chain launching']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20234q_' + layout.mode + '.png'}
                        alt='2023.4Q'
                        style={{ width: '68px', height: '64.26px' }}
                    />}
                    imgTop={-5}
                    imgWidth={75}
                    imgPad={250}
                />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
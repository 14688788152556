import React from 'react';
import { newsPr_content } from '../../../common/constants'

import UpdateCard from './UpdateCard';



const Update = () => {

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {newsPr_content.UPDATE &&
                newsPr_content.UPDATE.map((item, index) => {
                    return <UpdateCard key={index} target={item} />
                })
            }
            {
                <div style={{width:'32%'}} />
            }
        </div>
    );
};

export default Update;
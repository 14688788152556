import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
    body: {
        width: '215px',
        height: '334px',
        backgroundSize: '215px 334px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/images/profile/rozeus/card_rozeus_chap3.png)',
        color: '#ffffff',
        transition: "all 0.3s ease-in",
        MozTransition: "all 0.3s ease-in",
        WebkitTransition: "all 0.3s ease-in",
        msTransition: "all 0.3s ease-in",
        OTransition: "all 0.3s ease-in",
        cursor: 'pointer',
        "&:hover": {
            backgroundImage: props => 'url(/images/profile/rozeus/card_rozeus_chap3_' + props.mode + '.png)',
            color: theme.palette.text.sub.sub3
        },
    },
    fontColor:{

    },
    descFont:{
        fontFamily: 's-core_dream4_regular',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '20px',
        color:'inherit'
    }
}));

const Chapter3Card = ({ layout, title, desc, handleClick }) => {

    const classes = useStyles(layout);

    return (
        <div className={classes.body} onClick={handleClick}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'140px'}}>
                {title &&
                title.map((item, index)=>{
                    return <Typography key={index} variant='h2' className={classes.fontColor}>{item}</Typography>
                })}
            </div>
            <div style={{height:'1px'}} />
            <div style={{ display: 'flex', width:'175px', justifyContent: 'center', alignItems: 'center', margin:'0px 20px', height: '143px' }}>
                <Typography className={classes.descFont} style={{textAlign:'center'}}>{desc}</Typography>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3Card));
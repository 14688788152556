import React, { useEffect } from 'react';
import { size } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import Branch from './Branch';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'1939px',
        margin: 'auto',
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '1939px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    centerLine :{
        position: 'absolute', 
        left: 'calc(50% - 1.5px)', 
        top: '12px', 
        width: '3px', 
        height: '1653px', 
        borderLeft: '3px solid ' + theme.palette.roadmapPrimary
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });


    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div className={classes.centerLine}/>
            
                <Branch
                    position='right'
                    top={100}
                    width={70.36}
                    title='2020.1Q'
                    // desc={['- ROZ Wallet Beta',
                    //        '- ROZEUS BackEnd System V1.0']}
                    desc={[
                        '- ROZEUS BackEnd System V1.0']}
                    // img={<img
                    //     src={'/images/progress/roadmap/ic_roadmap_20201q_' + layout.mode + '.png'}
                    //     alt='2020.1Q'
                    //     style={{ width: '77px', height: '101.23px' }}
                    // />}
                    // imgTop={-50}
                    // imgWidth={0}
                    // imgPad={300}
                />
                <Branch
                    position='left'
                    top={220}
                    width={370}
                    title='2020.2Q'
                    desc={['ROZEUS MVP Release']}
                />
                <Branch
                    position='right'
                    top={340}
                    width={84.54}
                    title='2020.3Q'
                    desc={['-  ROZEUS SERVICE BETA OPEN'
                         , '- ROZEUS BackEnd System V1.5']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20203q_' + layout.mode + '.png'}
                        alt='2020.3Q'
                        style={{ width: '168px', height: '189px' }}
                    />}
                    imgTop={-80}
                    imgWidth={0}
                    imgPad={300}
                />
                <Branch
                    position='left'
                    top={460}
                    width={299.81}
                    title='2020.4Q'
                    desc={[' ROZEUS SERVICE BETA UPDATES'
                         , 'ROZEUS BackEnd System V2.0']}
                />
                <Branch
                    position='right'
                    top={580}
                    width={73.59}
                    title='2021.1Q'
                    desc={['-  ROZEUS BETA SERVICE UPDATES'
                         , '- ROZEUS Alpha SERVICE Development']}
                    imgTop={0}
                />
                <Branch
                    position='left'
                    top={700}
                    width={371.96}
                    title='2021.2Q'
                    desc={['ROZEUS Alpha SERVICE Development']}
                />
                <Branch
                    position='right'
                    top={820}
                    width={152.42}
                    title='2021.3Q'
                    desc={['ROZEUS Alpha SERVICE Open',
                           'ROZEUS Platform Engine and Module Development']}
                />
                <Branch
                    position='left'
                    top={940}
                    width={220.89}
                    title='2021.4Q'
                    desc={['- Activate AD engine and module system',
                           '- ROZEUS Block Chain TestNet Open',
                        //    '- ROZ Wallet Alpha ver. release',
                           '- Activate Reward engine and module']}
                    img={<img
                    src={'/images/progress/roadmap/ic_roadmap_20214q_' + layout.mode + '.png'}
                    alt='2021.4Q'
                    id='20214Q'
                    style={{ width: '75px', height: '82px' }}
                    />}
                    imgTop={0}
                    imgWidth={-10}
                    imgPad={140}
                    id={1}
                />
                <Branch
                    position='right'
                    top={1060}
                    width={77.68}
                    title='2022.1Q'
                    desc={['- Rozeus platform launching']}
                    imgTop={0}
                />
                <Branch
                    position='left'
                    top={1180}
                    width={373.93}
                    title='2022.2Q'
                    desc={['ROZEUS GLOBAL Setup (for East Asia)']}
                    imgTop={0}
                />
                <Branch
                    position='right'
                    top={1300}
                    width={145.42}
                    title='2022.4Q'
                    desc={['ROZEUS GLOBAL open(for East Asia)']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20224q_' + layout.mode + '.png'}
                        alt='2022.4Q'
                        style={{ width: '199.73px', height: '168.1px' }}
                    />}
                    imgTop={-180}
                    imgWidth={100}
                    imgPad={200}
                />
                <Branch
                    position='left'
                    top={1420}
                    width={255.03}
                    title='2023.1Q'
                    desc={['ROZEUS GLOBAL open (for eng.)']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20231q_' + layout.mode + '.png'}
                        alt='20231Q'
                        id='20231Q'
                        style={{ width: '86px', height: '166.72px' }}
                    />}
                    imgTop={-110}
                    imgWidth={-10}
                    imgPad={140}
                    id={2}
                />
                <Branch
                    position='right'
                    top={1540}
                    width={126.84}
                    title='2023.2Q'
                    desc={['- Rozeus service engine system advancement']}
                    imgTop={0}
                />
                <Branch
                    position='left'
                    top={1680}
                    width={297.69}
                    title='2023.4Q'
                    desc={['- Rozeus Blockchain Mainnet Open',
                           '-  Rozeus fact-chain launching']}
                    img={<img
                        src={'/images/progress/roadmap/ic_roadmap_20234q_' + layout.mode + '.png'}
                        alt='2023.4Q'
                        style={{ width: '106px', height: '100px' }}
                    />}
                    imgTop={-10}
                    imgWidth={75}
                    imgPad={85}
                    id={3}
                />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import { makeStyles, Popper, List, ListSubheader, ListItem, ListItemText, ListItemIcon, Typography  } from '@material-ui/core';
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';


const useStyles = makeStyles(theme => ({
    menuHover: {
        display: 'flex',
        alignItems: 'center',
        height: '58px',
        margin: '0px 4px',
        cursor: 'pointer',
        '&:hover': {
            marginTop: '2px',
            borderBottom: '2px solid',
            borderColor: theme.palette.menuUnderLine
        }
    },
    langSelector: {
        width: '150px',
        height: '200px',
        backgroundSize:'150px 200px',
        backgroundImage:'url(/images/header/small/ic_m_selector.png)',
        backgroundRepeat:'no-repeat',
        //display:'none'
    },
}));

const Language = ({ imgPath, alt, width, height, menu, layout}) => {

    const classes = useStyles();
    const [mode, setMode] = useState('');
    const [show, setShow] = useState();

    const handleMode = (mode) => {
        setMode(mode)
    }

    const handleClick = (event) => {
        menu.select('lang', event.currentTarget); 
         
    }
 
 
    return (
        <React.Fragment>
            
            <div className={classes.menuHover} style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
                <img 
                    src={'/images/header/small/ic_language_' + layout.mode + (menu.subMenuObj? '_choice': mode) + '.png'} 
                    alt={alt} 
                    style={{ display:'block', width: width, height: height, cursor: 'pointer'}} 
                    onClick={(e) => handleClick}
                    onMouseOver={(e) => handleMode('_over')}
                    onMouseOut={(e) => handleMode('')}
                    onMouseDown={(e) => handleMode('_down')}
                    onMouseUp={(e) => handleMode('')}
                    onTouchStart={(e) => handleMode('_down')}
                    onTouchEnd={(e) => handleMode('')}
                />
            </div>
            
            <Popper open={menu.target === 'lang' && menu.subMenuObj} anchorEl={menu.subMenuObj} placement='bottom-end' transition>
            <div className={classes.langSelector} id='selector'>
            <Typography variant='h5' className={classes.menuText} style={{fontWeight:'bold', color:'#1e1e1e', paddingTop:'15px', paddingLeft:'20px'}}> Language </Typography>   
                <List>                        
                        <ListItem  style={{ height: '46px', padding:'0px 20px' }} onClick={(e) => {layout.language = 'eng'; menu.deselect();}}>
                            <ListItemText primary={
                                <Typography variant='h5' className={classes.menuText} style={(layout.language === 'eng'? (layout.mode === 'light' ? {color:'#ED1C24'}: {color:'#8C6FF1'}): {color:'#1e1e1e'})}> English </Typography>
                            } />
                            <img  src={'/images/header/small/ic_'+ (layout.language === 'eng'? 'check' : 'uncheck') +'_language_'+layout.mode+'.png'} alt=''/>       
                        </ListItem>
                        <ListItem  style={{ height: '46px', padding:'0px 20px' }} onClick={(e) => {layout.language = 'kor'; menu.deselect();}}>
                            <ListItemText primary={
                                    <Typography variant='h5' className={classes.menuText} style={(layout.language === 'kor'? (layout.mode === 'light' ? {color:'#ED1C24'}: {color:'#8C6FF1'}): {color:'#1e1e1e'})}> 한국어 </Typography>
                                } />
                            <img src={'/images/header/small/ic_'+ (layout.language === 'kor'? 'check' : 'uncheck') +'_language_'+layout.mode+'.png'} alt=''/>   
                        </ListItem>
                        <ListItem  style={{ height: '46px', padding:'0px 20px' }} onClick={(e) => {layout.language = 'cn'; menu.deselect();}}>
                            <ListItemText primary={
                                <Typography variant='h5'  className={classes.menuText} style={(layout.language === 'cn'? (layout.mode === 'light' ? {color:'#ED1C24'}: {color:'#8C6FF1'}): {color:'#1e1e1e'})}> 体中文  </Typography>
                            } />
                            <img src={'/images/header/small/ic_'+ (layout.language === 'cn'? 'check' : 'uncheck' )+'_language_'+layout.mode+'.png'} alt=''/> 
                        </ListItem>
                    </List>
            </div>
            </Popper> 
        
        </React.Fragment>
    );
}


export default inject(({ menu, layout }) => ({
    menu: menu,
    layout: layout
}))(observer(Language));
import React, { useEffect , useLayoutEffect, useState } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    branchLeft: {
        position: 'absolute',
        right: 'calc(50% - 7px)',
        display: 'flex',
        justifyContent:'flex-end',
        alignItems: 'center',
        marginTop: '-18px',
    },
    branchRight: {
        position: 'absolute',
        left: 'calc(50% - 7px)',
        display: 'flex',
        alignItems: 'center',  
    },
    circleFull :{
        width:'14px',
        height:'14px',
        background: theme.palette.roadmapPrimary,
        borderRadius:'50%'
    },
    circle: {
        width: '14px',
        height: '14px',
        border:'1px solid '+theme.palette.roadmapPrimary,
        borderRadius: '50%'
    },
    divider:{
        background: theme.palette.roadmapPrimary, 
        marginTop: '-1px'
    }
}));

const Branch = ({ position, width, top, title, desc, img, imgTop, imgWidth, imgPad, toTop, toLeft, layout, id }) => {

    const classes = useStyles();
    const [st, setSt] = useState(0);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    useLayoutEffect(() => {
        function updateSize() {
            window.innerWidth > 1580 ? setSt() :
            setSt({position: 'absolute', bottom :0, left:(100+imgPad), border:'solid white'});
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    if(position === 'left'){
        return (

                <div className={classes.branchLeft} style={{ top:top }}>
                    <div
                        data-aos='fade-up'
                        data-aos-duration={aos_option.duration}
                        data-aos-offset={aos_option.offset}
                        style={{position:'absolute'
                              , top: window.innerWidth > 1580 ? (imgTop - 5) : id === 1? 0 : id === 2? -100 : -130 //(imgTop - 95)
                              , left: window.innerWidth > 1580 ? (imgWidth+imgPad)*-1: id === 1? -100 : id === 2? -120 : 0}}>
                            
                    {img}
                        <div style={{ width: imgPad }} />
                    </div>
                    <div style={{ marginTop: '-10px' }}>
                        <Typography variant='body2'>{title}</Typography>
                        <div style={{position:'absolute'}}>
                        {desc &&
                            desc.map((item, index) => {
                                return (
                                    item !== 'beta version release' ?
                                        <Typography key={index} variant='body1'>{item}</Typography>
                                        :
                                        <Typography key={index} variant='body1'>&nbsp;&nbsp;{item}</Typography>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ width: '10.5px' }} />
                    <div className={classes.circle} />
                    <Divider className={classes.divider} style={{ width: width }} />
                    <div className={classes.circleFull} />
                </div>
        );
    }else{
        return (
            <div className={classes.branchRight} style={{ top: top }}>
                <div style={{display:'flex',alignItems:'center',marginTop:'-20px'}}>
                    <div className={classes.circleFull} />
                    <Divider className={classes.divider} style={{ width: width }} />
                    <div className={classes.circle} />
                </div>
                <div style={{ width: '10.5px' }} />
                <div style={{ position: 'absolute', top: -28, left: (width + 38), width: size.minWidth / 2 - (width-100) }}>
                    <Typography variant='body2'>{title}</Typography>
                    {desc && 
                        desc.map((item, index)=>{
                            return (
                                    <Typography key={index} variant='body1'>{item}</Typography>
                            )
                        })}
                </div>
                <div style={{ width: imgPad}} />
                <div
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                    style={{position: 'absolute'
                          , top: window.innerWidth > 1580 ? (imgTop - 5) : ''
                          , bottom: window.innerWidth > 1580 ? '' : -5
                          , left: window.innerWidth > 1580 ? (width + 28)+ imgPad : (imgPad+imgWidth)}}>
                    {img}
                </div>
            </div>
        );
    }
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Branch));
import React, { useCallback, useEffect, useState } from 'react';
import { m_size, LIGHT, DARK } from '../../../common/constants'

import { AppBar, IconButton, makeStyles, Divider, Drawer, Collapse, } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import ICB from '../../common/ICB';
import Language from '../../../components/small/common/Language';
import Menu from './Menu';
import { throttle } from 'lodash';

const useStyles = makeStyles(theme => ({
    toolbar : {
        position: 'relative',
        width:'calc(100% - 32px)',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin:'0px 16px',
        height:'50px',
        display:'flex',
        alignItems:'center',
        
    },
    toolbarContent : {
        display: 'flex',
        justifyContent:'space-between',
        width: '100%',
        //minWidth: size.minWidth,
        //maxWidth: size.maxWidth,
    },
    logoArea : {
        position: 'absolute',
        top: 0,
        left:0,
        width: '100%',
        height: '50px',
        display: 'flex',
        justifyContent:'center',
        alignItems: 'center',
    },
    menuArea: {
        position: 'absolute',
        top:0,
        left: 0,
        width:'35px',
        height: '50px',
        display: 'flex',
        zIndex: 10,
    },
    iconArea: {
        position:'absolute',
        top: 0,
        right:0,
        height: '50px',
        display: 'flex',
        justifyContent:'flex-end',
        alignItems:'center',
        zIndex: 11,
    },
}));

const Header = ({layout, menu}) => {

    const classes = useStyles();

    const [eleVal, setEleVal] = useState(false);

    const handleBannerClick = event => {
        layout.setBanner(false);
    };

    const listener = useCallback(() => {
        if (window.scrollY || window.pageYOffset > 0) {
            setEleVal(true)
        } else {
            setEleVal(false)
        };
    }, []);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener);
        return () => {
            window.removeEventListener("scroll", throttleListener);
        };
    }, [listener]);

    const darkModeChange = () => {
        layout.mode === LIGHT ? layout.setMode(DARK) : layout.setMode(LIGHT)
    }

    const toggleDrawer = (val) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        menu.setSmallMenusShow(val);
    };

    

    return (
        <React.Fragment>
            <div id='back-to-top-anchor' />
            <Collapse in={layout.banner}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#F2F2F2',
                    }}
                    onClick={(e) => handleBannerClick(false)}
                >
                    {layout.language === 'kor' ?
                        <img src='/images/header/small/floating_ko.png' alt='Floating' style={{ width: '100%', height: '100%' }} />
                        :
                        <img src='/images/header/small/floating_en.png' alt='Floating' style={{ width: '100%', height: '100%' }} />
                    }
                </div>
            </Collapse>
            <AppBar position="sticky" elevation={0}>
                <div className={classes.toolbar}> 
                    <div className={classes.toolbarContent}>
                        <div className={classes.menuArea} onClick={toggleDrawer(true)}>
                            <IconButton color="inherit" style={{ padding: '0px' }} >
                                <img src={'/images/header/small/ic_m_menu_' + layout.mode + '.png'} alt='MENU' style={{ width: '18px', height: '17.27px' }} />
                            </IconButton>
                        </div>
                        <div className={classes.logoArea} onClick={(e) => window.location.href = '/'}>
                            <img src={'/images/common/web_logo_'+layout.mode+'.png'} alt='logo_rozeus' style={{width:'122px',height:'19.89px'}}/>
                        </div>
                        <div className={classes.iconArea}>
                            { layout.mode === 'light' ?
                                <ICB imgPath={'/images/header/ic_darkMode_' + layout.mode} alt='Language Change' width='22px' height='22px' handleClick={darkModeChange} />
                                :
                                <React.Fragment>
                                    <ICB imgPath={'/images/header/ic_darkMode_' + layout.mode} alt='Language Change' width='20px' height='20px' handleClick={darkModeChange} />
                                    <div style={{width:'2px'}} />
                                </React.Fragment>
                            }
                            <div style={{width:'15px'}} />
                            
                            <Language imgPath={'/images/header/ic_language_'+ layout.mode} alt='Language Change' width='20px' height='20px' />
                        </div> 
                   </div>
                </div>
                { eleVal && <Divider />}  
            </AppBar>
            <Drawer 
                anchor='left'
                open={menu.smallMenuShow}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Menu />
            </Drawer>
        </React.Fragment>
    );
}

export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu
}))(observer(Header));

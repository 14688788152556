import React, { useEffect, useLayoutEffect, useState } from 'react';

import { SMALL, LARGE, LIGHT, DARK } from '../common/constants'

import MainTemplate from '../templates/MainTemplate';
import FooterContainer from './FooterContainer';
import Header from '../components/common/Header';
import MHeader from '../components/small/common/Header';
import { Collapse, Hidden } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import AlphaPopNew from '../components/common/AlphaPopNew'

const MainContainer = ({ layout, menu }) => {
    const [banner, setBanner] = useState(true);
 

    const handleBannerClick = event => {
        setBanner(false);
    };
    const [show, setShow] = useState(true);

    useLayoutEffect(() => {
        // console.log("///",window.innerWidth);
        function updateSize() {
            const temp = layout.sizeMode;
            if(window.innerWidth < 600){
                temp !== SMALL && menu.deselect()
                layout.setSizeMode(SMALL);
            }else{
                temp !== LARGE && menu.deselect()
                layout.setSizeMode(LARGE);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    });

    return (
        <React.Fragment>
            <Hidden xsDown>
                <MainTemplate
                    header={<Header />}
                    footer ={<FooterContainer type='main'/>}
                    headerSize={0}
                />
            </Hidden>
            <Hidden smUp>
                <MainTemplate
                    header={<MHeader/>}
                    footer={<FooterContainer type='main' />}
                    headerSize={0}
                />
            </Hidden>
            <AlphaPopNew />
        </React.Fragment>
    );
};

export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu,
}))(observer(MainContainer));

import React, { useEffect } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Chapter3Card from './Chapter3Card';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '888px',
        margin: 'auto',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/images/profile/rozeus/back_rozeus_chap3.png)',
        backgroundColor: theme.palette.background.sub.chapBack,
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        height: '888px',
        margin: 'auto',
    },
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap} >
            <div className={classes.body}>
                <div style={{height:'150px'}} />
                {layout.language === 'kor'? 
                <div>
                    <Typography variant='h1' style={{color:'#ffffff'}}>로제우스를 만나보세요</Typography>
                    <div style={{height:'30px'}} />
                    {/**
                    <Typography variant='body1' style={{ color: '#ffffff' }}>로제우스 ROZ, ROZEUS WALLET, 그리고 ROZEUS SERVICE를 경험 할 수 있습니다.</Typography>
                     */}
                    <Typography variant='body1' style={{ color: '#ffffff' }}>로제우스와 관련된 BFLYSOFT, ROZEUS AI, 그리고 ROZEUS SERVICE를 경험 할 수 있습니다.</Typography>
                </div>
                : layout.language === 'eng'?
                <div>
                    <Typography variant='h1' style={{color:'#ffffff'}}>Experience Rozeus</Typography>
                    <div style={{height:'30px'}} /> 
                    {/**
                    <Typography variant='body1' style={{ color: '#ffffff' }}>You can experience Rozeus ROZ, Rozeus Wallet and Rozeus service.</Typography>
                     */}
                    <Typography variant='body1' style={{ color: '#ffffff' }}>Experience BFLYSOFT, ROZUES AI, and ROZEUS SERVICE.</Typography>
                </div>
                :
                <div>
                    <Typography variant='h1' style={{color:'#ffffff'}}>体验Rozeus</Typography>
                    <div style={{height:'30px'}} />
                    {/**
                    <Typography variant='body1' style={{ color: '#ffffff' }}>体验ROZEUS的ROZ、ROZEUS WALLET，以及ROZEUS服务吧！</Typography>
                     */}
                    <Typography variant='body1' style={{ color: '#ffffff' }}>立即体验与Rozeus相关的 BFLYSOFT、ROZEUS AI以及ROZEUS服务吧！</Typography>
                </div>                
                }
                <div style={{ height: '118px' }} />
                <div style={{ display:'flex', justifyContent:'space-between',width:'920px', height:'400px', margin:'auto' }}>
                    <Chapter3Card 
                        // title={['ROZ' ]}
                        title={layout.language === 'cn'? ['BFLYSOFT', '大数据']:['BFLYSOFT', 'BIG DATA']}
                        desc={layout.language === 'kor'? 
                            // '로제우스의 네이티브 가상자산입니다. 로제우스 서비스 및 로제우스 미디어 댑에서 사용 하거나 친구에게 자산을 전송할 수 있습니다.'
                            '트렌드인사이트, 사회이슈, 현상을 바라보는 전문가의 시각, 분야별 주요뉴스까지, PR/MKT인들이 알아두어야 할 다채로운 내용으로 채워져있습니다.'
                            : layout.language === 'eng'?
                            // 'ROZ is Rozeus\' native virtual currency. You can use it on Rozeus service and media dApp or transfer it to your friends.'
                            'Trend insights, social issues, professional opinions on phenomenons, and top news for PR professionals and marketers.'
                            :
                            // 'ROZEUS用户的虚拟资产。可以在ROZEUS服务与ROZEUS媒体dApp上使用，或转账给朋友。'
                            '趋势解读、社会热点、专家解读、各领域主要新闻等推广宣传与营销专业人士必需的多领域、高品质内容。'
                        }
                        // handleClick={(e)=> history.push('/profile/whatIsROZ')}
                        handleClick={(e) => window.open('https://blog.naver.com/PostList.nhn?blogId=bflysoft_biz&parentCategoryNo=20&skinType=&skinId=&from=menu&userSelectMenu=true')}
                    />
                    <Chapter3Card
                        // title={['ROZEUS', 'WALLET']}
                        title={layout.language === 'cn'?['ROZEUS', '人工智能']:['ROZEUS', 'AI']}
                        desc={layout.language === 'kor'? 
                             // '로제우스의 가상 자산 지갑입니다. 이더리움과 ROZ를 보관하고 전송할 수 있습니다.'
                            'AI 기술 브랜드 아래 자연어 처리 분야에서 차별화된 기술력을 보여드립니다.'
                            : layout.language === 'eng'?
                            // 'Rozeus wallet is Rozeus\' virtual asset property wallet. You can store and send Ethereum and ROZ.'
                            'Differentiated technological skills in natural language processing under Artificial Intelligence technology. '
                            :
                            // 'ROZEUS的虚拟资产钱包。可以保管并转账以太币与ROZ。'  
                            '基于人工智能技术的专业自然语言处理技术。'
                        }
                        // handleClick={(e) => window.open('https://play.google.com/store/apps/details?id=com.rwallet')}
                        handleClick={(e) => window.open('https://blog.naver.com/rozeus8')}
                    />
                    <Chapter3Card
                        title={['ROZEUS', 'SERVICE']}
                        desc={layout.language === 'kor'? 
                            '로제우스 첫 번째 미디어 dApp 서비스로, 사용자에게 맞춤 콘텐츠를 보여줍니다.'
                            : layout.language === 'eng'?
                            'Rozeus\' first media dApp service to show customized contents to users.'
                            :
                            '作为ROZEUS的首个媒体dApp服务，为用户提供个人订制内容。'
                        }
                        handleClick={(e) => window.open('https://www.rozeus.com')}
                    />
                    <Chapter3Card
                        title={['MORE']}
                        desc={layout.language === 'kor'? 
                            '로제우스 보도 자료'
                            : layout.language === 'eng'?
                            'Rozeus\' press release'
                            :
                            'ROZEUS 媒体报道资料'
                        }
                        handleClick={(e) => history.push('/profile/newsPr')}
                    />
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));
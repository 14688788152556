import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    numText: {
        position:'relative',
        width: '100%',
        margin: '0px 0px',
    },
}));

const NumberTitle = ({ number, text, top }) => {

    const classes = useStyles();

    return (
        <div style={{position:'relative', marginBottom:top}}>
            <Typography variant='h6'>{number}</Typography>
            <div className={classes.numText} style={{ top: top }}>
                {text.map((item, index) => {
                    return <Typography key={index} variant='h1'>{item}</Typography>
                })}
            </div>
        </div>
    );
};

export default NumberTitle;
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { size, aos_option } from '../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import axios from 'axios';

import CustomButton2 from '../../common/CustomButton2';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height:'1671px',
        margin: 'auto',
        backgroundColor: theme.palette.background.sub.subBackground,
    },
    body: {
        position: 'relative',
        width: '100%',
        height: '1671px',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    price:{
        width: '100%',
        minWidth: '920px',
        height:'160px',
        border:'2px solid '+theme.palette.subBox,
        background:theme.palette.background.default,
        borderRadius:'4px',
        padding:'32px',
        display:'flex',
        justifyContent:'space-between',
        alignItems: 'center',
    },
    provider:{
        fontFamily: 's-core_dream4_regular',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
    },
    chartBox: {
        width: '240px',
        height:'70px',
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();
    const history = useHistory();
    const [sizeShow, setSizeShow] = useState(true);

    const [rozData, setRozData] = useState({});
    
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    useEffect(() => {
        axios.get('http://rozeus.com:8080/RozAlarmPush/rozmarket.jsp')
            .then((response) => {
                setRozData(response.data.data[0])
            }).catch(err => {
                console.log(err)
            });
    },[]);


    useLayoutEffect(() => {
        function updateSize() {
            window.innerWidth > size.chageLineWidth ? setSizeShow(true) : setSizeShow(false);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);
    
    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={150}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'200px'}} />
                {layout.language === 'cn'? 
                    <Typography variant='h1'>ROZEUS 和 ROZ</Typography>
                :
                    <Typography variant='h1'>ROZEUS and ROZ</Typography>
                }
                <div style={{ height: '30px' }} />
                {layout.language === 'kor'? 
                    <React.Fragment>
                    <Typography variant='body1'>ROZEUS와 ROZ를 혼동 할 수 있습니다. ROZEUS는 사고 팔 수 없습니다.</Typography>
                    <Typography variant='body1'>로제우스는 블록체인 프로젝트이고 ROZ는 로제우스의 플랫폼의 대표 가상 자산입니다.</Typography>
                    <Typography variant='body1'>로제우스의 통화를 구매하는 것은 ROZ를 사는 것입니다.</Typography>
                    </React.Fragment>
                :layout.language === 'eng'? 
                    <React.Fragment>
                    <Typography variant='body1'>You can confuse Rozeus with ROZ. Rozeus cannot be bought and sold.</Typography>
                    <Typography variant='body1'>Rozeus is a block chain project ; meanwhile ROZ is the typical virtual asset of the Rozeus's platform.</Typography>
                    <Typography variant='body1'>Buying Rozeus's currency means buying ROZ.</Typography>
                    </React.Fragment>
                :
                    <React.Fragment>
                    <Typography variant='body1'>人们经常会混淆ROZEUS与ROZ。ROZEUS不能进行买卖。</Typography>
                    <Typography variant='body1'>ROZEUS是区块链项目，ROZS是ROZEUS平台的代表性虚拟资产。</Typography>
                    <Typography variant='body1'>购买ROZEUS货币，就是购买ROZ。</Typography>
                    </React.Fragment>
                }
                <div style={{ height: '12px' }} />
                
                {layout.language === 'kor'? 
                    <Typography variant='body1' style={{fontWeight:'900'}} onClick={(e) => history.push('/profile/whatIsRozeus')}>로제우스에 대해 여기서 더 알아보세요.<img src={'/images/common/ic_link_arrow_' + layout.mode + '.png'} alt='LINK' style={{ width: '8px', height: '13px', marginLeft: '5px' }} /></Typography>
                :layout.language === 'eng'? 
                    <Typography variant='body1' style={{fontWeight:'900'}} onClick={(e) => history.push('/profile/whatIsRozeus')}>You can find out more about Rozeus here.<img src={'/images/common/ic_link_arrow_' + layout.mode + '.png'} alt='LINK' style={{ width: '8px', height: '13px', marginLeft: '5px' }} /></Typography>
                :
                    <Typography variant='body1' style={{fontWeight:'900'}} onClick={(e) => history.push('/profile/whatIsRozeus')}>下面，我们将一起了解一下关于ROZEUS的内容。<img src={'/images/common/ic_link_arrow_' + layout.mode + '.png'} alt='LINK' style={{ width: '8px', height: '13px', marginLeft: '5px' }} /></Typography>
                }
                <div style={{ height: '120px' }} />
                <div
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                    className={classes.price}>
                    {sizeShow ? 
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img src={'/images/profile/roz/ic_roz_chap1_coin_'+layout.mode+'.png'} alt='COIN' style={{width:'17.94px',height:'20.03px'}} />
                            <div style={{width:'8.06px'}} />
                            {layout.language === 'kor'? 
                                <Typography variant='body1'>현재 ROZ PRICE (USD)</Typography>
                            :layout.language === 'eng'? 
                                <Typography variant='body1'>Current ROZ Price (USD)</Typography>
                            :
                                <Typography variant='body1'>现在的ROZ PRICE(USD)</Typography>
                            }
                            <div style={{ width: '98px' }} />
                            <Typography variant='h4'>
                                {rozData.last ? '$ ' + Math.floor(rozData.last * Math.pow(10, 4)) / Math.pow(10, 4) : '$ 0'}
                            </Typography>
                        </div>
                        :
                        <div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <img src={'/images/profile/roz/ic_roz_chap1_coin_' + layout.mode + '.png'} alt='COIN' style={{ width: '17.94px', height: '20.03px' }} />
                                <div style={{ width: '8.06px' }} />
                                {layout.language === 'kor'? 
                                    <Typography variant='body1'>현재 ROZ PRICE (USD)</Typography>
                                :layout.language === 'eng'? 
                                    <Typography variant='body1'>Current ROZ Price (USD)</Typography>
                                :
                                    <Typography variant='body1'>现在的ROZ PRICE(USD)</Typography>
                                 }                             
                            </div>
                            <Typography variant='h4'>{rozData.last ? '$ ' + Math.floor(rozData.last * Math.pow(10, 4)) / Math.pow(10, 4) : '$ 0'}</Typography>
                        </div>
                    }
                    <div style={{ flexGrow: 1 }} />
                    {rozData.change_rate < 0 ? 
                        <img src='/images/profile/roz/ic_roz_chap1_chart_down.png' alt='CHART DOWN' style={{ width: '120px', height: '37px' }} />
                        :
                        <img src='/images/profile/roz/ic_roz_chap1_chart_up.png' alt='CHART UP' style={{ width: '120px', height: '36px' }} />
                    }
                    <div style={{ width: '20px' }} />
                    <div style={{textAlign:'right'}}>
                        <Typography variant='body2' style={rozData.change_rate < 0 ? { color: '#ED1C24' } : { color: '#16940B'}}>
                            {rozData.change_rate ? Math.floor(rozData.change_rate * Math.pow(10, 2)) / Math.pow(10, 2) : 0}% {rozData.change_rate < 0 ? 'DOWN' : 'UP'} (Last 24 Hours)
                        </Typography>
                        {layout.language === 'kor'? 
                            <Typography className={classes.provider}>coinmarketcap.com 제공</Typography>
                        :layout.language === 'eng'? 
                            <Typography className={classes.provider}>Provided by coinmarketcap.com </Typography>
                        :
                            <Typography className={classes.provider}>coinmarketcap.com 提供</Typography>
                        }

                    </div>
                    <div style={{ width: '50px' }} />
                    <CustomButton2 text='Get ROZ' size={{ width: '140px', height: '44px' }} onclick={(e) => window.open('https://coinmarketcap.com/currencies/rozeus/')} /> 
                </div>
                <div style={{ height: '120px' }} />
                <div
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                >
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div className={classes.chartBox}>
                        {layout.language === 'kor'? 
                            <Typography variant='body2'>개방된 개인 소유권</Typography>
                        :layout.language === 'eng'?
                            <Typography variant='body2' style={{textAlign:'center'}}>Opened Personal <br/> Ownership</Typography> 
                        :
                        <   Typography variant='body2'>开放的个人所有权</Typography>
                        }
                        </div>

                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'?
                            <div>
                            <Typography variant='body1'>ROZ는 개인이 완전히 소유권을 가집니다. 소유권을 증명하기 위해서 제 3기관이나 사람이 필요하지 않습니다.</Typography>
                            <Typography variant='body1'>완전히 모두에게 개방되어 있습니다. 누구나 인터넷과 지갑만 있으면 은행이 필요 없습니다.</Typography>
                        </div>
                        : layout.language === 'eng'? 
                        <div>
                            <Typography variant='body1'>Individuals fully own ROZ. No third organization or a middleman is needed to prove the ownership of ROZ. </Typography>
                            <Typography variant='body1'>It is completely open to everyone. If you have Internet and a wallet, you don't need any bank. </Typography>
                        </div> 
                        :
                            <div>
                                <Typography variant='body1'>ROZ的所有权完全归属个人。不需要通过第三方或其他人来证明所有权。</Typography>
                                <Typography variant='body1'>完全向所有人开放。只要拥有网络与钱包，就无需再去银行了。</Typography>
                            </div>
                        }
                        
                    </div>
                    <div style={{ height: '40px' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.chartBox}>
                        {layout.language === 'kor'?
                            <Typography variant='body2'>안전을 위한 암호화</Typography>
                        : layout.language === 'eng'?    
                            <Typography variant='body2' style={{textAlign:'center'}}>Encryption for  <br/> safety</Typography>
                        :
                            <Typography variant='body2' >安全的加密</Typography>
                        }
                        </div>
                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'?
                            <div>
                                <Typography variant='body1'>가상 자산은 익숙하지 않을 수 있습니다. 하지만 검증되고 안전한 암호화로 보호됩니다.</Typography>
                                <Typography variant='body1'>ROZ를 보호하고 ROZ의 거래를 안전하게 지원합니다.</Typography>
                            </div>
                        : layout.language === 'eng'?
                            <div>
                                <Typography variant='body1'>You may be unfamiliar with virtual assets. However, it is protected by validated and safe encryption.</Typography>
                                <Typography variant='body1'>We support safe ROZ transactions and protect ROZ. </Typography>
                            </div> 
                        :
                            <div>
                                <Typography variant='body1'>您可能不太熟悉虚拟资产。但是，它是得到检验的，通过加密来得到安全保护。</Typography>
                                <Typography variant='body1'>保护ROZ，支持ROZ的安全交易。</Typography>
                            </div>
                        }

                    </div>

                    <div style={{ height: '40px' }} />
                    
                    <React.Fragment>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.chartBox} style={{height:'100px'}}>
                        {layout.language === 'kor'?
                            <Typography variant='body2'>개인간의 거래 실현</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body2' style={{textAlign:'center'}}>Accomplish<br/> Transactions <br/> between individuals</Typography>
                        :
                            <Typography variant='body2' >实现个人交易</Typography>
                        }
                        </div>
                        <div style={{ width: '24px' }} />
                        
                        {layout.language === 'kor'?
                        <div>
                            <Typography variant='body1'>은행과 같은 중개 서비스 없이 ROZ를 보낼 수 있습니다. 직접 현금으로 거래하는 것과 같습니다.</Typography>
                            <Typography variant='body1'>하지만 현금보다 안전하고 장소와 시간에 제약 없이 누구와도 안전하게 거래 할 수 있습니다.</Typography>
                        </div>
                        : layout.language === 'eng'?
                        <div>
                            <Typography variant='body1'>You can transmit ROZ without any brokerage services such as the bank. It's similar to cash transactions. </Typography>
                            <Typography variant='body1'>However, it is much safer than cash, and you can trade safely with anyone, anywhere, and anytime. </Typography>
                        </div>
                        :   
                        <div>
                            <Typography variant='body1'>无需银行的中介服务，就能够转账ROZ。与使用现金进行交易一样。</Typography>
                            <Typography variant='body1'>但是，比现金更加安全，且不受时间与地点的局限，可以与任何人进行安全的交易。</Typography>
                        </div>
                        }
                        
                    </div>
                    
                    <div style={{ height: '40px' }} />                    
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.chartBox}>
                        {layout.language === 'kor'?
                            <Typography variant='body2'>중앙 집중 제어 없음</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body2' style={{textAlign:'center'}}>No Centralized <br/> Control</Typography>
                        :
                            <Typography variant='body2' >无中央集中控制</Typography>
                        }
                        </div>
                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'?
                        <div>
                            <Typography variant='body1'>중앙 집중 기관이나 시스템이 없습니다.</Typography>
                            <Typography variant='body1'>이용 약관을 변경하거나 거래에 제약을 두는 회사나 은행이 없습니다.</Typography>
                        </div>
                        : layout.language === 'eng'?
                        <div>
                            <Typography variant='body1'>There is no centralized organization or system. </Typography>
                            <Typography variant='body1'>There are no companies or banks that change the policy and limit the number of transactions.</Typography>
                        </div>
                        :
                        <div>
                            <Typography variant='body1'>没有中央集中机关或系统。</Typography>
                            <Typography variant='body1'>没有可以变更使用规则与交易限制的公司与银行。</Typography>
                        </div>
                        }
                    </div>
                    <div style={{ height: '40px' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.chartBox}>
                        {layout.language === 'kor'?
                            <Typography variant='body2'>한정 발행 가치 보존</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body2' style={{textAlign:'center'}}>Value Retention through  <br/> limited issuance </Typography>
                        :
                            <Typography variant='body2' >发行数量有限，保证货币价值</Typography>
                        }
                        </div>

                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'?
                        <div>
                            <Typography variant='body1'>ROZ는 100억개를 한정하여 발행된 코인입니다. 가치가 확장 될 때 한정된 수량의 가상 자산으로 가치를 보존 합니다.</Typography>
                            <Typography variant='body1'>소수점 이하 9자리까지 1개의 ROZ를 분할하여 구매 할 수 있습니다.</Typography>
                         </div>
                        : layout.language === 'eng'?
                        <div>
                            <Typography variant='body1'>ROZ has a 10 billion coin limit issuance. Its value is retained with a limited number of virtual assets as its value increases. </Typography>
                            <Typography variant='body1'>ROZ can be split purchased by up to 9 decimal places. </Typography>
                        </div>
                        :
                        <div>
                        <Typography variant='body1'>ROZ是限量发行100亿个的货币。价值增加时，由于数量有限，虚拟资产价值将得到上升。</Typography>
                        <Typography variant='body1'>可以以小数点以下第9位数为单位，分开购买ROZ。</Typography>
                        </div>
                        }

                    </div>
                    <div style={{ height: '40px' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.chartBox}>
                        {layout.language === 'kor'?
                            <Typography variant='body2'>미디어 활동의 보상</Typography>
                            : layout.language === 'eng'?
                            <Typography variant='body2' style={{textAlign:'center'}}>Rewards For Media <br/> Activities</Typography>
                        :
                            <Typography variant='body2'>媒体活动的报酬</Typography>
                        }
                        </div>
                        <div style={{ width: '24px' }} />
                        {layout.language === 'kor'?
                        <div>
                            <Typography variant='body1'>로제우스는 미디어 활동을 ROZ로 보상합니다. 공평하고 투명한 가치 분배를 합니다.</Typography>
                            <Typography variant='body1'>전체 로제우스 네트워크에 긍정적인 영향을 미친 활동은 보상을 받습니다.</Typography>
                        </div>
                        : layout.language === 'eng'?
                        <div>
                            <Typography variant='body1'>ROZEUS rewards media activities with ROZ. We distribute fairly and transparently.</Typography>
                            <Typography variant='body1'>All activities that bring positive impacts to the entire Rozeus network are rewarded.</Typography>
                        </div>
                        :
                        <div>
                            <Typography variant='body1'>进行ROZEUS媒体活动，将得到ROZ作为报酬。进行公平、透明的价值分配。</Typography>
                            <Typography variant='body1'>所有对ROZEUS系统有积极作用的活动，都将得到酬劳。</Typography>
                        </div>
                        }
                    </div>
                    </React.Fragment>                    
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { themeType } from './common/constants';

import MainContainer from './containers/MainContainer';

import { largeLight, largeDark, largeLightChina, largeDarkChina} from './CssTheme'
import { smallLight, smallDark, smallLightChina, smallDarkChina } from './SmallCssTheme'

import ReactGA from 'react-ga';

const App = ({ layout }) => {

  const [theme, setTheme] = useState(largeDark);
  // useEffect(() => {
  //   ReactGA.initialize('UA-126020819-4');
  //   ReactGA.set({ page: 'rozeus.io' });
  //   ReactGA.pageview('/io');
  //   window.location.href=`https://www.rozeus.com`;
  // },[]);

  useEffect(() => {
    const selectTheme = layout.sizeMode+'_'+layout.mode+'_'+layout.language;
    switch (selectTheme){
      case themeType.LARGE_LIGHT_KOR:
        setTheme(largeLight)
        return;
      case themeType.LARGE_LIGHT_ENG:
        setTheme(largeLight)
        return;
      case themeType.LARGE_LIGHT_CN:
        setTheme(largeLightChina)
        return;
      case themeType.LARGE_DARK_KOR:
        setTheme(largeDark)
        return;
      case themeType.LARGE_DARK_ENG:
        setTheme(largeDark)
        return;
      case themeType.LARGE_DARK_CN:
        setTheme(largeDarkChina)
        return;
      case themeType.SMALL_LIGHT_KOR:
        setTheme(smallLight)
        return;
      case themeType.SMALL_LIGHT_ENG:
        setTheme(smallLight)
        return;
      case themeType.SMALL_LIGHT_CN:
        setTheme(smallLightChina)
        return;
      case themeType.SMALL_DARK_KOR:
        setTheme(smallDark)
        return;
      case themeType.SMALL_DARK_ENG:
        setTheme(smallDark)
        return;
     case themeType.SMALL_DARK_CN:
        setTheme(smallDarkChina)
        return;

      default : 
        setTheme(largeDark)
    }
  
  }, [layout.sizeMode, layout.mode, layout.language]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <MainContainer  />
    </MuiThemeProvider>
  );
}

export default inject(({ layout }) => ({
  layout: layout
}))(observer(App));

import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
        padding: m_size.padding,
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        backgroundPosition: 'right -18px top 42%',
        backgroundSize: '281.7px auto',
        backgroundRepeat: 'no-repeat',
    },
    body: {
    },
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div className={classes.bodyWrap} style={{ backgroundImage:'url(/images/profile/rozeus/back_rozeus_chap2_'+layout.mode+'.png)'}}>
            <div className={classes.body}>
                <div
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                >
                    <Typography variant='h1'>Strong Points</Typography>
                    <div style={{ height: '20px' }} />
                    {layout.language === 'kor'?
                        <React.Fragment>
                            <Typography variant='body1'>로제우스는 서비스, 플랫폼 그리고 미디어 dApp 레벨으로 구성됩니다.</Typography>
                            <Typography variant='body1'>콘텐츠는 서비스와 플랫폼에서 생산되고 소비됩니다.</Typography>
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>서비스는 로제우스가 운영하는 첫 번째 dApp이자 dApp 레벨의 첫 번째 미디어 dApp 입니다.</Typography>
                            <Typography variant='body1'>뉴스룸 기능으로 미디어에게 공평하게 콘텐츠를 노출 시킬 수 있는 창(Window)를 제공합니다.</Typography>
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>플랫폼은 모든 참여자들의 생태계 기반(Ground)를 제공합니다. 생태계는 다양한 미디어 dApp들이 만들어 갑니다.</Typography>
                            <Typography variant='body1'>로제우스 서비스도 미디어 dApp중 하나입니다.</Typography>
                            {/**
                            <Typography variant='body1'>플랫폼은 댑 인터페이스, 마켓, 지갑, 플랫폼사이트, 6개의 미디어 엔진, TARA, 그리고 MIMIC등의 모듈로 구성됩니다.</Typography>
                             */}
                            <Typography variant='body1'>플랫폼은 댑 인터페이스, 마켓, 플랫폼사이트, 6개의 미디어 엔진, TARA, 그리고 MIMIC등의 모듈로 구성됩니다.</Typography>
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>미디어 dApp 레벨은 로제우스 서비스의 기능을 적용해 만들어진 비즈니스 댑(Business dApp)의 집합입니다.</Typography>
                            <Typography variant='body1'>기업등 여러 이해 관계자는 로제우스가 제공하는 다양한 기능을 쉽게 적용해 독립된 미디어 dApp을 생성 할 수 있습니다.</Typography>
                            {/**
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='overline' onClick={(e)=>history.push('/progress/resources')}>더 자세한 내용은 PROGRESS 탭의 RESOURCES에서 확인 할 수 있습니다.<img src={'/images/common/ic_link_arrow_'+layout.mode+'.png'} alt='LINK' style={{width:'6px', marginLeft:'5px'}}/></Typography>
                             */}
                            </React.Fragment> 

                    :layout.language === 'eng'? 
                        <React.Fragment>
                            <Typography variant='body1'>Rozeus consists of service, platform, and media dApp level.</Typography>
                            <Typography variant='body1'>Contents are created and consumed through our service and platform.</Typography>
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>Service is the first operated dApp under Rozues, and is made by dApp level's first media dApps.</Typography>
                            <Typography variant='body1'>Through newsroom functions, a window is provided to the media for fair contents exposure.</Typography>
                            <Typography variant='body1'>The platform provides an ecosystem ground for all participants.</Typography>
                            <Typography variant='body1'>The ecosystem is created by various media dApps.</Typography>                   
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>The service is created by the first dApp run by Rozeus and by the first Media dApp on the dApp level.</Typography>
                            <Typography variant='body1'>Rozeus service is also a part of media dApps.</Typography>
                            {/**
                            <Typography variant='body1'>The Platform consists of modules including dApp interface, market, platform site, 6 media engine, TARA, and MIMIC. </Typography>
                             */}
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>Media dApp level is a collection made up of Rozeus' service functions.</Typography>
                            <Typography variant='body1'>Businesses and stakeholders can create an independent media dApp with various functions that Rozeus provides.</Typography>
                            {/**
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1' style={{fontWeight:'bold'}} onClick={(e)=>history.push('/progress/resources')}>For more information, Check our PROGRESS tab under Resources.<img src={'/images/common/ic_link_arrow_'+layout.mode+'.png'} alt='LINK' style={{width:'6px', marginLeft:'5px'}}/></Typography>
                            */}
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <Typography variant='body1'>ROZEUS由服务、平台与媒体dApp等级组成。</Typography>
                            <Typography variant='body1'>内容将在服务于平台中制作并分发。</Typography>
                            <Typography variant='body1'>&nbsp;</Typography>
                            <Typography variant='body1'>服务作为ROZEUS运营的第一个dApp，将由dApp等级</Typography>
                            <Typography variant='body1'>的第一个媒体dApp制作并发展。</Typography>
                            <Typography variant='body1'>ROZEUS服务也是媒体dApp之一。</Typography>
                            <Typography variant='body1'>平台由dAPP接口、商店、平台网站、6大媒</Typography>
                            <Typography variant='body1'>体引擎、TARA，以及MIMIC等模块组成。</Typography>
                            <Typography variant='body1'>&nbsp;</Typography>                            
                            <Typography variant='body1'>媒体dApp等级是采用ROZEUS服务的功能制作而成的</Typography>
                            <Typography variant='body1'>商务dApp（Business dApp）的集合。</Typography>
                            <Typography variant='body1'>企业等各有关部门可以通过ROZEUS提供的多种功能，</Typography>
                            <Typography variant='body1'>轻松构建独立的媒体dApp。</Typography>
                            {/**
                            <Typography variant='overline' onClick={(e)=>history.push('/progress/resources')}>更多详细内容可以通过PROGESS dApp的Resourcess进行确认。<img src={'/images/common/ic_link_arrow_'+layout.mode+'.png'} alt='LINK' style={{width:'6px', marginLeft:'5px'}}/></Typography>
                            */}
                        </React.Fragment>

                    }
                    
                </div>
                <div style={{ height: '100px' }} />
                <div 
                    data-aos='fade-up'
                    data-aos-duration={aos_option.duration}
                    data-aos-offset={aos_option.offset}
                    style={{ display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center'}}> 
                    <img src={'/images/profile/rozeus/small/chart_rozeus_chap2_' + layout.mode + '_' + layout.language +'.png'} alt='chapter2 chart' style={{ width: '100%', height:'100%'}} />
                </div>
                <div style={{ height: '150px' }} />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
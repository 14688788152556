import React, { useCallback, useEffect, useState } from 'react';
import { size, aos_option } from '../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { makeStyles, requirePropFactory, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Counter from './Counter';
import NumberTitle from './NumberTitle';
import { throttle } from 'lodash';

const useStyles = makeStyles(theme => ({
    bodyWrap:{
        width: '100%',
        height: '1754px',
        margin: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '100% 553px',
    },
    body: {
        width: '100%',
        minWidth:size.minWidth,
        maxWidth:size.maxWidth,
        height: '1754px',
        margin:'auto',
    },
    contentWrap :{
        height: '980px'
    },
    symbol : {
        position:'relative',
        width: '100%',
        height: '1754px',
        marginTop:'-1115px',
    },
    symbolImg: {
        position: 'absolute',
        bottom:'235px',
        right:'0px',
    }
}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();
    const [flag1, setFlag1] = useState(true);
    const [flag2, setFlag2] = useState(true);
    const [flag3, setFlag3] = useState(true);


    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    const listener = useCallback(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        const section = document.querySelector("#top");
        const sectionHeight = section && section.getBoundingClientRect().height;

        if ((height + scrollTop) > (sectionHeight + 820) && flag1) {
            setFlag1(false)
        }
        if ((height + scrollTop) > (sectionHeight + 820 + 160) && flag2) {
            setFlag2(false)
        }
        if ((height + scrollTop) > (sectionHeight + 820 + 320) && flag3) {
            setFlag3(false)
        }
    }, [flag1, flag2, flag3]);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener, true);
        return () => {
            window.removeEventListener("scroll", throttleListener, true);
        };
    }, [listener]);


    useEffect(() => {
        const scrollTop = window.scrollY || window.pageYOffset;
        const height = window.innerHeight;
        if ((height + scrollTop) > (920 + 120) && flag1) {
            setFlag1(false)
        }
        if ((height + scrollTop) > (920 + 265 + 120 + 160) && flag2) {
            setFlag2(false)
        }
        if ((height + scrollTop) > (920 + 265 + 120 + 320) && flag3) {
            setFlag3(false)
        }
    }, [flag1, flag2, flag3]);

    return (
        <div className={classes.bodyWrap} 
            style={{ backgroundImage: 'url(/images/home/back_chapter1_' + layout.mode +'.png)'}}>
            <div className={classes.body}>
                <div style={{height:'135px'}} />
                <div className={classes.contentWrap}>
                    <div
                        data-aos='fade-up'
                        data-aos-duration={aos_option.duration}
                        data-aos-offset={aos_option.offset}
                    >
                        <NumberTitle 
                            number='01' 
                            text={
                                layout.language === 'kor'? 
                                ['인공지능 알고리즘 기반의', '빅데이터 분석 기술을 적용한','오픈 미디어 플랫폼을 만듭니다.']
                                : layout.language === 'eng'?
                                ['Creating the open media platform', 'which applies big data analysis based on','artificial intelligence algorithm.']
                                :
                                ['采用基于人工智能算法大数据分析','技术的开放式媒体平台。']
                                } 
                            top='-84px'
                            />
                        <div style={{ height: '30px' }} />
                        <div style={{ margin: '0px 0px' }}>
                            {layout.language === 'kor'? 
                                    <React.Fragment>
                                    <Typography variant='body1'>공평하고 합리적인 미디어 생태계를 구성하기 위해 언론사, 전문 매체, 관련 기관과 함께 일합니다.</Typography>
                                    <Typography variant='body1'>미디어를 디지털 환경으로 연결하는 기술과 방대한 미디어 빅데이터를 가지고 있습니다.</Typography>
                                    <Typography variant='body1'>한국의 no.1 미디어 데이터 기반 ai 큐레이션 서비스를 제공하고 있습니다.</Typography>
                                    </React.Fragment>
                            : layout.language === 'eng'?
                                    <React.Fragment>
                                    <Typography variant='body1'>Work with the press, specialized media and related organizations to create a fair and rational media ecosystem.</Typography>
                                    <Typography variant='body1'>Secure the technology connecting media to the digital environment with the vast amount of media big data.</Typography>
                                    <Typography variant='body1'>Provide AI curation service based on No.1 media data in Korea.</Typography>
                                    </React.Fragment>                            
                            : 
                                    <React.Fragment>
                                    <Typography variant='body1'>携手新闻媒体、专业媒体、相关机构，致力于提供公平、合理的媒体生态体系。</Typography>
                                    <Typography variant='body1'>拥有将媒体链接到数字化环境中的技术与庞大的媒体大数据。</Typography>
                                    <Typography variant='body1'>提供韩国No.1的媒体大数据AI分类服务。</Typography>
                                    </React.Fragment>
                            }
                      </div>
                    </div>
                    <div style={{ height: '130px' }} />
                    <div>
                    {layout.language === 'kor'? 
                        <Typography variant='body1'>로제우스 누적 컨텐츠 수</Typography>
                    : layout.language === 'eng'?
                        <Typography variant='body1'>Rozeus's cumulative contents </Typography>
                    :
                        <Typography variant='body1'>ROZEUS累计内容数</Typography>
                    }
                        {flag1 ? 
                            <Typography variant='subtitle1'>&nbsp;</Typography>
                            :
                            <Counter countNum={40000000} />
                        }
                        <div style={{ height: '65px' }} />
                        {layout.language === 'kor'? 
                            <Typography variant='body1'>로제우스 매일 생성되는 컨텐츠 수</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Rozeus's daily generating contents </Typography>
                        :
                            <Typography variant='body1'>ROZEUS每天生成的内容数</Typography>
                        }
                        {flag2 ?
                            <Typography variant='subtitle1'>&nbsp;</Typography>
                            :
                            <Counter countNum={50000} />
                        }
                        <div style={{ height: '65px' }} />
                        {layout.language === 'kor'? 
                            <Typography variant='body1'>로제우스 누적 파트너 수</Typography>
                        : layout.language === 'eng'?
                            <Typography variant='body1'>Rozeus's cumulative partners</Typography>
                        :
                            <Typography variant='body1'>ROZEUS累计合作伙伴数</Typography>
                        }
                        {flag3 ?
                            <Typography variant='subtitle1'>&nbsp;</Typography>
                            :
                            <Counter countNum={2300} />
                        } 
                    </div>
                </div>
                <div className={classes.symbol}>
                    <div className={classes.symbolImg}>
                        <img 
                            data-aos='fade-up' 
                            data-aos-duration={aos_option.duration} 
                            data-aos-offset={aos_option.offset}
                            src={'/images/home/move_chapter1_symbol_' + layout.mode + '.png'} 
                            alt='symbol' 
                            style={{width: '560px', height: '563px'}} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
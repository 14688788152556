import React, { useState } from 'react';

import { size, menuList } from '../../../common/constants'

import { Divider, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ModalPopup from './ModalPopup';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '320px',
        margin: 'auto',
        backgroundColor:theme.palette.background.footer
    },
    body: {
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin: 'auto',
    },
    contentWrap: {
        width: '651px',
        minWidth: '651px',
        maxWidth: '651px',
    },
    contentText:{
        fontFamily: 's-core_dream4_regular',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '18px',

    },
    linkTitle:{
        fontFamily: 's-core_dream4_regular',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    linkText: {
        fontFamily: 's-core_dream4_regular',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
    }
}));

const Download = ({ layout }) => {

    const classes = useStyles();
    const history = useHistory();

    const [aboutTarget, setAboutTarget] = useState('');

    const handleAboutClick = (target) => {
        setAboutTarget(target);
        layout.setShowAboutModal(true);
    }

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                <div className={classes.contentWrap}>
                    <div style={{ height: '15px' }} />
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={'/images/common/footer/ic_autorenew_'+layout.mode+'.png'} alt='Auto Refresh' style={{width:'11px',height:'14px'}} />
                        <div style={{width:'8.33px'}}/>
                        <Typography className={classes.contentText}>website last updated: June 02, 2021</Typography>
                    </div>
                    <div style={{ height: '11px' }} />
                </div>
                <Divider style={{width:'100%'}}/>
                <div className={classes.contentWrap}>
                    <div style={{ height: '25px' }} />
                    <div style={{display:'flex', justifyContent:'space-between',alignItems:'flex-start'}}>
                        <div>
                            <Typography className={classes.linkTitle}>PROFILE</Typography>
                            <div style={{height:'16px'}} />
                            {menuList['PROFILE'].map((item, index)=>{
                                return (
                                    <div key={index} style={{ cursor: 'pointer' }} onClick={(e) => history.push(item.url)}>
                                        <Typography className={classes.linkText}>{item.name}</Typography>
                                        <div style={{ height: '14px' }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <Typography className={classes.linkTitle}>PROGRESS</Typography>
                            <div style={{ height: '16px' }} />
                            {menuList['PROGRESS'].map((item, index) => {
                                return (
                                    <div key={index} style={{ cursor: 'pointer' }} onClick={(e) => history.push(item.url)}>
                                        <Typography className={classes.linkText}>{item.name}</Typography>
                                        <div style={{ height: '14px' }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                                <Typography className={classes.linkTitle}>Service </Typography><img src={'/images/common/footer/ic_upArrow_' + layout.mode + '.png'} alt='Up Arrow' style={{ marginLeft:'7.5px', width: '9px', height: '9px' }} />
                            </div>
                            <div style={{ height: '16px' }} />
                            {menuList['SERVICE'].map((item, index) => {
                                return (
                                    <div key={index} style={{ cursor: 'pointer' }} onClick={(e) => window.open(item.url)}>
                                        <Typography className={classes.linkText}>{item.name}</Typography>
                                        <div style={{ height: '14px' }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <Typography className={classes.linkTitle}>ABOUT rozeus.io</Typography>
                            <div style={{ height: '16px' }} />
                            {menuList['ABOUT'].map((item, index) => {
                                return (
                                    <div key={index} style={{ cursor: 'pointer' }} onClick={(e) => item.name === 'Contact Us' ? history.push(item.url) : handleAboutClick(item.name)}>
                                        <Typography className={classes.linkText}>{item.name}</Typography>
                                        <div style={{ height: '14px' }} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopup target={aboutTarget} />
        </div>
    )

};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Download));
import React, { useLayoutEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
    },
    body: {
        width: '100%',
    },
    icon : {
        width:'33px',
        height:'33px',
        cursor:'pointer'
    }
}));

const SNSIcon = ({ layout }) => {

    const classes = useStyles();

    const [sizeShow, setSizeShow] = useState(true);

    useLayoutEffect(() => {
        function updateSize() {
            window.innerWidth > 400 ? setSizeShow(true) : setSizeShow(false);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);

    return (
        <div className={classes.bodyWrap}>
            <div className={classes.body}>
                {sizeShow ?
                    <div style={{ display: 'flex' }}>
                        <img src={'/images/common/footer/naver_' + layout.mode + '.png'} alt='naver' className={classes.icon} onClick={(e) => window.open('https://blog.naver.com/rozeus8')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/sinaWeibo_' + layout.mode + '.png'} alt='weibo' className={classes.icon} onClick={(e) => window.open('https://weibo.com/p/1005057199901989/home?from=page_100505&mod=TAB&is_all=1#place')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/youtube_' + layout.mode + '.png'} alt='youtube' className={classes.icon} onClick={(e) => window.open('https://www.youtube.com/channel/UC-iUWWR6LPC3PmOOxH5E3kg')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/medium_' + layout.mode + '.png'} alt='medium' className={classes.icon} onClick={(e) => window.open('https://medium.com/rozeus')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/telegram_' + layout.mode + '.png'} alt='telegram' className={classes.icon} onClick={(e) => window.open('https://t.me/rozeus_2019')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/facebook_' + layout.mode + '.png'} alt='facebook' className={classes.icon} onClick={(e) => window.open('https://www.facebook.com/rozeuskr/')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/ic_gitHub_' + layout.mode + '.png'} alt='github' className={classes.icon} onClick={(e) => window.open('https://github.com/RozeusBlockChain')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/zhihu_' + layout.mode + '.png'} alt='zhihu' className={classes.icon} onClick={(e) => window.open('https://www.zhihu.com/people/rozeus/posts')} />
                        <div style={{ width: '10px' }} />
                        <img src={'/images/common/footer/tudou_' + layout.mode + '.png'} alt='tudou' className={classes.icon} onClick={(e) => window.open('http://id.tudou.com/i/UNzc0NDU4NjE2OA==?spm=a2h28.8313469.ln.du_host')} />
                    </div>
                    :
                    <React.Fragment>
                        <div style={{display:'flex'}}>
                            <img src={'/images/common/footer/naver_' + layout.mode + '.png'} alt='naver' className={classes.icon} onClick={(e) => window.open('https://blog.naver.com/rozeus8')} />
                            <div style={{width:'10px'}} />
                            <img src={'/images/common/footer/sinaWeibo_' + layout.mode + '.png'} alt='weibo' className={classes.icon} onClick={(e) => window.open('https://weibo.com/p/1005057199901989/home?from=page_100505&mod=TAB&is_all=1#place')}/>
                            <div style={{ width: '10px' }} />
                            <img src={'/images/common/footer/youtube_' + layout.mode + '.png'} alt='youtube' className={classes.icon} onClick={(e) => window.open('https://www.youtube.com/channel/UC-iUWWR6LPC3PmOOxH5E3kg')}/>
                            <div style={{ width: '10px' }} />
                            <img src={'/images/common/footer/medium_' + layout.mode + '.png'} alt='medium' className={classes.icon} onClick={(e) => window.open('https://medium.com/rozeus')}/>
                            <div style={{ width: '10px' }} />
                            <img src={'/images/common/footer/telegram_' + layout.mode + '.png'} alt='telegram' className={classes.icon} onClick={(e) => window.open('https://t.me/rozeus_2019')} />
                            <div style={{ width: '10px' }} />
                            <img src={'/images/common/footer/facebook_' + layout.mode + '.png'} alt='facebook' className={classes.icon} onClick={(e) => window.open('https://www.facebook.com/rozeuskr/')}/>
                        </div>
                        <div style={{ height: '10px' }} />
                        <div style={{ display: 'flex' }}>
                            <img src={'/images/common/footer/ic_gitHub_' + layout.mode + '.png'} alt='github' className={classes.icon} onClick={(e) => window.open('https://github.com/RozeusBlockChain')}/>
                            <div style={{ width: '10px' }} />                    
                            <img src={'/images/common/footer/zhihu_' + layout.mode + '.png'} alt='zhihu' className={classes.icon} onClick={(e) => window.open('https://www.zhihu.com/people/rozeus/posts')}/>
                            <div style={{ width: '10px' }} />
                            <img src={'/images/common/footer/tudou_' + layout.mode + '.png'} alt='tudou' className={classes.icon} onClick={(e) => window.open('http://id.tudou.com/i/UNzc0NDU4NjE2OA==?spm=a2h28.8313469.ln.du_host')}/>
                        </div>
                    </React.Fragment>
                }

            </div>
        </div>
    )

}; 

export default inject(({ layout }) => ({
    layout: layout
}))(observer(SNSIcon));
import React, { useEffect } from 'react';
import { makeStyles, useScrollTrigger, Zoom } from '@material-ui/core';
import PropTypes from "prop-types";
import { Route, Switch, Link } from 'react-router-dom';
import HomeContainer from '../containers/HomeContainer';
import VideoModal from '../components/common/VideoModal';
import ProfileContainer from '../containers/ProfileContainer';
import ProgressContainer from '../containers/ProgressContainer';
import AboutContainer from '../containers/AboutContainer';

const useStyles = makeStyles(theme => ({
    fontCustom: {
        fontSize: '18px'
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: '#e7484e !important',
    },
    scrollTopFab: {
        position: "fixed",
        bottom: '10%',
        right: '0px',
        zIndex:1000
    }
}));

function ScrollTop(props) {

    const { children } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            "#back-to-top-anchor"
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <div className={classes.scrollTopFab}>
            <Zoom in={trigger}>
                <div onClick={handleClick} role="presentation" >
                    {children}
                </div>
            </Zoom>
        </div>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired
};

const MainTemplate = ({ header, footer, headerSize}) => {
    return (
        <React.Fragment>
            {header}
            <div style={{ height: headerSize+'px' }}></div>
            <Route path="/" exact>
                <HomeContainer />
                {footer}
            </Route>
            <Route path="/profile" exact component={ProfileContainer} />
            <Route path="/profile/:target" exact component={ProfileContainer} />
            <Route path="/progress" exact component={ProgressContainer} />
            <Route path="/progress/:target" exact component={ProgressContainer} />
            <Route path="/about" exact component={AboutContainer} />
            <Route path="/about/:target" exact component={AboutContainer} />
            <ScrollTop>
                <img src='/images/common/top_btn.png' alt='Go to Top' style={{ width: '50px', height: '50px' }}/>
            </ScrollTop>
            <VideoModal />
        </React.Fragment>
    );
};

export default MainTemplate;
import React, { useEffect } from 'react';
import { m_size } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
        backgroundColor : theme.palette.background.sub.subBackground
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
    },

}));

const Chapter1 = ({ layout }) => {

    const classes = useStyles();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });
    
    return (
        <div 
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'100px'}} />
                <Typography variant='h1'>Logo_Allvertical</Typography>
                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Allvertical_red.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{display:'flex',alignItems:'center', cursor:'pointer'}} onClick={(e) => window.location.href ='/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Allvertical_red
                    <div style={{width:'8px'}} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height:'12px' }} />
                    </div>
                </Typography>
                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Allvertical_white.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Allvertical_white
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>

                <div style={{ height: '120px' }} />
                <Typography variant='h1'>Logo_Allhorizontal</Typography>
                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Allhorizontal_red.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Allhorizontal_red
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>
                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Allhorizontal_white.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Allhorizontal_white
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>

                <div style={{ height: '120px' }} />
                <Typography variant='h1'>Logo_Symvolonly</Typography>
                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Symvolonly_red.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Symvolonly_red
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>

                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Symvolonly_white.png' alt='download' style={{ width: '100%' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Symvolonly_white
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>


                <div style={{ height: '120px' }} />
                <Typography variant='h1'>Logo_Textonly</Typography>
                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Textonly_red.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Textonly_red
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>

                <div style={{ height: '30px' }} />
                <img src='/images/profile/pressKit/resources/small/Logo_Textonly_white.png' alt='download' style={{ width: '100%' }} />
                <div style={{ height: '10px' }} />
                <Typography variant='body1' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.href = '/resources/pressKit/Logo_Allvertical_Red.ai'}>
                    Logo_Textonly_white
                    <div style={{ width: '8px' }} />
                    <div>
                        <img src={'/images/common/ic_button_download2_' + layout.mode + '.png'} alt='download' style={{ width: '11px', height: '12px' }} />
                    </div>
                </Typography>


            </div>
            <div style={{ height: '150px' }} />
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter1));
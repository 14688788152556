import React, { useState } from 'react';
import Slider from 'infinite-react-carousel'
import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    dotFill: {
        borderRadius: '50%', 
        border: '1px solid' + theme.palette.button.hover, 
        backgroundColor: theme.palette.button.hover,
        width: '10px', 
        height: '10px',
    },
    dot: {
        borderRadius: '50%',
        border: '1px solid ' + theme.palette.button.hover,
        width: '10px',
        height: '10px',
    },
    cardWrap:{
        height:'480px',
        display: 'flex', 
        alignItems: 'center'
    },
    chapter1Card: {
        width:'100%',
        height: '460px',
        backgroundColor: theme.palette.background.sub.chapCard,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 7px',
        animation: '$large 0.15s ease-in normal',
    },
    chapter1CardDis: {
        width: '100%',
        height: '440px',
        backgroundColor: theme.palette.background.sub.chapCard,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 7px',
        animation: '$small 0.15s ease-in normal',
    },
    '@keyframes large': {
        '0%': { height: '440px' },
        '50%': { height: '450px' },
        '100%' : {height: '460px'}
    },
    '@keyframes small': {
        '0%': { height: '460px' },
        '50%': { height: '450px' },
        '100%': { height: '440px' }
    }

}));

const CustomSlider = ({layout}) => {

    const classes = useStyles();
    const [slideIndex , setSlideIndex] = useState(0);

    const setting = {
        arrows: false,
        centerMode: true,
        centerPadding: 40,
        duration: 100,
        afterChange: index => setSlideIndex(index)
    };

    return (
        <React.Fragment>
            <div>
                <Slider {...setting}>
                    <div>
                        <div className={classes.cardWrap}>
                            <div className={slideIndex === 0 ? classes.chapter1Card : classes.chapter1CardDis}>
                                <div style={{width:'100%', height:'190px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                    <img src={'/images/profile/rozeus/card_rozeus_chap1_1_' + layout.mode + '.png'} alt="신뢰할 수 있는 콘텐츠" style={{ display:'inline-block', width: '40%', maxHeight:'60%' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body2'>신뢰할 수 있는 콘텐츠</Typography>
                                :layout.language === 'eng'?
                                    <Typography variant='body2'>Reliable Contents</Typography>
                                :
                                    <Typography variant='body2' >值得信任的内容</Typography>
                                }                          
                                <div style={{ height: '22px' }} />                                
                                {layout.language === 'kor'? 
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>내가 읽고 있는 콘텐츠를 의심하기 시작한 때가 언제인가요? 효율적인 시스템으로 가짜 뉴스 검증 체계를 구현합니다. 검증된 사실의 위키를 만듭니다. 믿을 수 있는 콘텐츠가 의미있는 콘텐츠입니다. 의심하는 시간을 아낄 수 있습니다.</Typography>
                                :layout.language === 'eng'?
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>When was it that you started doubting the contents that you are reading? 
                                                                                                                        We provide an efficient fake news verification system. 
                                                                                                                        Verified information makes a wiki of information. Reliable contents have true values. 
                                                                                                                        You can save the time spent doubting the contents.
                                    </Typography>
                                :
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>什么时候开始怀疑自己看到的内容有问题呢？
                                                                                                                        通过高效的系统，构建虚假新闻验证体系。
                                                                                                                        制作事实验证维基。值得信任的内容才是有价值的内容。
                                                                                                                        为用户节省疑惑时间。
                                    </Typography>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.cardWrap}>
                            <div className={slideIndex === 1 ? classes.chapter1Card : classes.chapter1CardDis}>
                                <div style={{ width: '100%', height: '190px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={'/images/profile/rozeus/card_rozeus_chap1_2_' + layout.mode + '.png'} alt="모두에게 공평한 투명한 보상" style={{ display: 'inline-block', width: '40%', maxHeight: '60%' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body2'>모두에게 공평한 투명한 보상</Typography>
                                :layout.language === 'eng'?
                                    <Typography variant='body2'>Fair and Transparent Rewards for All</Typography>
                                :
                                    <Typography variant='body2' >为所有人提供公平而透明的回报</Typography>
                                }
                                
                                <div style={{ height: '22px' }} />
                                {layout.language === 'kor'? 
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>도움이 되는 글과 댓글을 작성하면 보상을 받습니다. 모든 종류의 긍적적인 활동은 영향력에 비례하는 보상을 받습니다. 전체 보상은 모두에게 자동 공개되며 투명하게 분배 됩니다. 미디어의 파워를 독자와 작가에게 돌려줍니다.</Typography>
                                :layout.language === 'eng'?
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>Helpful contents and comments are rewarded. 
                                                                                                                        All positive activities will be rewarded according to the impacts of the influences. 
                                                                                                                        All rewards will be visible to everyone and distributed transparently. 
                                                                                                                        The media power goes back to the readers and writers.</Typography>
                                :
                                    <React.Fragment>
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>撰写有益文章与留言将得到奖励。</Typography>
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>所有积极有益的活动，都将以影响力为基准得到奖励。</Typography>
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>所有奖励将自动向所有人公开，并进行透明分配。</Typography>
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>将媒体的力量交给读者与撰稿人。</Typography>
                                    </React.Fragment>
                                }

                                
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.cardWrap}>
                            <div className={slideIndex === 2 ? classes.chapter1Card : classes.chapter1CardDis}>
                                <div style={{ width: '100%', height: '190px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={'/images/profile/rozeus/card_rozeus_chap1_3_' + layout.mode + '.png'} alt="편리한 개인별 시스템" style={{ display: 'inline-block', width: '30%', marginTop: '20px', maxHeight: '60%' }} />
                                </div>
                                {layout.language === 'kor'? 
                                    <Typography variant='body2'>편리한 개인별 시스템</Typography>
                                :layout.language === 'eng'?
                                    <Typography variant='body2'>Convenient Personalized System</Typography>
                                :
                                    <Typography variant='body2'>便捷的个性化系统</Typography>
                                }
                                
                                <div style={{ height: '22px' }} />
                                {layout.language === 'kor'? 
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>사용자 데이터가 쌓일 수록 개인을 위한 시스템이 점점 똑똑해집니다. 작가 등 생산자의 콘텐츠는 사용자 데이터를 기반으로 공평하게 노출됩니다.</Typography>
                                :layout.language === 'eng'?
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>As users' data gathers, the system becomes more intelligent. Writers and their contents are exposed fairly based on the users' data.</Typography>
                                :
                                    <React.Fragment>
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>随着累计用户数据的增加，个性化定制系统将变得更加智能。</Typography>
                                    <Typography variant='h3' style={{ margin: '0px 20px', width: 'calc(100% - 40px)' }}>撰稿人等内容制作人将以用户数据为基础得到公平的流量。</Typography>
                                    </React.Fragment>
                                }                               
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <div style={{height:'20px'}} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={slideIndex === 0 ? classes.dotFill : classes.dot} />
                <div style={{width:'9px'}} />
                <div className={slideIndex === 1 ? classes.dotFill : classes.dot} />
                <div style={{ width: '9px' }} />
                <div className={slideIndex === 2 ? classes.dotFill : classes.dot} />
            </div>
        </React.Fragment>

    )
}

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(CustomSlider));
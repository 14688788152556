import React from 'react';
import { makeStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    divider: {
        backgroundColor : theme.palette.text.secondary,
        width: props => props.sizeMode === 'large' ? '35px' : '18px',
        height: props => props.sizeMode === 'large' ? '8px' : '5px',
    },
}));

const HomeChapterDivider = ({ type, layout }) => {

    const classes = useStyles(layout);

    if(!type){
        return (
            <div className={classes.divider} />
        );
    }else{
        return (
            <div style={{ display: 'flex', width: layout.sizeMode === 'large' ? '40px' : '25px', justifyContent:'space-between'}}>
                <div className={classes.divider} style={{ width: layout.sizeMode === 'large' ? '8px' : '5px'}}/>
                <div className={classes.divider} style={{ width: layout.sizeMode === 'large' ? '8px' : '5px' }} />
                <div className={classes.divider} style={{ width: layout.sizeMode === 'large' ? '8px' : '5px' }} />
            </div>
        );
    }
}; 

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(HomeChapterDivider));
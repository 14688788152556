import { KingBedRounded } from '@material-ui/icons';
import { observable, action } from 'mobx';
import { LARGE, SMALL, DARK, LIGHT } from '../../common/constants'

export default class LayoutStore {

    @observable sizeMode = LARGE;
    @observable mode = DARK;

    @observable language = 'kor';
    @observable banner = true;
   
    @observable showModal = false;
    @observable modalViedeoPath = '';

    @observable newsPrTab = 0;
    @observable partnerTab = 0;

    @observable showAboutModal = false;
    @observable modalAboutTitle = '';
    @observable modalAboutContents = '';

    constructor(root) {
        this.root = root;
        this.mode = localStorage.getItem("ROZEUS_IO_VIEW_MODE") ? localStorage.getItem("ROZEUS_IO_VIEW_MODE") : DARK;
    }

    @action setSizeMode = (val) => {
        this.sizeMode = val;
    }

    @action setMode = (val) => {
        this.mode = val;
        localStorage.setItem("ROZEUS_IO_VIEW_MODE", val);
    }

    @action showVideo = (val) => {
        this.showModal = true;
        this.modalViedeoPath = val;
    }

    @action closeModal = () => {
        this.showModal = false;
        this.modalViedeoPath = '';
    }

    @action setPartnerTab = (val) => {
        this.partnerTab = val;
    }

    @action setNewsPrTab = (val) => {
        this.newsPrTab = val;
    }

    @action setShowAboutModal = (val) => {
        this.showAboutModal = val;
    }

    @action setModalAboutTitle = (val) => {
        this.modalAboutTitle = val;
    }

    @action setModalAboutContents = (val) => {
        this.modalAboutContents = val;
    }

    @action setLanguage = (val) => {
        this.language = val;
    }

    @action setBanner = (val) => {
        this.banner = val;
    }
}
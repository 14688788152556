import React, { useState } from 'react';

const ICB = ({ imgPath, alt, width, height, handleClick }) => {

    const [mode, setMode] = useState('');


    const handleMode = (mode) => {
        setMode(mode)
    }

    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <img 
                src={imgPath + mode + '.png'} 
                alt={alt} 
                style={{ display:'block', width: width, height: height, cursor: 'pointer'}} 
                onClick={handleClick}
                onMouseOver={(e) => handleMode('_over')}
                onMouseOut={(e) => handleMode('')}
                onMouseDown={(e) => handleMode('_down')}
                onMouseUp={(e) => handleMode('')}
                onTouchStart={(e) => handleMode('_down')}
                onTouchEnd={(e) => handleMode('')}
            />
        </div>
    );
}

export default ICB;

import React, { useCallback, useEffect, useState } from 'react';
import { size, LIGHT, DARK } from '../../common/constants'

import { AppBar, makeStyles, Divider, Collapse, } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import ICB from './ICB';
import Language from './Language';
import CustomMenuItem from './CustomMenuItem';
import { throttle } from 'lodash';

const useStyles = makeStyles(theme => ({
    toolbar : {
        width:'100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
        margin:'auto',
        height:'60px',
        display:'flex',
        alignItems:'center',
    },
    toolbarContent : {
        display: 'flex',
        justifyContent:'space-between',
        width: '100%',
        minWidth: size.minWidth,
        maxWidth: size.maxWidth,
    },
    menuArea:{
        marginLeft:'60px',
        height:'60px',
        display:'flex',
    },
    iconArea: {
        height: '60px',
        display: 'flex',
    },
}));

const Header = ({layout, menu}) => {
    const classes = useStyles();
    const [eleVal, setEleVal] = useState(false);

    const handleBannerClick = event => {
        layout.setBanner(false);
    };

    const listener = useCallback(() => {
        if (window.scrollY || window.pageYOffset > 0) {
            setEleVal(true)
        } else {
            setEleVal(false)
        };
    }, []);

    useEffect(() => {
        const throttleListener = throttle(listener, 300)
        window.addEventListener("scroll", throttleListener);
        return () => {
            window.removeEventListener("scroll", throttleListener);
        };
    }, [listener]);

    const darkModeChange = () => {
        layout.mode === LIGHT ? layout.setMode(DARK) : layout.setMode(LIGHT);
    }

    return (
        <React.Fragment>
            <div id='back-to-top-anchor' />
            <Collapse in={layout.banner}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#F2F2F2',
                    }}
                    onClick={(e) => handleBannerClick(false)}
                >
                    {layout.language === 'kor' ?
                        <img src='/images/header/floating_ko.png' alt='Floating' style={{ width: '1240px' }} />
                    :
                        <img src='/images/header/floating_en.png' alt='Floating' style={{ width: '1240px' }} />
                    }
                </div>
            </Collapse>
            <AppBar position="sticky" elevation={0}>
                <div className={classes.toolbar}>
                    <div className={classes.toolbarContent}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}}>
                                <img src={'/images/common/web_logo_' + layout.mode + '.png'} alt='logo_rozeus' style={{ width: '122px', height: '19.89px' }} onClick={(e) => window.location.href = '/'}/>
                            </div>
                            <div className={classes.menuArea}>
                                <CustomMenuItem name='PROFILE' margin='60px'/>
                                <div style={{ width: '50px' }} />
                                <CustomMenuItem name='PROGRESS' margin='45px'/>
                                <div style={{ width: '50px' }} />
                                <CustomMenuItem name='SERVICE' margin='75px'/>
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }} onClick={menu.deselect}/>
                        <div className={classes.iconArea} onClick={menu.deselect}>
                            { layout.mode === 'light' ?
                                <ICB imgPath={'/images/header/ic_darkMode_' + layout.mode} alt='Language Change' width='22px' height='22px' handleClick={darkModeChange} />
                                :
                                <ICB imgPath={'/images/header/ic_darkMode_' + layout.mode} alt='Language Change' width='20px' height='20px' handleClick={darkModeChange} />
                            }
                            
                        </div>
                        <div style={{width:'29px'}} />
                            <Language imgPath={'/images/header/ic_language_'+ layout.mode} alt='Language Change' width='20px' height='20px' />
                    </div>
                </div>
                { eleVal && <Divider />}  
            </AppBar>
        </React.Fragment>
    );
}
export default inject(({ layout, menu }) => ({
    layout: layout,
    menu: menu
}))(observer(Header));

import React from 'react';
import { newsPr_content } from '../../../common/constants'

import EventCard from './EventCard';



const Event = () => {

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {newsPr_content.EVENT &&
                newsPr_content.EVENT.map((item, index) => {
                    return <EventCard key={index} target={item} />
                })
            }
            <div style={{width:'32%'}}/>
        </div>
    );
};

export default Event;
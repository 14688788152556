import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../common/constants'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { makeStyles, Typography } from '@material-ui/core';
import NumberTitle from '../../home/NumberTitle';

import { inject, observer } from 'mobx-react';
import HomeChapterDivider from '../../home/HomeChapterDivider';

import MobileVideoComponent from './MobileVideoComponent';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        height: '885px',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        margin: 'auto',
        padding: m_size.padding,
        backgroundImage: props => 'url(/images/home/back_chapter2_' + props.mode + '.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 430px',
        backgroundSize: '65% auto',
    },
    body: {
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        display: 'flex',
        justifyContent: 'flex-end',

    },
}));

const Chapter2 = ({ layout }) => {

    const classes = useStyles(layout);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={aos_option.offset}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div>
                    <NumberTitle
                        number='02'
                        text={
                            layout.language === 'kor'? 
                            ['합법적 데이터', '전문적 파트너', '상생의 미디어']
                            : layout.language === 'eng'?
                            ['Smart Service', 'Transparent Platform', 'Healthy Ecosystem']
                            :
                            ['合法数据', '专业伙伴', '共生媒体']
                        }
                        top='-62px'
                    /> 
                    <div style={{height:'30px'}} />
                    {layout.language === 'kor'? 
                    <div style={{ margin: '0px 0px' }}>
                        <Typography variant='body1'>대중과 언론의 소통을 돕고 트렌드와 사용자</Typography>
                        <Typography variant='body1'>개인화에 집중합니다. </Typography>
                        <Typography variant='body1'>효율적 산업을 위한 시스템과 인프라를 실현</Typography>
                        <Typography variant='body1'>합니다. </Typography>
                        <Typography variant='body1'>뉴스와 사람이 만나는 프로세스를 혁신합니다. </Typography>
                    </div>
                    : layout.language === 'eng'?
                    <div style={{ margin: '0px 0px' }}>
                        <Typography variant='body1'>Support public communication with the media and focus on trends and users.</Typography>
                        <Typography variant='body1'>Realize systems and infrastructure for the efficient media industry.</Typography>
                        <Typography variant='body1'>Renovate the joining process between news and people.</Typography>
                    </div>
                    :
                    <div style={{ margin: '0px 0px' }}>
                        <Typography variant='body1'>搭建公众与新闻媒体之间的沟通桥梁，</Typography>
                        <Typography variant='body1'>关注用户与趋势。 </Typography>
                        <Typography variant='body1'>为实现媒体行业高效发展，</Typography>
                        <Typography variant='body1'>构建完备的体系与基础。</Typography>
                        <Typography variant='body1'>创新人与新闻交汇的流程。</Typography>
                    </div>
                    }

                    <div style={{ height: '50px' }} />
                    <HomeChapterDivider />
                    <div style={{ height: '80px' }} />
                    <div>
                        <MobileVideoComponent
                            imgPath='/images/home/small/video_thumnail_chap2.png'
                            videoPath='https://www.youtube.com/embed/bicQtTc5hU8?autoplay=1&wmode=opaque'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter2));
import React, { useState } from 'react';

import { Fade, makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import CustomButton2 from '../../common/CustomButton2';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        position:'relative',
        width: '605px',
        height: '407px',
        border:'1px solid '+ theme.palette.divider
    },
    dim:{
        width: '100%', 
        height: '100%', 
        background: theme.palette.background.default, 
        opacity: 0.9
    }
}));

const Viewer = ({ type, text, img, hover, imgSize, url, layout }) => {

    const classes = useStyles();
    const [over, setOver] = useState(false);

    const handleClick = () => {
        window.location.href=url
    }

    return (
        <div 
            className={classes.bodyWrap} 
            style={type === 'light' ? { backgroundColor: '#ffffff' } : { backgroundColor: '#303030'}}
            onMouseOver={(e) => setOver(true)}
            onMouseOut={(e) => setOver(false)}
            >
            <div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'100%'}}>
                <img src={img} alt='download target preview' style={{ width: imgSize.width, height:imgSize.height}} />
            </div>
            {hover &&
            <Fade in={over} timeout={400}>
                <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%',}}>
                <div className={classes.dim} />
                    <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
                        <Typography variant='body1' >{text}</Typography>
                        <div style={{height:'14px'}} />
                        <CustomButton2 text='Download' size={{ width: '158px', height: '44px' }} img={{src:'/images/common/ic_button_download_dark.png', width:'14px', height:'12px'}} onclick={handleClick} />
                    </div>
                </div>
            </Fade>
            }
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Viewer));
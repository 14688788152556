import React, { useEffect } from 'react';
import { m_size, aos_option } from '../../../../common/constants'

import AOS from 'aos';
import 'aos/dist/aos.css'

import { makeStyles, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    bodyWrap: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.sub.chapBack,
        backgroundImage: props=> 'url(/images/profile/roz/small/back_roz_chap3_2_' + props.mode + '.png)', 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: '100% auto', 
        backgroundPosition: 'left bottom'
    },
    body: {
        position: 'relative',
        width: '100%',
        minWidth: m_size.minWidth,
        maxWidth: m_size.maxWidth,
        padding: m_size.padding,
        margin: 'auto',
        backgroundImage: props => 'url(/images/profile/roz/small/back_roz_chap3_1_' + props.mode + '.png)', 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: '40% auto', 
        backgroundPosition: 'right 11px'
    },
}));

const Chapter3 = ({ layout }) => {

    const classes = useStyles(layout);
    const history = useHistory();

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out-back'
        });
    });

    return (
        <div 
            data-aos='fade-up'
            data-aos-duration={aos_option.duration}
            data-aos-offset={150}
            className={classes.bodyWrap}>
            <div className={classes.body}>
                <div style={{height:'100px'}} />
                {layout.language === 'kor'? 
                <React.Fragment>
                    <Typography variant='h1' style={{ color: '#ffffff' }}>지속 가능한<br/>성장을 위한 설계</Typography>
                    <div style={{ height: '20px' }} />
                    <Typography variant='body1' style={{color:'#ffffff'}}>대부분의 프로젝트는 사용자의 증가에 따른 네트워크 효과에 막연히 의지하여 가치가 무한대로 성장할 것이라는 논리를 펼치고 있습니다.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>이것은 사용자가 무한대로 증가한다는 가설을 전체로 하고 있습니다.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>이러한 모델에 대하여 전문가들은 잘못 설계된 이코노미이며 궁극적으로 성장에 한계를 가져 올 수 밖에 없는 구조라는 의견이 주를 이루고 있습니다.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>로제우스는 팩트체인 프로토콜(Fact-chain protocol), 베이스(BASE) 그리고 유틸리티 최대화(Maximizing utiliy)등의 장치와 여러 설계 원칙을 가지고 “ROZ”를 만들었습니다.</Typography>
                </React.Fragment>
                    :layout.language === 'eng'?  
                <React.Fragment>  
                    <Typography variant='h1' style={{ color: '#ffffff' }}>Designed for <br/>Sustainable Growth</Typography>
                    <div style={{ height: '20px' }} />
                    <Typography variant='body1' style={{color:'#ffffff'}}>Most of other projects are relying vaguely on the network effects of increasing users, and relying on the logic that value will grow to infinity.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>Above are based on the hypothetical premise that the number of users increases indefinitely.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>Though many experts say that these models are poorly designed economy which structures cannot help bring on the limited growth inevitably.</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>Rozeus has created "ROZ" with devices such as Fact-chain protocol, Base and Maximizing utility and several design principles.</Typography>
                </React.Fragment>   
                :
                <React.Fragment> 
                    <Typography variant='h1' style={{ color: '#ffffff' }}>可持续发展的设计</Typography>
                    <div style={{ height: '20px' }} />
                    <Typography variant='body1' style={{color:'#ffffff'}}>大部分项目采取随着用户增加，完全依赖社群效应的价值无限增长原理。</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>这一方式以无限增加的用户为前提。</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>专家评价称，这种模型是设计错误的经济，最终会带来发展的受限的结构。</Typography>
                    <Typography variant='body1' style={{ color: '#ffffff' }}>ROZEUS通过事实核查协议（Fact-chain protocol）、基础（BASE）以及效用最大化（Maximizing utility）等条件与各种设计原则创造了“ROZ”。</Typography>
                </React.Fragment>
            
                }
                <div style={{ height: '16px' }} />
                {layout.language === 'kor'? 
                <Typography variant='overline' style={{ color: '#ffffff' }} onClick={(e) => history.push('/progress/resources')}>더 자세한 내용은 PROGRESS 탭의 RESOURCES에서 확인 할 수 있습니다.<img src={'/images/common/ic_link_arrow_white.png'} alt='LINK' style={{ width:'6px', marginLeft:'5px' }} /></Typography>
                 :layout.language === 'eng'?  
                <Typography variant='body1' style={{ color: '#ffffff', fontWeight:'bold' }} onClick={(e) => history.push('/progress/resources')}>More detail information is in Resources on PROGRESS tap.<img src={'/images/common/ic_link_arrow_white.png'} alt='LINK' style={{ width:'6px', marginLeft:'5px' }} /></Typography>
                 :
                <Typography variant='overline' style={{ color: '#ffffff' }} onClick={(e) => history.push('/progress/resources')}>更多详细内容可以通过PROGESS dApp的Resourcess进行确认<img src={'/images/common/ic_link_arrow_white.png'} alt='LINK' style={{ width:'6px', marginLeft:'5px' }} /></Typography>
                }
                
                
                <div style={{ height: '100px'}} />
                {layout.language === 'kor'?     
                    <Typography variant='body2' style={{ textAlign:'center', color: '#ffffff' }}>ROZ를 바탕의 로제우스 경제의 순환</Typography>
                    : layout.language === 'eng'?
                    <Typography variant='body2' style={{ textAlign:'center', color: '#ffffff' }}>The cycle of the Roseus <br/>economy based on the ROZ coin</Typography>
                    :
                    <Typography variant='body2' style={{ textAlign:'center', color: '#ffffff' }}>ROZ를 바탕의 로제우스 경제의 순환</Typography>
                    }
                <div style={{ height: '24px' }} />
                <img src={'/images/profile/roz/small/ic_roz_chap3_chart_' + layout.mode + '.png'} alt='chart3' style={{ cursor:'pointer', width: '100%' }} onClick={(e)=>window.open('/images/profile/roz/ic_roz_chap3_chart_' + layout.mode + '.png')}/>
                <div style={{ height: '150px' }} />
            </div>
        </div>
    );
};

export default inject(({ layout }) => ({
    layout: layout,
}))(observer(Chapter3));